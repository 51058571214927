// Enum список цветов
/* eslint-disable-next-line max-lines-per-function  */
export enum ColorValiablesNameEnum {
    primary = '#3D62F5',
    secondary = '#0057B2',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    accent = '#3D62F5',
    subtitleAccent = '#F1F7FF',
    dark = '#1D1D1D',
    positive = '#21BA45',
    success = '#00B669',
    negative = '#FF3737',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    error = '#FF3737',
    lightError = '#FFF2F2',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    attention = '#FF3737',
    attention2 = '#FFAF37',
    info = '#31CCEC',
    warning = '#F2C037',
    notify = '#EE3420',
    link = '#3E53A4',
    black = '#2A2A34',
    white = '#ffffff',
    shade9 = '#54565F',
    shade8 = '#9090A5',
    shade7 = '#B4B9D1',
    shade4 = '#D8DEE1',
    shade2 = '#EEF1F3',
    shade1 = '#F2F5F7',
    shade5 = '#F7F9FA',
    blueLight = '#5399C7',
    blueDark = '#1C425A',
    subtleSuccess = '#E5FFEE',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    subtleAttention = '#FFF2F2',
    magenta = '#FF4EBB',
    violet = '#A041FF',
    indigo = '#346CB0',
    skyBlue = '#4DB5FF',
    pink = '#FF8D94',
    orange = '#FF6D00',
    lectureTxt = '#AE40E1',
    appInactive = '#172942',
    appActive = '#A6B4B9',
}
// Интерфейс паарамметров
export interface IIconProps {
    name: string;
    color: string;
    hoverColor: string;
    size?: string | number;
    useRawColor: boolean;
}
