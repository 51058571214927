import { IImageElement } from 'pages/Call/data/interfaces';

// Наложить фон на видео
export function setBackgroundImage(results: any, canvas: HTMLCanvasElement | null, renderingContext: CanvasRenderingContext2D, image: IImageElement | null): void {
    if (!canvas) {
        return;
    }

    renderingContext.save();
    renderingContext.clearRect(0, 0, canvas.width, canvas.height);
    if (image && image.isLoaded) {
        renderingContext.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);
    }
    renderingContext.globalCompositeOperation = 'source-out';
    if (image && image.isLoaded) {
        renderingContext.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
    }
    renderingContext.globalCompositeOperation = 'destination-atop';

    // Если есть картинка, то отрисовываем когда она загружена
    // либо когда картинки нет - это стандарный фон
    if ((image && image.isLoaded) || !image) {
        renderingContext.drawImage(results.image, 0, 0, canvas.width, canvas.height);
    }

    renderingContext.restore();
}

// Применить эффект размытия к видео
export function setBlurEffect(results: any, canvas: HTMLCanvasElement | null, blurCanvas: HTMLCanvasElement, renderingContext: CanvasRenderingContext2D, blurContext: CanvasRenderingContext2D): void {
    if (!canvas) {
        return;
    }

    renderingContext.save();
    renderingContext.clearRect(0, 0, canvas.width, canvas.height);
    renderingContext.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);
    renderingContext.globalCompositeOperation = 'source-out';

    blurContext.filter = 'blur(5px)';
    blurContext.drawImage(results.image, 0, 0);
    blurContext.filter = 'none';

    renderingContext.drawImage(
        blurCanvas,
        0, 0,
        blurCanvas.width,
        blurCanvas.height,
        0, 0,
        canvas.width,
        canvas.height
    );

    renderingContext.globalCompositeOperation = 'destination-atop';
    renderingContext.drawImage(results.image, 0, 0, canvas.width, canvas.height);
    renderingContext.restore();
}
