<template>
    <div class="chat-component row" :class="modeChat">
        <template v-if="currentChatId || isActivitySolutionChat || userDataForNewPrivateChat">
            <template v-if="isShowDisabledChatRoom && !isForbiddenChat">
                <TopPanelDisabledChat
                    :is-mobile-view="isMobileView"
                    :chat-id="currentChatId"
                    @on-select-room="onSelectRoom"
                />
                <q-card class="no-chat absolute-center q-px-xl text-center">
                    <q-card-section>
                        <img src="~assets/laptop.png" alt="" style="max-width: 271px" />
                        <div class="text-shade-8">{{ localize('Выберите чат и введите сообщение') }}</div>
                    </q-card-section>
                </q-card>
            </template>
            <template v-else-if="( chatInfo || isActivitySolutionChat || userDataForNewPrivateChat) && !isLoadingCreateChatRequest && !isForbiddenChat">
                <div
                    class="chat-container col"
                    :class="{
                        'hide-main-chat': isThreadChat,
                        'new-year': isNewYear
                    }"
                >
                    <TopPanel
                        @change-state="$emit('change-state')"
                        :key="'topPanel' + chatKey"
                        :is-pinned-chat="!!chatInfo && !!chatInfo.pinnedAt"
                        :is-archive-chat="isArchiveChat"
                        :is-mobile-view="isMobileView"
                        :mode-chat="modeChat"
                        :chat-info="chatInfo"
                        :student="student"
                        :is-mutual-check="isMutualCheck"
                        :is-activity-solution-chat="isActivitySolutionChat"
                        :is-chat-in-muted-section-or-discipline="isChatInMutedSectionOrDiscipline"
                        :user-data-for-new-private-chat="userDataForNewPrivateChat"
                        :messages-block-ref="messagesBlockRef"
                        @on-select-room="onSelectRoom"
                        @go-to-message="setMessageToView"
                        @on-message-file-deleted="onMessageFileDeleted"
                        @show-chats-panel="showChatsPanel"
                    />
                    <MessagesBlock
                        ref="messagesBlockRef"
                        :key="'messagesBlock' + chatKey"
                        v-show="!isThreadChat"
                        :chat-info="chatInfo"
                        :is-activity-solution-chat="isActivitySolutionChat"
                        :is-archive-chat="isArchiveChat"
                        :is-thread-chat="isThreadChat"
                        :is-mobile-view="isMobileView"
                        :mode-chat="modeChat"
                    />
                    <BottomPanel
                        ref="bottomPanelRef"
                        :mode-chat="modeChat"
                        :key="'bottomPanel' + chatKey"
                        :chat-info="chatInfo"
                        :is-mobile-view="isMobileView"
                        :is-archive-chat="isArchiveChat"
                        :activity-id="activityId"
                        :student="student"
                        :is-activity-solution-chat="isActivitySolutionChat"
                        :user-data-for-new-private-chat="userDataForNewPrivateChat"
                        @add-new-message-to-chat="addNewMessageToChat"
                        @on-send-message-error="onErrorHandler"
                        @create-chat-and-solution="createChatAndSolution"
                        @create-private-chat="createPrivateChat"
                        @on-send-message="onSend"
                    />
                </div>
                <ThreadChat
                    ref="threadChatRef"
                    :mode-chat="modeChat"
                    :parent-chat="chatInfo"
                    :is-mobile-view="isMobileView"
                    :is-archive-chat="isArchiveChat"
                    :is-chat-in-muted-section-or-discipline="isChatInMutedSectionOrDiscipline"
                    @go-to-message="setMessageToView"
                    @go-to-message-in-chat="goToMainMessageInChat"
                    @set-chat-id="setChatId"
                    :class="{ 'wide-thread-chat': isThreadChat }"
                />
            </template>
            <template v-else-if="isForbiddenChat">
                <div class="chat-denied-block">
                    <q-card class="chat-denied-block__graphic-block">
                        <img src="~/assets/chat-forbidden.png" alt="" />
                        <div class="chat-denied-block__graphic-block__reason-text">{{ localize('Чат более недоступен') }}</div>
                    </q-card>
                    <div class="chat-denied-block__bottom-line">
                        {{ localize('Отправка сообщений недоступна') }}
                    </div>
                </div>
            </template>
            <div v-else class="full-width text-center q-mt-xl">
                <q-spinner-dots color="primary" size="4em" />
            </div>
        </template>
        <q-card v-else class="no-chat absolute-center q-px-xl text-center">
            <q-card-section>
                <img src="~assets/laptop.png" alt="" style="max-width: 271px" />
                <div class="text-shade-8">{{ localize('Выберите чат и введите сообщение') }}</div>
            </q-card-section>
        </q-card>
    </div>
</template>

<script lang="ts">
    import { localize } from 'src/services/LocalizationService';
    import MessagesBlock from './components/MessagesBlock.vue';
    import {
        ActivitySolutionClient,
        ChatBaseInfoResponseModel,
        ChatClient,
        ChatFileDto,
        ChatMessageDto,
        ChatMessageHubDto,
        ChatMessageReactionDto,
        ChatMessageType,
        ChatPinnedMessageDto,
        ChatSearchMessageDto,
        ChatType,
        DiscussionParentMessage,
        ListChatDto,
        ResultOf,
        RoutePageNameEnum,
        UnreadeMessagesCountResponseModel,
        UserBaseInfoDto,
    } from 'src/api/ApiClient';
    import TopPanel from './components/TopPanel.vue';
    import BottomPanel from './components/BottomPanel.vue';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { Common } from 'src/helpers/Common';
    import { chatBus } from '../EventBuses';
    import { IChatRoom, IDisciplineChat } from 'pages/Main/Chat/types/interfaces';
    import ThreadChat from 'components/Chat/components/ThreadChat.vue';
    import { IChatMessage } from 'components/Chat/types/interfaces';
    import TopPanelDisabledChat from 'components/Chat/components/TopPanelDisabledChat.vue';
    import {
        computed,
        defineComponent,
        getCurrentInstance,
        onBeforeUnmount,
        onMounted,
        PropType,
        ref,
    } from 'vue';
    import { ModeChat } from 'components/Chat/types/enums';
    import { convertChatBaseInfoToInfoListItem, convertChatBaseInfoToListChatDto } from 'pages/Main/Chat/helpers';
    import { IErrorMessageDto } from 'src/types/generated/hubs/chatPartialHub/models/IErrorMessageDto';
    import { ChatPartialHub } from 'src/services/hubs/ChatPartialHub';
    import { useRoute, useRouter } from 'vue-router';
    import { useAccountStore } from 'src/store/module-account';
    import { useMainLayoutStore } from 'src/store/module-main-layout';
    import { useChatStore } from 'src/store/module-chat';
    import { UpdateCountUnreadedMessagesDto } from 'src/services/hubs/types/interfaces';
    import { useCallStore } from 'src/store/module-call';
    import { ChatBusEvents } from 'components/EventBuses/emuns';

    export default defineComponent({
        name: 'Chat',

        components: {
            TopPanelDisabledChat,
            ThreadChat,
            BottomPanel,
            TopPanel,
            MessagesBlock,
        },

        emits: [
            'on-create-chat-and-solution',
            'show-chats-panel',
            'change-state',
            'on-load',
        ],

        props: {
            // Режим отображения чата
            modeChat: {
                type: String,
                default: ModeChat.Inline,
            },
            // Мобильный вид чата
            isMobileView: {
                type: Boolean,
                default: false,
            },
            // Передаётся в чате решений
            activityId: {
                type: Number as PropType<number | null>,
                default: undefined,
            },
            // Включена ли взаимопроверка, передаётся в чате решений
            isMutualCheck: {
                type: Boolean,
                default: false,
            },
            // если вид от препода - это студент
            // с которым он ведёт переписку
            // передаётся в чате решений
            student: {
                type: Object as PropType<UserBaseInfoDto | undefined>,
            },
            // Является ли чат чатом решения
            isSolutionChat: {
                type: Boolean as PropType<boolean | null>,
                default: false,
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const messagesBlockRef = ref<InstanceType<typeof MessagesBlock> | null>(null);
            const threadChatRef = ref<InstanceType<typeof ThreadChat> | null>(null);
            const bottomPanelRef = ref<InstanceType<typeof BottomPanel> | null>(null);
            const isForbiddenChat = ref<boolean>(false);
            const app = getCurrentInstance();

            const $route = useRoute();
            const chatStore = useChatStore();
            const mainLayoutStore = useMainLayoutStore();
            const accountStore = useAccountStore();
            const callStore = useCallStore();
            const $router = useRouter();
            const isNewYear = Common.isNewYearTime();

            // Нужно ли показывать компонент для отображения селекта с чатами решений
            // в комнате, в которой чат отключен
            const isShowDisabledChatRoom = ref<boolean>(false);

            // Ключ из родительского компонента
            const chatKey = ref<string>(app?.vnode?.key?.toString() ?? Date.now().toString());

            // Id текущего чата
            const currentChatId = ref<number>(0);

            // Модель базовой информации о чате
            const chatInfo = ref<ChatBaseInfoResponseModel | null>(null);

            // Данные пользователя для которого нужно будет создать приватный чат
            const userDataForNewPrivateChat = ref<UserBaseInfoDto | null>(null);

            // Флаг ставится в true когда издёт запрос на создание чата и решения
            const isLoadingCreateChatRequest = ref<boolean>(false);

            const isActivitySolutionChat = computed<boolean>(() => {
                return props.isSolutionChat || !!chatStore.selectedSolutionChat;
            });

            const isArchiveChat = computed<boolean>(() => {
                return chatInfo.value ? chatInfo.value.isArchive : false;
            });

            const isThreadChat = computed<boolean>(() => {
                return chatInfo.value ? chatInfo.value.type === ChatType.Discussion : false;
            });

            // Чат находится в замьюченной секции или дисциплине
            const isChatInMutedSectionOrDiscipline = computed<boolean>(() => {
                if (chatInfo.value) {
                    const mainChatType = chatInfo.value.type === ChatType.Discussion
                        ? chatInfo.value.parentChatType
                        : chatInfo.value.type;

                    return chatStore.getIsChatInMutedSectionOrDiscipline(mainChatType, chatInfo.value.disciplineId);
                }

                return false;
            });

            function goToMainMessageInChat(message: ChatMessageDto): void {
                // Если мы находися на странице чатов, то чат обсуждения у нас открыт на всю страницу
                // и мы сначала должны открыть родительский чат и потом перейти к сообщению
                if (chatInfo.value?.type === ChatType.Discussion && chatInfo.value.parentMessage) {
                    setChatId(chatInfo.value.parentMessage.chatId, {
                        id: message.id!,
                        chatId: message.chatId,
                        nameChat: '',
                        text: message.text,
                        createDateTime: message.createDateTime,
                        author: message.author,
                        files: []
                    });
                } else {
                    // переходим к сообщению в чате
                    messagesBlockRef.value?.setMessageToView(message.id);
                }
            }

            function setMessageToView(messageId: number): void {
                messagesBlockRef.value?.setMessageToView(messageId);
            }

            // Обработчик события из ChatFiles - чтобы обновить сообщение, в котором этот файл удалили
            function onMessageFileDeleted(file: ChatFileDto): void {
                messagesBlockRef.value?.onMessageFileDeleted(file);
            }

            function onSelectRoom(chatId: number): void {
                isShowDisabledChatRoom.value = false;

                if (chatId) {
                    setChatId(chatId);
                } else {
                    currentChatId.value = 0;
                    chatInfo.value = null;
                    isForbiddenChat.value = false;
                    // Меняем chatKey чтобы перерендерился компонент для чата решения, которого нет (чат не создан)
                    chatKey.value = 'chatKey' + Date.now();
                    chatStore.pinnedMessages = [];
                }
            }

            // Показать вид для отключенного чата комнаты
            function showDisabledChat(chatId: number): void {
                currentChatId.value = chatId;
                chatInfo.value = null;
                isForbiddenChat.value = false;
                isShowDisabledChatRoom.value = true;
                // Устанавливаем id комнаты чтобы в чате решения работал запрос на получение комнат
                chatStore.selectedRoomId = chatId;
            }

            /**
             * Установить данные пользователя для чата
             * который еще не создан, но чат отобразить надо
             * @param userData - информация о пользователе
             */
            function setPrivateChatUserData(userData: UserBaseInfoDto): void {
                userDataForNewPrivateChat.value = userData;
                currentChatId.value = 0;
                chatInfo.value = null;
                isForbiddenChat.value = false;
                isShowDisabledChatRoom.value = false;

                // обнуляем информацию в сторе
                chatStore.currentChatId = 0;
                chatStore.pinnedMessages = [];
                chatStore.editingMessage = null;
                chatStore.replyingMessage = null;
            }

            // Эта функция вызывается из других модулей для того,
            // Что бы инициализировать чат для переданных параметров
            async function setChatId(chatId: number, message?: ChatSearchMessageDto): Promise<void> {
                // Для чата решений черновик сохраняется в вотчере
                // тк здесь может открываться несозданный чат решения
                if (!props.isSolutionChat) {
                    bottomPanelRef.value?.updateDraftMessage();
                    threadChatRef.value?.updateDraftMessage();
                }

                currentChatId.value = chatId;
                chatInfo.value = null;
                isForbiddenChat.value = false;
                userDataForNewPrivateChat.value = null;
                isShowDisabledChatRoom.value = false;

                // обнуляем информацию в сторе
                chatStore.currentChatId = chatId;
                chatStore.pinnedMessages = [];
                chatStore.editingMessage = null;
                chatStore.replyingMessage = null;

                const chatInfoModel = await loadChatInfo(chatId);

                // передаем данные в дочерние модули
                if (chatInfoModel) {
                    chatInfo.value = chatInfoModel;

                    if (chatInfo.value?.type === ChatType.Discussion && chatInfo.value.parentMessage) {
                        chatInfo.value.nameChat = chatInfo.value.parentMessage.chatName ?? '';
                        chatStore.mainMessageInThread = getMainMessageInfo(chatInfo.value);
                    } else {
                        chatStore.mainMessageInThread = null;
                    }

                    if (isActivitySolutionChat.value && isLoadingCreateChatRequest.value) {
                        isLoadingCreateChatRequest.value = false;
                    }

                    if (chatInfo.value?.type !== ChatType.Solution) {
                        chatStore.selectedSolution = null;
                        chatStore.selectedSolutionChat = null;
                    }

                    setTimeout(() => {
                        if (isThreadChat.value) {
                            threadChatRef.value?.setMessageToView(message?.id);
                        } else {
                            messagesBlockRef.value?.setMessageToView(message?.id);
                        }
                    }, 100);

                    context.emit('on-load', chatInfo.value);
                }
            }

            async function loadChatInfo(chatId: number): Promise<ChatBaseInfoResponseModel | null> {
                const result = await new ChatClient(getApiClientInitialParams()).getChatBaseInfo(chatId);
                if (result.isSuccess) {
                    return result.entity;
                } else if (result.httpStatusCode === 403) {
                    isForbiddenChat.value = true;
                    return null;
                } else {
                    // если чат находится справа, то ни куда не редиректим
                    NotifyErrors(result, '', props.modeChat === ModeChat.Page);
                    return null;
                }
            }

            // добавляем наше сообщени сразу ещё до отправки в хаб
            function addNewMessageToChat(data: ChatMessageDto): void {
                messagesBlockRef.value?.addMessage(data);
            }

            // Обработчик отправки нового сообщения
            function onSend(): void {
                messagesBlockRef.value?.scrollMessagesToDown();
            }

            // создаём чат и решение, вызывается при отправке
            // сообщения в чат, которого нет
            async function createChatAndSolution(message: ChatMessageHubDto): Promise<void> {
                let userId;

                if ($route.name === Common.getRouteName(RoutePageNameEnum.ActivitySolution)) {
                    // если есть поле student, то значит это вид от препода
                    // это студент с которым он ведет переписку и мы создаем чат
                    // для этого студента
                    if (props.student) {
                        userId = props.student.id;
                    } else {
                        // иначе это вид от студента и этот студент - мы
                        userId = accountStore.getAccountInfo?.id ?? 0;
                    }
                } else {
                    // когда мы находимся в миничате или на странице всех чатов
                    userId = chatStore.selectedSolutionChat?.userId ?? 0;
                }

                isLoadingCreateChatRequest.value = true;
                let activityId;

                if (props.activityId) {
                    activityId = props.activityId;
                } else {
                    activityId = chatStore.selectedSolution?.activityId ?? 0;
                }

                const result = await new ActivitySolutionClient(getApiClientInitialParams()).addChatAndSolution({
                    activityId,
                    userId,
                });

                if (result.isSuccess) {
                    message.chatId = result.entity.chatId;
                    await setChatId(message.chatId);
                    (app?.appContext.config.globalProperties.$commonHub as ChatPartialHub)?.sendMessageAsync(message);
                    context.emit('on-create-chat-and-solution', result.entity, props.student?.id);
                } else {
                    NotifyErrors(result);
                    isLoadingCreateChatRequest.value = false;
                }
            }

            // Создать приватный чат с пользователем
            async function createPrivateChat(message: ChatMessageHubDto): Promise<void> {
                if (!userDataForNewPrivateChat.value) {
                    return;
                }

                isLoadingCreateChatRequest.value = true;
                const result = await new ChatClient(getApiClientInitialParams()).getOrCreateChatWithUser(userDataForNewPrivateChat.value.id);

                if (result.isSuccess) {
                    message.chatId = result.entity;
                    await setChatId(message.chatId);
                    (app?.appContext.config.globalProperties.$commonHub as ChatPartialHub)?.sendMessageAsync(message);
                } else {
                    NotifyErrors(result);
                }

                isLoadingCreateChatRequest.value = false;
            }

            // Обработчик события от вебсокета,
            // вызывается когда нам пришло сообщение
            async function onMessageReceivedHandler(data: ChatMessageDto): Promise<void> {
                // Для работы сортировки по недавним сообщениям нужно обновлять LastMessageDateTime у чата, куда пришло сообщение
                chatStore.updateLastMessageDateTime(data);

                // Если находимся в звонке
                if ($route.name === Common.getRouteName(RoutePageNameEnum.CallEnter)) {
                    if (data.chatId === currentChatId.value && chatInfo.value?.type !== ChatType.Discussion) {
                        messagesBlockRef.value?.addMessage(data);
                    } else {
                        threadChatRef.value?.onMessageReceivedHandler(data);
                        messagesBlockRef.value?.updateDiscussionForMessage(data);
                    }
                    // Если блок с чатом не виден, то инкрементируем количество непрочитанных сообщений
                    if (!callStore.chatIsVisible) {
                        incrementMessage(data, callStore.callStartingData?.disciplineId);
                    } else {
                        // Если пришло сообщение от другого чата, то инкрементируем кол-во непрочитанный
                        // Или если это нужный чат, но при этом пользователь проскролил куда-то вверх,
                        // То тоже увеличивыем счетчик непрочитанных, что бы показалась кнопка скрола вниз и индикатор
                        if (data.chatId !== currentChatId.value || (messagesBlockRef.value?.getScrollPercent() ?? 0) < 0.9) {
                            incrementMessage(data, callStore.callStartingData?.disciplineId);
                        }
                    }
                } else {
                    if (data.chatId === currentChatId.value && chatInfo.value?.type !== ChatType.Discussion) {
                        // Если сайдбар с чатами закрыт, то инкрементируем кол-во непрочитанный
                        // Или если это нужный чат, но при этом пользователь проскролил куда-то вверх,
                        // То тоже увеличивыем счетчик непрочитанных, что бы показалась кнопка скрола вниз и индикатор
                        if (!mainLayoutStore.isVisibleChatBlock || (messagesBlockRef.value?.getScrollPercent() ?? 0) < 0.9) {
                            incrementMessage(data);
                        }
                        messagesBlockRef.value?.addMessage(data);
                    } else if (data.chatType === ChatType.Discussion) {
                        threadChatRef.value?.onMessageReceivedHandler(data);
                        messagesBlockRef.value?.updateDiscussionForMessage(data);
                    } else if (
                        // Обработка ситации, когда сообщение приходит в несозданный активный чат решения
                        // если у чата и сообщения activityId совпадают, то скорее всего сообщение пришло в этот чат
                        // потому что врядли у студента будет два чата решения в одной активности
                        props.isSolutionChat
                        && !currentChatId.value
                        && data.chatType === ChatType.Solution
                        && data.activityId === props.activityId
                        && data.receiverId === props.student?.id
                    ) {
                        setChatId(data.chatId);
                    } else {
                        //Если пришло сообщение из чата, которого еще нет, добавляем чат в стор
                        await addChatToStore(data);
                        incrementMessage(data);
                    }
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда кто-то удалил сообщение
            async function onRemoveMessageReceivedHandler(data: ChatMessageDto): Promise<void> {
                // если обсуждение было удалено, и пользователь в это время находится на странице этого обсуждения, то перенаправляем его на страницу чата
                if (data.discussion?.isDeleted &&
                    $route.name === Common.getRouteName(RoutePageNameEnum.Chat) &&
                    Number($route.params.id) === data.discussion?.id) {
                    $router.push({
                        name: Common.getRouteName(RoutePageNameEnum.Chat),
                        params: { id: data.chatId.toString() },
                    });
                }

                if (data.chatId === currentChatId.value && chatInfo.value?.type !== ChatType.Discussion) {
                    messagesBlockRef.value?.removeMessage(data);
                } else {
                    // Получаем количество не прочитанных сообщений
                    const result: ResultOf<UnreadeMessagesCountResponseModel> = await new ChatClient(getApiClientInitialParams()).unreadeMessagesCount(data.chatId);
                    if (result.isSuccess) {
                        chatStore.setCountUnreadedMessages({
                            chatId: result.entity.unreadedMessagesCount.chatId,
                            count: result.entity.unreadedMessagesCount.count,
                        });
                    } else {
                        NotifyErrors(result);
                    }

                    threadChatRef.value?.onRemoveMessageReceivedHandler(data);
                    messagesBlockRef.value?.removeMessageFromDiscussion(data);
                }

                // Если для удаляемого сообщения есть чат-обсуждение, то удаляем его из списка чатов
                if (data.discussion) {
                    chatStore.removeDiscussionChat(data.discussion.id);
                    chatStore.setCountUnreadedMessages({
                        chatId: data.discussion.id,
                        count: 0,
                    });
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда кто-то отредактировал сообщение
            function onEditMessageReceivedHandler(data: ChatMessageDto): void {
                if (data.chatId === currentChatId.value && chatInfo.value?.type !== ChatType.Discussion) {
                    messagesBlockRef.value?.updateMessage(data);
                } else {
                    threadChatRef.value?.onEditMessageReceivedHandler(data);
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда кто-то добавил реакцию к сообщению
            function onSendMessageReactionReceivedHandler(data: ChatMessageReactionDto): void {
                if (data.chatId === currentChatId.value && chatInfo.value?.type !== ChatType.Discussion) {
                    messagesBlockRef.value?.updateMessageReaction(data);
                } else {
                    threadChatRef.value?.onSendMessageReactionReceivedHandler(data);
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда кто-то удалил реакцию у сообщения
            function onRemoveMessageReactionReceivedHandler(data: ChatMessageReactionDto): void {
                if (data.chatId === currentChatId.value && chatInfo.value?.type !== ChatType.Discussion) {
                    messagesBlockRef.value?.removeMessageReaction(data);
                } else {
                    threadChatRef.value?.onRemoveMessageReactionReceivedHandler(data);
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда кто-то закрепил сообщение
            function onPinMessageReceivedHandler(data: ChatPinnedMessageDto): void {
                if (data.chatId === currentChatId.value && chatInfo.value?.type !== ChatType.Discussion) {
                    chatStore.addPinnedMessage(data);
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда кто-то открепил сообщение
            function onUnpinMessageReceivedHandler(data: ChatMessageDto): void {
                if (data.chatId === currentChatId.value && chatInfo.value?.type !== ChatType.Discussion) {
                    chatStore.removePinnedMessage(data.id!);
                    messagesBlockRef.value?.updateMessage(data);
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда пользователь прочитал сообщение
            function onUpdateReadedMessageHandler(data: ChatMessageDto): void {
                // Если мы в звонке, то проверяем видимость чата
                if ($route.name === Common.getRouteName(RoutePageNameEnum.CallEnter)) {
                    if (data.chatId === currentChatId.value) {
                        messagesBlockRef.value?.setUpdateLastReadedMessage(data.id!);
                    }
                } else {
                    if (data.chatId === currentChatId.value && chatInfo.value?.type !== ChatType.Discussion) {
                        messagesBlockRef.value?.setUpdateLastReadedMessage(data.id!);
                    } else {
                        threadChatRef.value?.onUpdateReadedMessageHandler(data);
                    }
                }
            }

            // Обработчик события от вебсокета,
            // вызывается когда пользователь прочитал сообщение
            function onUpdateCountUnreadedMessagesHandler(data: UpdateCountUnreadedMessagesDto): void {
                // Обновляем счетчик непрочитанных сообщений,
                // если сообщения этого чата пользователь прочитал в другой вкладке
                if (data.chatId !== currentChatId.value) {
                    chatStore.decrementUnreadedCountMessage(data);
                }
            }

            function getMainMessageInfo(chatBaseInfo: ChatBaseInfoResponseModel): IChatMessage {
                const parentMessage = chatBaseInfo.parentMessage as DiscussionParentMessage;

                return {
                    id: chatBaseInfo.parentMessage!.id,
                    chatId: chatBaseInfo.id,
                    createDateTime: parentMessage.createDateTime,
                    type: ChatMessageType.Ordinal,
                    isEditedMessage: parentMessage.isEditedMessage,
                    isDeleted: parentMessage.isDeleted,
                    text: parentMessage.text,
                    author: parentMessage.author,
                    isPinnedMessage: false,
                    chatType: ChatType.Discussion,
                    messageReactions: parentMessage.messageReactions,
                    files: parentMessage.files,
                    discussion: {
                        id: chatBaseInfo.id,
                        countOfMessages: 0,
                        countOfUsers: 0,
                        users: [],
                        isDeleted: parentMessage.isDeleted,
                    },
                    isMyMessage: parentMessage.author.id === accountStore.getAccountInfo?.id,
                    isSending: false,
                    receiverNotInDiscussion: false,
                    forcedPushNotificationUserIds: [],
                };
            }

            // Инкрементирование количества непрочитанных сообщений
            function incrementMessage(data: ChatMessageDto, disciplineId?: number | null): void {
                if (data.author.id !== accountStore.getAccountInfo?.id) {
                    chatStore.incrementUnreadedCountMessage({
                        chatId: data.chatId,
                        count: 1,
                        disciplineId: disciplineId ?? data.disciplineId,
                        activityId: data.activityId,
                        isArchive: data.isDeleted,
                        type: data.chatType,
                    });
                }
            }

            // Добавить чат в стор, если его еще нет
            async function addChatToStore(data: ChatMessageDto): Promise<void> {
                const allChatIds = chatStore.getAllChatsIds;

                // Если пришло сообщение из чата, которого еще нет, добавляем чат в стор
                if (allChatIds.filter((i: number) => i === data.chatId).length === 0) {
                    const result = await new ChatClient(getApiClientInitialParams()).getChatBaseInfo(data.chatId);

                    if (!result.isSuccess) {
                        NotifyErrors(result);
                        return;
                    }

                    if (result.entity.type !== ChatType.Discipline && result.entity.type !== ChatType.Solution) {
                        const chat: ListChatDto = {
                            chatId: data.chatId,
                            name: result.entity.nameChat,
                            type: result.entity.type,
                            countMembers: result.entity.countMembers,
                            logoPath: result.entity.chatAvatarPath,
                            isClosedRoom: result.entity.isClosedRoom,
                            isDisabled: false,
                            parentChatType: 0,
                            isMutedDiscipline: false,
                            isMuted: result.entity.isMuted,
                        };

                        chatStore.addChats([chat]);
                        addChatsToMiniChats(result.entity, data);
                    } else {
                        if (result.entity.isDefaultRoom) {
                            chatStore.addChats([convertToIDisciplineChat(result.entity)]);
                            addChatsToMiniChats(result.entity, data);
                        } else {
                            chatStore.addRoomToDisciplineChat(convertToIChatRoom(result.entity));
                        }
                    }
                }
            }

            function addChatsToMiniChats(chat: ChatBaseInfoResponseModel, message: ChatMessageDto): void {
                const miniChat = convertChatBaseInfoToInfoListItem(chat);
                miniChat.lastMessageDateTime = message.createDateTime;
                chatBus.emit(ChatBusEvents.AddChatsToMiniChats, [miniChat]);
            }

            function convertToIChatRoom(chatBaseInfo: ChatBaseInfoResponseModel): IChatRoom {
                const chatList = convertChatBaseInfoToListChatDto(chatBaseInfo);
                return {
                    ...chatList,
                    disciplineChatId: chatBaseInfo.disciplineId,
                    isOpen: false,
                    isLoading: false,
                    solutionChats: [],
                } as IChatRoom;
            }

            function convertToIDisciplineChat(chatBaseInfo: ChatBaseInfoResponseModel): IDisciplineChat {
                const chatList = convertChatBaseInfoToListChatDto(chatBaseInfo);

                return {
                    ...chatList,
                    isOpen: false,
                    isLoading: false,
                    isCanCreateChatRoom: false,
                    disciplineId: chatBaseInfo.disciplineId,
                    activityId: chatBaseInfo.activityId,
                    rooms: [],
                    hasUnreadedMessages: false,
                } as IDisciplineChat;
            }

            // Инициируем событие, что бы можно было перехватить в родительских компонентах
            function showChatsPanel(): void {
                context.emit('show-chats-panel');
            }

            function onErrorHandler(data: IErrorMessageDto): void {
                if (data.chatId === currentChatId.value) {
                    messagesBlockRef.value?.setErrorMessage(data);
                }
            }

            function offChatHandlers(): void {
                chatBus.off(ChatBusEvents.OnMessageReceivedHandler, onMessageReceivedHandler);
                chatBus.off(ChatBusEvents.OnRemoveMessageReceivedHandler, onRemoveMessageReceivedHandler);
                chatBus.off(ChatBusEvents.OnEditMessageReceivedHandler, onEditMessageReceivedHandler);
                chatBus.off(ChatBusEvents.OnSendMessageReactionReceivedHandler, onSendMessageReactionReceivedHandler);
                chatBus.off(ChatBusEvents.OnRemoveMessageReactionReceivedHandler, onRemoveMessageReactionReceivedHandler);
                chatBus.off(ChatBusEvents.OnPinMessageReceivedHandler, onPinMessageReceivedHandler);
                chatBus.off(ChatBusEvents.OnUnpinMessageReceivedHandler, onUnpinMessageReceivedHandler);
                chatBus.off(ChatBusEvents.OnUpdateReadedMessageHandler, onUpdateReadedMessageHandler);
                chatBus.off(ChatBusEvents.OnUpdateCountUnreadedMessagesHandler, onUpdateCountUnreadedMessagesHandler);
                chatBus.off(ChatBusEvents.OnErrorHandler, onErrorHandler);
            }

            onMounted(() => {
                offChatHandlers();
                chatBus.on(ChatBusEvents.OnMessageReceivedHandler, onMessageReceivedHandler);
                chatBus.on(ChatBusEvents.OnRemoveMessageReceivedHandler, onRemoveMessageReceivedHandler);
                chatBus.on(ChatBusEvents.OnEditMessageReceivedHandler, onEditMessageReceivedHandler);
                chatBus.on(ChatBusEvents.OnSendMessageReactionReceivedHandler, onSendMessageReactionReceivedHandler);
                chatBus.on(ChatBusEvents.OnRemoveMessageReactionReceivedHandler, onRemoveMessageReactionReceivedHandler);
                chatBus.on(ChatBusEvents.OnPinMessageReceivedHandler, onPinMessageReceivedHandler);
                chatBus.on(ChatBusEvents.OnUnpinMessageReceivedHandler, onUnpinMessageReceivedHandler);
                chatBus.on(ChatBusEvents.OnUpdateReadedMessageHandler, onUpdateReadedMessageHandler);
                chatBus.on(ChatBusEvents.OnUpdateCountUnreadedMessagesHandler, onUpdateCountUnreadedMessagesHandler);
                chatBus.on(ChatBusEvents.OnErrorHandler, onErrorHandler);
            });

            onBeforeUnmount(() => {
                offChatHandlers();
                chatStore.isSearchInChatMode = false;
                chatStore.searchedMessageIds = null;
            });

            return {
                messagesBlockRef,
                threadChatRef,
                bottomPanelRef,
                isNewYear,
                isShowDisabledChatRoom,
                chatKey,
                currentChatId,
                chatInfo,
                isLoadingCreateChatRequest,
                isActivitySolutionChat,
                isArchiveChat,
                isThreadChat,
                userDataForNewPrivateChat,
                goToMainMessageInChat,
                setMessageToView,
                onMessageFileDeleted,
                onSelectRoom,
                showDisabledChat,
                setChatId,
                setPrivateChatUserData,
                addNewMessageToChat,
                onSend,
                createChatAndSolution,
                createPrivateChat,
                showChatsPanel,
                onErrorHandler,
                localize,
                isChatInMutedSectionOrDiscipline,
                isForbiddenChat,
            };
        },

    });
</script>

<style lang="scss" scoped>
    .chat-component {
        position: relative;
        width: 100%;
        height: 100%;

        &.inline {
            height: calc(100% - 60px);

            .no-chat {
                max-width: 90%;
            }
        }

        .chat-container {
            position: relative;
            height: 100%;
            display: flex;
            flex-flow: column;
            background-image: url('assets/chat-pattern.png');
            background-size: 220px;

            &.hide-main-chat {
                width: 0;
                overflow: hidden;
            }

            &.new-year {
                background-image: url('assets/chat-pattern-new-year.png');
                background-size: 100%;
            }
        }

        .wide-thread-chat {
            width: 100%;
        }

        ::v-deep(.content-container) {
            position: relative;
            flex-grow: 1;
        }
    }

    @media (max-width: 960px) {
        .chat-component {
            .no-chat {
                max-width: 90%;
            }
        }
    }
</style>

<style lang="scss">
    .chat-component {
        &.inline {
            .message-block {
                .message-item {
                    max-width: 87%;
                    min-width: 110px;
                }
            }
        }

        .thread-chat-block {
            &.wide-thread-chat {
                .thread-message {
                    .message-item {
                        max-width: 550px;
                    }
                }
            }

            .thread-message {
                .message-item {
                    max-width: 87%;

                    .code-editor-component.inline-code {
                        min-width: 220px;
                    }
                }
            }
        }
    }

    .chat-denied-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-image: url('assets/chat-pattern.png');
        background-size: 220px;
        width: 100%;

        &__graphic-block {
            text-align: center;
            margin: auto auto;
            width: 310px;

            img {
                width: 80%;
                margin: 50px auto 0 auto;
            }

            &__reason-text {
                color: $shade-9;
                margin: 50px 0;
            }
        }

        &__bottom-line {
            height: 54px;
            line-height: 54px;
            color: $shade-8;
            background-color: #FFFFFF;
            text-align: left;
            padding: 0 0 0 20px;
            vertical-align: middle;
        }
    }
</style>
