<template>
    <q-layout style="margin: 0; padding: 0; background-color: black;">
        <q-page-container>
            <settings-page
                v-if="!isLoadedStartingData"
                @connect-to-call="initCall"
            />
            <router-view
                v-if="isLoadedStartingData"
            />
        </q-page-container>
        <ExternalReferenceChecker />
    </q-layout>
</template>

<script lang="ts">
    import adapter from 'webrtc-adapter';
    import JanusWrapper from 'pages/Call/libs/janus/JanusWrapper';
    import SettingsPage from 'pages/Call/components/SettingsPage.vue';
    import CallMode from 'src/pages/Call/types/CallMode.enum';
    import { defineComponent, getCurrentInstance, onBeforeMount, onBeforeUnmount, ref } from 'vue';
    import useSharedLayout from 'layouts/useSharedLayout';
    import { useRoute } from 'vue-router';
    import { ColorValiablesNameEnum } from 'components/ui/Icon/interface';
    import ExternalReferenceChecker from 'layouts/Main/components/ExternalReferenceChecker.vue';

    // Родительский layout для страниц звонка
    export default defineComponent({
        name: 'Call',

        components: {
            ExternalReferenceChecker,
            SettingsPage
        },

        setup() {
            const $route = useRoute();
            const app = getCurrentInstance();

            const { initCommonHub } = useSharedLayout();

            const isLoadedStartingData = ref<boolean>(false);

            function initCall(): void {
                // Инициализуем хаб для чатов
                if ($route.query.mode !== CallMode.participants) {
                    // Если это страница участников, то хаб уже инициализирован
                    initCommonHub();
                }

                // показываем интерфейс звонка
                isLoadedStartingData.value = true;
            }

            onBeforeMount(() => {
                // Создаём объект $janus
                if (app) {
                    app.appContext.config.globalProperties.$janus = new JanusWrapper();
                }

                (window as any).adapter = adapter;

                if ($route.query.mode === CallMode.participants) {
                    // Для страницы участников инициализируем хаб, чтобы прослушивать начало звонка
                    initCommonHub();
                }

                document.body.style.backgroundColor = 'black';
            });

            onBeforeUnmount(() => {
                app?.appContext.config.globalProperties.$commonHub?.closeConnection();
                document.body.style.backgroundColor = ColorValiablesNameEnum.shade1;
            });

            return {
                isLoadedStartingData,
                initCall,
            };
        }
    });
</script>
