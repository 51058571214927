/* eslint-disable */

var Janus = (function () {
  'use strict';

  /**
   * Return (and increment) the value of a counter, starting from a random seed.
   * The counter starts from 0 after reaching Number.MAX_SAFE_INTEGER.
   *
   * @returns {string} A numeric string
   */
  const getNumericID = (_ => {
    let now;
    let next = Math.floor(Number.MAX_SAFE_INTEGER * Math.random());

    return _ => {
      now = next;
      next = next + 1;
      if (next >= Number.MAX_SAFE_INTEGER) next = 0;
      return '' + now;
    };
  })();

  /**
   * Generate a circular iterator from an array.
   *
   * @param {Array} list - The array that must be iterated
   * @returns {CircularIterator} The generated iterator
   */
  const newIterator = list => {
    const l = Array.from(list);
    const len = l.length;
    var i = 0;

    return {
      nextElem: _ => l[i++ % len],
      currElem: _ => l[i % len],
    };
  };

  /**
   * Return a promise that will resolve after a given amount of milliseconds.
   *
   * @param {number} ms - The amount of millis to wait before resolving
   * @returns {Promise<void>} A promise that will resolve after a certain time
   */
  const delayOp = ms => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  /**
   * Check if a url string contains one of the protocols in a white list.
   *
   * @param {string} url_string - The url string to be checked
   * @param {Array<string>} admitted - The admitted protocols
   * @returns {boolean} True if the check succeeds
   */
  const checkUrl = (url_string, admitted) => {
    try {
      /* 'slice(0, -1)' removes the colon at the last position */
      const protocol = (new URL(url_string)).protocol.slice(0, -1);
      return admitted.includes(protocol);
    } catch (_error) { }
    return false;
  };

  /**
   * Get a CLI argument.
   *
   * @param {string} arg_name - The argument name
   * @param {("string"|"number"|"boolean")} arg_type - The argument type
   * @param {string|number|boolean} arg_default - An optional default value if missing
   * @returns {string|number|boolean|void}
   */
  const getCliArgument = (arg_name, arg_type, arg_default) => {
    if (typeof process === 'undefined' || !Array.isArray(process.argv) || process.argv.length < 2) return arg_default;
    const args = process.argv.slice(2);
    let arg_val = undefined;
    for (const param of args) {
      /* --arg */
      if (param === `--${arg_name}`) {
        if (arg_type === 'boolean') arg_val = true;
      }
      /* --arg=value */
      else if (param.startsWith(`--${arg_name}=`)) {
        arg_val = param.split('=').length > 1 ? param.split('=')[1] : arg_val;
        if (arg_val) {
          if (arg_type === 'boolean') {
            if (arg_val.toLowerCase() === 'false') arg_val = false;
            else if (arg_val.toLowerCase() === 'true') arg_val = true;
            if (typeof arg_val !== 'boolean') arg_val = undefined;
          }
          if (arg_type === 'number') {
            arg_val = parseInt(arg_val);
            if (!Number.isInteger(arg_val)) arg_val = undefined;
          }
        }
      }
    }
    arg_val = typeof arg_val !== 'undefined' ? arg_val : arg_default;
    return arg_val;
  };

  const LEVELS = ['none', 'error', 'warning', 'info', 'verbose', 'debug'];
  const LEVELS_IDX = LEVELS.reduce((obj, lvl, idx) => {
    obj[lvl] = idx;
    return obj;
  }, {});

  const DEFAULT_LEVEL = 'info';
  let log_verbosity = getCliArgument('janode-log', 'string', DEFAULT_LEVEL);

  const printout = (msg_verbosity, console_fn, ...args) => {
    if (LEVELS_IDX[msg_verbosity] > LEVELS_IDX[log_verbosity]) return;
    const ts = (new Date()).toISOString();
    const prefix = `${ts} - ${msg_verbosity.toUpperCase().padEnd(8, ' ')}:`;
    console_fn(prefix, ...args);
  };

  /**
   * The logger used by Janode.
   */
  const Logger = {
    /**
     * Debug logging.
     * It is a wrapper for `console.debug()`.
     *
     * @function
     * @param {...any} args
     */
    debug: (...args) => printout('debug', console.debug, ...args),

    /**
     * Verbose logging.
     * It is a wrapper for `console.debug()`.
     *
     * @function
     * @param {...any} args
     */
    verbose: (...args) => printout('verbose', console.debug, ...args),

    /**
     * Info logging (default).
     * It is a wrapper for `console.info()`.
     *
     * @function
     * @param {...any} args
     */
    info: (...args) => printout('info', console.info, ...args),

    /**
     * Warning logging.
     * It is a wrapper for `console.warn()`.
     *
     * @function
     * @param {...any} args
     */
    warning: (...args) => printout('warning', console.warn, ...args),

    /**
     * Error logging.
     * It is a wrapper for `console.error()`.
     *
     * @function
     * @param {...any} args
     */
    error: (...args) => printout('error', console.error, ...args),

    /**
     * Set level of logger.
     *
     * @function
     * @param {"debug"|"verb"|"info"|"warn"|"error"|"none"} lvl
     * @returns {string} The current level
     */
    setLevel: (lvl = '') => {
      lvl = lvl.toLowerCase();
      if (lvl === 'verb') lvl = 'verbose';
      if (lvl === 'warn') lvl = 'warning';
      if (typeof LEVELS_IDX[lvl] === 'number') {
        log_verbosity = lvl;
      }
      else {
        log_verbosity = DEFAULT_LEVEL;
      }
      return log_verbosity;
    }
  };
  /* set aliases */
  Logger.verb = Logger.verbose;
  Logger.warn = Logger.warning;

  Logger.setLevel(log_verbosity);

  /**
   * This module contains the Configuration class definition.
   * @module configuration
   * @private
   */

  const DEF_RETRY_TIME = 10;
  const DEF_MAX_RETRIES = 5;

  /**
   * Class representing a Janode configuration.
   * The purpose of the class is basically filtering the input config and distinguish Janus API and Admin API connections.
   */
  class Configuration {
    /**
     * Create a configuration.
     *
     * @private
     * @param {module:janode~RawConfiguration} config
     */
    constructor({ address, retry_time_secs, max_retries, is_admin, ws_options }) {
      if (!address)
        throw new Error('invalid configuration, missing parameter "address"');
      if (Array.isArray(address) && address.length === 0)
        throw new Error('invalid configuration, empty parameter "address"');
      this.address = Array.isArray(address) ? address : [address];
      for (const server of this.address) {
        if (typeof server !== 'object' || !server)
          throw new Error('invalid configuration, every element of address attribute must be an object');
        if (typeof server.url !== 'string' || !server.url)
          throw new Error('invalid configuration, missing server url attribute ');
      }

      this.retry_time_secs = (typeof retry_time_secs === 'number') ? retry_time_secs : DEF_RETRY_TIME;
      this.max_retries = (typeof max_retries === 'number') ? max_retries : DEF_MAX_RETRIES;
      this.is_admin = (typeof is_admin === 'boolean') ? is_admin : false;
      this.ws_options = (typeof ws_options === 'object') ? ws_options : null;
    }

    /**
     * Get the server list of this configuration.
     *
     * @returns {module:janode~ServerObjectConf[]} The address array
     */
    getAddress() {
      return this.address;
    }

    /**
     * Get the number of seconds between any attempt.
     *
     * @returns {number} The value of the property
     */
    getRetryTimeSeconds() {
      return this.retry_time_secs;
    }

    /**
     * Get the max number of retries.
     *
     * @returns {number} The value of the property
     */
    getMaxRetries() {
      return this.max_retries;
    }

    /**
     * Check if the configuration is for an admin connection.
     *
     * @returns {boolean} True if the configuration will be used for an admin connection
     */
    isAdmin() {
      return this.is_admin;
    }

    /**
     * Return the specific WebSocket transport options.
     *
     * @returns {object}
     */
    wsOptions() {
      return this.ws_options;
    }
  }

  var domain;

  // This constructor is used to store event handlers. Instantiating this is
  // faster than explicitly calling `Object.create(null)` to get a "clean" empty
  // object (tested with v8 v4.9).
  function EventHandlers() {}
  EventHandlers.prototype = Object.create(null);

  function EventEmitter() {
    EventEmitter.init.call(this);
  }

  // nodejs oddity
  // require('events') === require('events').EventEmitter
  EventEmitter.EventEmitter = EventEmitter;

  EventEmitter.usingDomains = false;

  EventEmitter.prototype.domain = undefined;
  EventEmitter.prototype._events = undefined;
  EventEmitter.prototype._maxListeners = undefined;

  // By default EventEmitters will print a warning if more than 10 listeners are
  // added to it. This is a useful default which helps finding memory leaks.
  EventEmitter.defaultMaxListeners = 10;

  EventEmitter.init = function() {
    this.domain = null;
    if (EventEmitter.usingDomains) {
      // if there is an active domain, then attach to it.
      if (domain.active) ;
    }

    if (!this._events || this._events === Object.getPrototypeOf(this)._events) {
      this._events = new EventHandlers();
      this._eventsCount = 0;
    }

    this._maxListeners = this._maxListeners || undefined;
  };

  // Obviously not all Emitters should be limited to 10. This function allows
  // that to be increased. Set to zero for unlimited.
  EventEmitter.prototype.setMaxListeners = function setMaxListeners(n) {
    if (typeof n !== 'number' || n < 0 || isNaN(n))
      throw new TypeError('"n" argument must be a positive number');
    this._maxListeners = n;
    return this;
  };

  function $getMaxListeners(that) {
    if (that._maxListeners === undefined)
      return EventEmitter.defaultMaxListeners;
    return that._maxListeners;
  }

  EventEmitter.prototype.getMaxListeners = function getMaxListeners() {
    return $getMaxListeners(this);
  };

  // These standalone emit* functions are used to optimize calling of event
  // handlers for fast cases because emit() itself often has a variable number of
  // arguments and can be deoptimized because of that. These functions always have
  // the same number of arguments and thus do not get deoptimized, so the code
  // inside them can execute faster.
  function emitNone(handler, isFn, self) {
    if (isFn)
      handler.call(self);
    else {
      var len = handler.length;
      var listeners = arrayClone(handler, len);
      for (var i = 0; i < len; ++i)
        listeners[i].call(self);
    }
  }
  function emitOne(handler, isFn, self, arg1) {
    if (isFn)
      handler.call(self, arg1);
    else {
      var len = handler.length;
      var listeners = arrayClone(handler, len);
      for (var i = 0; i < len; ++i)
        listeners[i].call(self, arg1);
    }
  }
  function emitTwo(handler, isFn, self, arg1, arg2) {
    if (isFn)
      handler.call(self, arg1, arg2);
    else {
      var len = handler.length;
      var listeners = arrayClone(handler, len);
      for (var i = 0; i < len; ++i)
        listeners[i].call(self, arg1, arg2);
    }
  }
  function emitThree(handler, isFn, self, arg1, arg2, arg3) {
    if (isFn)
      handler.call(self, arg1, arg2, arg3);
    else {
      var len = handler.length;
      var listeners = arrayClone(handler, len);
      for (var i = 0; i < len; ++i)
        listeners[i].call(self, arg1, arg2, arg3);
    }
  }

  function emitMany(handler, isFn, self, args) {
    if (isFn)
      handler.apply(self, args);
    else {
      var len = handler.length;
      var listeners = arrayClone(handler, len);
      for (var i = 0; i < len; ++i)
        listeners[i].apply(self, args);
    }
  }

  EventEmitter.prototype.emit = function emit(type) {
    var er, handler, len, args, i, events, domain;
    var doError = (type === 'error');

    events = this._events;
    if (events)
      doError = (doError && events.error == null);
    else if (!doError)
      return false;

    domain = this.domain;

    // If there is no 'error' event listener then throw.
    if (doError) {
      er = arguments[1];
      if (domain) {
        if (!er)
          er = new Error('Uncaught, unspecified "error" event');
        er.domainEmitter = this;
        er.domain = domain;
        er.domainThrown = false;
        domain.emit('error', er);
      } else if (er instanceof Error) {
        throw er; // Unhandled 'error' event
      } else {
        // At least give some kind of context to the user
        var err = new Error('Uncaught, unspecified "error" event. (' + er + ')');
        err.context = er;
        throw err;
      }
      return false;
    }

    handler = events[type];

    if (!handler)
      return false;

    var isFn = typeof handler === 'function';
    len = arguments.length;
    switch (len) {
      // fast cases
      case 1:
        emitNone(handler, isFn, this);
        break;
      case 2:
        emitOne(handler, isFn, this, arguments[1]);
        break;
      case 3:
        emitTwo(handler, isFn, this, arguments[1], arguments[2]);
        break;
      case 4:
        emitThree(handler, isFn, this, arguments[1], arguments[2], arguments[3]);
        break;
      // slower
      default:
        args = new Array(len - 1);
        for (i = 1; i < len; i++)
          args[i - 1] = arguments[i];
        emitMany(handler, isFn, this, args);
    }

    return true;
  };

  function _addListener(target, type, listener, prepend) {
    var m;
    var events;
    var existing;

    if (typeof listener !== 'function')
      throw new TypeError('"listener" argument must be a function');

    events = target._events;
    if (!events) {
      events = target._events = new EventHandlers();
      target._eventsCount = 0;
    } else {
      // To avoid recursion in the case that type === "newListener"! Before
      // adding it to the listeners, first emit "newListener".
      if (events.newListener) {
        target.emit('newListener', type,
                    listener.listener ? listener.listener : listener);

        // Re-assign `events` because a newListener handler could have caused the
        // this._events to be assigned to a new object
        events = target._events;
      }
      existing = events[type];
    }

    if (!existing) {
      // Optimize the case of one listener. Don't need the extra array object.
      existing = events[type] = listener;
      ++target._eventsCount;
    } else {
      if (typeof existing === 'function') {
        // Adding the second element, need to change to array.
        existing = events[type] = prepend ? [listener, existing] :
                                            [existing, listener];
      } else {
        // If we've already got an array, just append.
        if (prepend) {
          existing.unshift(listener);
        } else {
          existing.push(listener);
        }
      }

      // Check for listener leak
      if (!existing.warned) {
        m = $getMaxListeners(target);
        if (m && m > 0 && existing.length > m) {
          existing.warned = true;
          var w = new Error('Possible EventEmitter memory leak detected. ' +
                              existing.length + ' ' + type + ' listeners added. ' +
                              'Use emitter.setMaxListeners() to increase limit');
          w.name = 'MaxListenersExceededWarning';
          w.emitter = target;
          w.type = type;
          w.count = existing.length;
          emitWarning(w);
        }
      }
    }

    return target;
  }
  function emitWarning(e) {
    typeof console.warn === 'function' ? console.warn(e) : console.log(e);
  }
  EventEmitter.prototype.addListener = function addListener(type, listener) {
    return _addListener(this, type, listener, false);
  };

  EventEmitter.prototype.on = EventEmitter.prototype.addListener;

  EventEmitter.prototype.prependListener =
      function prependListener(type, listener) {
        return _addListener(this, type, listener, true);
      };

  function _onceWrap(target, type, listener) {
    var fired = false;
    function g() {
      target.removeListener(type, g);
      if (!fired) {
        fired = true;
        listener.apply(target, arguments);
      }
    }
    g.listener = listener;
    return g;
  }

  EventEmitter.prototype.once = function once(type, listener) {
    if (typeof listener !== 'function')
      throw new TypeError('"listener" argument must be a function');
    this.on(type, _onceWrap(this, type, listener));
    return this;
  };

  EventEmitter.prototype.prependOnceListener =
      function prependOnceListener(type, listener) {
        if (typeof listener !== 'function')
          throw new TypeError('"listener" argument must be a function');
        this.prependListener(type, _onceWrap(this, type, listener));
        return this;
      };

  // emits a 'removeListener' event iff the listener was removed
  EventEmitter.prototype.removeListener =
      function removeListener(type, listener) {
        var list, events, position, i, originalListener;

        if (typeof listener !== 'function')
          throw new TypeError('"listener" argument must be a function');

        events = this._events;
        if (!events)
          return this;

        list = events[type];
        if (!list)
          return this;

        if (list === listener || (list.listener && list.listener === listener)) {
          if (--this._eventsCount === 0)
            this._events = new EventHandlers();
          else {
            delete events[type];
            if (events.removeListener)
              this.emit('removeListener', type, list.listener || listener);
          }
        } else if (typeof list !== 'function') {
          position = -1;

          for (i = list.length; i-- > 0;) {
            if (list[i] === listener ||
                (list[i].listener && list[i].listener === listener)) {
              originalListener = list[i].listener;
              position = i;
              break;
            }
          }

          if (position < 0)
            return this;

          if (list.length === 1) {
            list[0] = undefined;
            if (--this._eventsCount === 0) {
              this._events = new EventHandlers();
              return this;
            } else {
              delete events[type];
            }
          } else {
            spliceOne(list, position);
          }

          if (events.removeListener)
            this.emit('removeListener', type, originalListener || listener);
        }

        return this;
      };

  // Alias for removeListener added in NodeJS 10.0
  // https://nodejs.org/api/events.html#events_emitter_off_eventname_listener
  EventEmitter.prototype.off = function(type, listener){
      return this.removeListener(type, listener);
  };

  EventEmitter.prototype.removeAllListeners =
      function removeAllListeners(type) {
        var listeners, events;

        events = this._events;
        if (!events)
          return this;

        // not listening for removeListener, no need to emit
        if (!events.removeListener) {
          if (arguments.length === 0) {
            this._events = new EventHandlers();
            this._eventsCount = 0;
          } else if (events[type]) {
            if (--this._eventsCount === 0)
              this._events = new EventHandlers();
            else
              delete events[type];
          }
          return this;
        }

        // emit removeListener for all listeners on all events
        if (arguments.length === 0) {
          var keys = Object.keys(events);
          for (var i = 0, key; i < keys.length; ++i) {
            key = keys[i];
            if (key === 'removeListener') continue;
            this.removeAllListeners(key);
          }
          this.removeAllListeners('removeListener');
          this._events = new EventHandlers();
          this._eventsCount = 0;
          return this;
        }

        listeners = events[type];

        if (typeof listeners === 'function') {
          this.removeListener(type, listeners);
        } else if (listeners) {
          // LIFO order
          do {
            this.removeListener(type, listeners[listeners.length - 1]);
          } while (listeners[0]);
        }

        return this;
      };

  EventEmitter.prototype.listeners = function listeners(type) {
    var evlistener;
    var ret;
    var events = this._events;

    if (!events)
      ret = [];
    else {
      evlistener = events[type];
      if (!evlistener)
        ret = [];
      else if (typeof evlistener === 'function')
        ret = [evlistener.listener || evlistener];
      else
        ret = unwrapListeners(evlistener);
    }

    return ret;
  };

  EventEmitter.listenerCount = function(emitter, type) {
    if (typeof emitter.listenerCount === 'function') {
      return emitter.listenerCount(type);
    } else {
      return listenerCount.call(emitter, type);
    }
  };

  EventEmitter.prototype.listenerCount = listenerCount;
  function listenerCount(type) {
    var events = this._events;

    if (events) {
      var evlistener = events[type];

      if (typeof evlistener === 'function') {
        return 1;
      } else if (evlistener) {
        return evlistener.length;
      }
    }

    return 0;
  }

  EventEmitter.prototype.eventNames = function eventNames() {
    return this._eventsCount > 0 ? Reflect.ownKeys(this._events) : [];
  };

  // About 1.5x faster than the two-arg version of Array#splice().
  function spliceOne(list, index) {
    for (var i = index, k = i + 1, n = list.length; k < n; i += 1, k += 1)
      list[i] = list[k];
    list.pop();
  }

  function arrayClone(arr, i) {
    var copy = new Array(i);
    while (i--)
      copy[i] = arr[i];
    return copy;
  }

  function unwrapListeners(arr) {
    var ret = new Array(arr.length);
    for (var i = 0; i < ret.length; ++i) {
      ret[i] = arr[i].listener || arr[i];
    }
    return ret;
  }

  /**
   * This module contains several Janus constants related to the Janus/Admin API and Janode, like:<br>
   *
   * - Janus request names<br>
   *
   * - Janus response names<br>
   *
   * - Janus event names<br>
   *
   * - Janode event names<br>
   *
   * Some helper methods related to the protocols are defined here too.
   * @module protocol
   * @private
   */

  /**
   * Janus protocol constants
   *
   * @private
   */
  const JANUS = {
    /**
     * Janus API requests
     */
    REQUEST: {
      /* connection level requests */
      SERVER_INFO: 'info',
      /* session level requests */
      CREATE_SESSION: 'create',
      KEEPALIVE: 'keepalive',
      DESTROY_SESSION: 'destroy',
      /* handle level requests */
      ATTACH_PLUGIN: 'attach',
      MESSAGE: 'message',
      TRICKLE: 'trickle',
      HANGUP: 'hangup',
      DETACH_PLUGIN: 'detach',
    },
    /**
     * Janus temporary response (ack)
     */
    ACK: 'ack',
    /**
     * Janus definitive responses
     */
    RESPONSE: {
      SUCCESS: 'success',
      SERVER_INFO: 'server_info',
      ERROR: 'error',
    },
    /**
     * Janus events
     */
    EVENT: {
      EVENT: 'event',
      DETACHED: 'detached',
      ICE_FAILED: 'ice-failed',
      HANGUP: 'hangup',
      MEDIA: 'media',
      TIMEOUT: 'timeout',
      WEBRTCUP: 'webrtcup',
      SLOWLINK: 'slowlink',
      TRICKLE: 'trickle',
    },
    /**
     * Janus Admin API requests
     */
    ADMIN: {
      LIST_SESSIONS: 'list_sessions',
      LIST_HANDLES: 'list_handles',
      HANDLE_INFO: 'handle_info',
      START_PCAP: 'start_pcap',
      STOP_PCAP: 'stop_pcap',
    },
  };

  /**
   * Janode protocol constants
   *
   * @private
   */
  const JANODE = {
    /**
     * Janode core events.
     */
    EVENT: {
      CONNECTION_CLOSED: 'connection_closed',
      SESSION_DESTROYED: 'session_destroyed',
      HANDLE_DETACHED: 'handle_detached',
      HANDLE_ICE_FAILED: 'handle_ice_failed',
      HANDLE_HANGUP: 'handle_hangup',
      HANDLE_MEDIA: 'handle_media',
      HANDLE_WEBRTCUP: 'handle_webrtcup',
      HANDLE_SLOWLINK: 'handle_slowlink',
      HANDLE_TRICKLE: 'handle_trickle',
      CONNECTION_ERROR: 'connection_error',
    },
  };

  /**
   * Check if a message from Janus is a definitive response.
   *
   * @private
   * @param {object} data - The data from Janus
   * @returns {boolean} True if the check succeeds
   */
  const isResponseData = data => {
    if (typeof data === 'object' && data) {
      return Object.values(JANUS.RESPONSE).includes(data.janus);
    }
    return false;
  };

  /**
   * Check if a message from Janus is an error.
   *
   * @private
   * @param {object} data - The data from Janus
   * @returns {boolean} True if the check succeeds
   */
  const isErrorData = data => {
    if (typeof data === 'object' && data) {
      return data.janus === JANUS.RESPONSE.ERROR;
    }
    return false;
  };

  /**
   * Check if a message from Janus is a timeout notification.
   *
   * @private
   * @param {object} data - The data from Janus
   * @returns {boolean} True if the check succeeds
   */
  const isTimeoutData = data => {
    if (typeof data === 'object' && data) {
      return data.janus === JANUS.EVENT.TIMEOUT;
    }
    return false;
  };

  /**
   * Check if a message from Janus is an ack.
   *
   * @private
   * @param {object} data - The data from Janus
   * @returns {boolean} True if the check succeeds
   */
  const isAckData = data => {
    if (typeof data === 'object' && data) {
      return data.janus === JANUS.ACK;
    }
    return false;
  };

  var commonjsGlobal = typeof globalThis !== 'undefined' ? globalThis : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {};

  function getDefaultExportFromCjs (x) {
  	return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, 'default') ? x['default'] : x;
  }

  var browser;
  var hasRequiredBrowser;

  function requireBrowser () {
  	if (hasRequiredBrowser) return browser;
  	hasRequiredBrowser = 1;
  	// https://github.com/maxogden/websocket-stream/blob/48dc3ddf943e5ada668c31ccd94e9186f02fafbd/ws-fallback.js

  	var ws = null;

  	if (typeof WebSocket !== 'undefined') {
  	  ws = WebSocket;
  	} else if (typeof MozWebSocket !== 'undefined') {
  	  ws = MozWebSocket;
  	} else if (typeof commonjsGlobal !== 'undefined') {
  	  ws = commonjsGlobal.WebSocket || commonjsGlobal.MozWebSocket;
  	} else if (typeof window !== 'undefined') {
  	  ws = window.WebSocket || window.MozWebSocket;
  	} else if (typeof self !== 'undefined') {
  	  ws = self.WebSocket || self.MozWebSocket;
  	}

  	browser = ws;
  	return browser;
  }

  var browserExports = requireBrowser();
  var WebSocket$1 = /*@__PURE__*/getDefaultExportFromCjs(browserExports);

  const LOG_NS$5 = '[transport-ws.js]';

  /* Janus API ws subprotocol */
  const API_WS = 'janus-protocol';
  /* Janus Admin API ws subprotocol */
  const ADMIN_WS = 'janus-admin-protocol';

  /* Default ws ping interval */
  const PING_TIME_SECS = 10;
  /* Default pong wait timeout */
  const PING_TIME_WAIT_SECS = 5;

  /**
   * Class representing a connection through WebSocket transport.<br>
   *
   * In case of failure a connection will be retried according to the configuration (time interval and
   * times to attempt). At every attempt, if multiple addresses are available for Janus, the next address
   * will be tried. An error will be raised only if the maxmimum number of attempts have been reached.<br>
   *
   * Internally uses WebSockets API to establish a connection with Janus and uses ws ping/pong as keepalives.<br>
   *
   * @private
   */
  class TransportWs {
    /**
     * Create a connection through WebSocket.
     *
     * @param {module:connection~Connection} connection - The parent Janode connection
     */
    constructor(connection) {
      /**
       * The parent  Janode connection.
       *
       * @type {module:connection~Connection}
       */
      this._connection = connection;

      /**
       * The internal WebSocket connection.
       *
       * @type {WebSocket}
       */
      this._ws = null;

      /**
       * Internal counter for connection attempts.
       *
       * @type {number}
       */
      this._attempts = 0;

      /**
       * A boolean flag indicating that the connection is being opened.
       *
       * @type {boolean}
       */
      this._opening = false;

      /**
       * A boolean flag indicating that the connection has been opened.
       *
       * @type {boolean}
       */
      this._opened = false;

      /**
       * A boolean flag indicating that the connection is being closed.
       *
       * @type {boolean}
       */
      this._closing = false;

      /**
       * A boolean flag indicating that the connection has been closed.
       *
       * @type {boolean}
       */
      this._closed = false; // true if websocket has been closed after being opened

      /**
       * The task of the peridic ws ping.
       *
       * @type {*}
       */
      this._ping_task = null;

      /**
       * A numerical identifier assigned for logging purposes.
       *
       * @type {number}
       */
      this.id = connection.id;

      /**
       * A more descriptive, not unique string (used for logging).
       *
       * @type {string}
       */
      this.name = `[${this.id}]`;
    }

    /**
     * Initialize the internal WebSocket.
     * Wraps with a promise the standard WebSocket API opening.
     *
     * @returns {Promise<module:connection~Connection>}
     */
    async _initWebSocket() {
      Logger.info(`${LOG_NS$5} ${this.name} trying connection with ${this._connection._address_iterator.currElem().url}`);

      return new Promise((resolve, reject) => {
        const wsOptions = this._connection._config.wsOptions() || { };
        if (!wsOptions.handshakeTimeout) wsOptions.handshakeTimeout = 5000;

        const ws = new WebSocket$1(
          this._connection._address_iterator.currElem().url,
          [this._connection._config.isAdmin() ? ADMIN_WS : API_WS],
          wsOptions);

        /* Register an "open" listener */
        ws.addEventListener('open', _ => {
          Logger.info(`${LOG_NS$5} ${this.name} websocket connected`);
          /* Set the ping/pong task */
          this._setPingTask(PING_TIME_SECS * 1000);
          /* Resolve the promise and return this connection */
          resolve(this);
        }, { once: true });

        /* Register a "close" listener */
        ws.addEventListener('close', ({ code, reason, wasClean }) => {
          Logger.info(`${LOG_NS$5} ${this.name} websocket closed code=${code} reason=${reason} clean=${wasClean}`);
          /* Start cleanup */
          /* Cancel the KA task */
          this._unsetPingTask();
          this._connection._signalClose(this._closing);
          this._closing = false;
          this._closed = true;
          /* removeAllListeners is only supported on the node ws module */
          if (typeof this._ws.removeAllListeners === 'function') this._ws.removeAllListeners();
        }, { once: true });

        /* Register an "error" listener */
        /*
         * The "error" event is fired when a ws connection has been closed due
         * to an error (some data couldn't be sent for example)
         */
        ws.addEventListener('error', error => {
          Logger.error(`${LOG_NS$5} ${this.name} websocket error (${error.message})`);
          reject(error);
        }, { once: true });

        /* Register a "message" listener */
        ws.addEventListener('message', ({ data }) => {
          Logger.debug(`${LOG_NS$5} ${this.name} <ws RCV OK> ${data}`);
          this._connection._handleMessage(JSON.parse(data));
        });

        this._ws = ws;
      });
    }

    /**
     * Internal helper to open a websocket connection.
     * In case of error retry the connection with another address from the available pool.
     * If maximum number of attempts is reached, throws an error.
     *
     * @returns {WebSocket} The websocket connection
     */
    async _attemptOpen() {
      /* Reset status at every attempt, opening should be true at this step */
      this._opened = false;
      this._closing = false;
      this._closed = false;

      try {
        const conn = await this._initWebSocket();
        this._opening = false;
        this._opened = true;
        return conn;
      }
      catch (error) {
        /* In case of error notifies the user, but try with another address */
        this._attempts++;
        /* Get the max number of attempts from the configuration */
        if (this._attempts >= this._connection._config.getMaxRetries()) {
          this._opening = false;
          const err = new Error('attempt limit exceeded');
          Logger.error(`${LOG_NS$5} ${this.name} connection failed, ${err.message}`);
          throw error;
        }
        Logger.error(`${LOG_NS$5} ${this.name} connection failed, will try again in ${this._connection._config.getRetryTimeSeconds()} seconds...`);
        /* Wait an amount of seconds specified in the configuration */
        await delayOp(this._connection._config.getRetryTimeSeconds() * 1000);
        /* Make shift the circular iterator */
        this._connection._address_iterator.nextElem();
        return this._attemptOpen();
      }
    }

    /**
     * Open a transport connection. This is called from parent connection.
     *
     * @returns {Promise<module:connection~Connection>} A promise resolving with the Janode connection
     */
    async open() {
      /* Check the flags before attempting a connection */
      let error;
      if (this._opening) error = new Error('unable to open, websocket is already being opened');
      else if (this._opened) error = new Error('unable to open, websocket has already been opened');
      else if (this._closed) error = new Error('unable to open, websocket has already been closed');

      if (error) {
        Logger.error(`${LOG_NS$5} ${this.name} ${error.message}`);
        throw error;
      }

      /* Set the starting status */
      this._opening = true;
      this._attempts = 0;

      /* Use internal helper */
      return this._attemptOpen();
    }

    /**
     * Send a ws ping frame.
     * This API is only available when the library is not used in a browser.
     *
     * @returns {Promise<void>}
     */
    async _ping() {
      /* ws.ping is only supported on the node "ws" module */
      if (typeof this._ws.ping !== 'function') {
        Logger.warn('ws ping not supported');
        return;
      }
      let timeout;

      /* Set a promise that will reject in PING_TIME_WAIT_SECS seconds */
      const timeout_ping = new Promise((_, reject) => {
        timeout = setTimeout(_ => reject(new Error('timeout')), PING_TIME_WAIT_SECS * 1000);
      });

      /* Set a promise that will resolve once "pong" has been received */
      const ping_op = new Promise((resolve, reject) => {
        /* Send current timestamp in the ping */
        const ping_data = '' + Date.now();

        this._ws.ping(ping_data, error => {
          if (error) {
            Logger.error(`${LOG_NS$5} ${this.name} websocket PING send error (${error.message})`);
            clearTimeout(timeout);
            return reject(error);
          }
          Logger.verbose(`${LOG_NS$5} ${this.name} websocket PING sent (${ping_data})`);
        });

        /* Resolve on pong */
        this._ws.once('pong', data => {
          Logger.verbose(`${LOG_NS$5} ${this.name} websocket PONG received (${data.toString()})`);
          clearTimeout(timeout);
          return resolve();
        });

      });

      /* Race between timeout and pong */
      return Promise.race([ping_op, timeout_ping]);
    }

    /**
     * Set a ws ping-pong task.
     *
     * @param {number} delay - The ping interval in milliseconds
     * @returns {void}
     */
    _setPingTask(delay) {
      /* ws "ping" is only supported on the node ws module */
      if (typeof this._ws.ping !== 'function') {
        Logger.warn('ws ping not supported');
        return;
      }
      if (this._ping_task) return;

      /* Set a periodic task to send a ping */
      /* In case of error, terminate the ws */
      this._ping_task = setInterval(async _ => {
        try {
          await this._ping();
        } catch ({ message }) {
          Logger.error(`${LOG_NS$5} ${this.name} websocket PING error (${message})`);
          /* ws "terminate" is only supported on the node ws module */
          this._ws.terminate();
        }
      }, delay);

      Logger.info(`${LOG_NS$5} ${this.name} websocket ping task scheduled every ${PING_TIME_SECS} seconds`);
    }

    /**
     * Remove the ws ping task.
     *
     * @returns {void}
     */
    _unsetPingTask() {
      if (!this._ping_task) return;
      clearInterval(this._ping_task);
      this._ping_task = null;
      Logger.info(`${LOG_NS$5} ${this.name} websocket ping task disabled`);
    }

    /**
     * Get the remote Janus hostname.
     * It is called from the parent connection.
     *
     * @returns {string} The hostname of the Janus server
     */
    getRemoteHostname() {
      if (this._ws && this._ws.url) {
        return (new URL(this._ws.url)).hostname;
      }
      return null;
    }

    /**
     * Gracefully close the connection.
     * Wraps with a promise the standard WebSocket API "close".
     * It is called from the parent connection.
     *
     * @returns {Promise<void>}
     */
    async close() {
      /* Check the status flags before */
      let error;
      if (!this._opened) error = new Error('unable to close, websocket has never been opened');
      else if (this._closing) error = new Error('unable to close, websocket is already being closed');
      else if (this._closed) error = new Error('unable to close, websocket has already been closed');

      if (error) {
        Logger.error(`${LOG_NS$5} ${this.name} ${error.message}`);
        throw error;
      }

      this._closing = true;

      return new Promise((resolve, reject) => {
        Logger.info(`${LOG_NS$5} ${this.name} closing websocket`);
        try {
          this._ws.close();
          /* Add a listener to resolve the promise */
          this._ws.addEventListener('close', resolve, { once: true });
        } catch (e) {
          Logger.error(`${LOG_NS$5} ${this.name} error while closing websocket (${e.message})`);
          this._closing = false;
          reject(e);
          return;
        }
      });
    }

    /**
     * Send a request from this connection.
     * Wraps with a promise the standard WebSocket API "send".
     * It is called from the parent connection.
     *
     * @param {object} request - The request to be sent
     * @returns {Promise<object>} A promise resolving with a response from Janus
     */
    async send(request) {
      /* Check connection status */
      let error;
      if (!this._opened) error = new Error('unable to send request because connection has not been opened');
      else if (this._closed) error = new Error('unable to send request because connection has been closed');

      if (error) {
        Logger.error(`${LOG_NS$5} ${this.name} ${error.message}`);
        throw error;
      }

      /* Stringify the message */
      const string_req = JSON.stringify(request);

      return new Promise((resolve, reject) => {
        this._ws.send(string_req, { compress: false, binary: false }, error => {
          if (error) {
            Logger.error(`${LOG_NS$5} ${this.name} websocket send error (${error.message})`);
            reject(error);
            return;
          }
          Logger.debug(`${LOG_NS$5} ${this.name} <ws SND OK> ${string_req}`);
          resolve();
        });
      });
    }

  }

  const LOG_NS$4 = '[handle.js]';

  /**
   * Class representing a Janode handle.<br>
   *
   * Users implementing new plugins must extend this class and override the `handleMessage` function.<br>
   *
   * Handle extends EventEmitter, so an instance can emit events and users can subscribe to them.<br>
   *
   * Users are not expected to create Handle instances, but insted use the Session.attach() API.
   *
   * @hideconstructor
   */
  class Handle extends EventEmitter {
    /**
     * Create a Janode handle.
     *
     * @param {module:session~Session} session - A reference to the parent session
     * @param {number} id - The handle identifier
     */
    constructor(session, id) {
      super();

      /**
       * The transaction manager used by this handle.
       *
       * @private
       * @type {TransactionManager}
       */
      this._tm = session._tm; // keep track of pending requests

      /**
       * A boolean flag indicating that the handle is being detached.
       * Once the detach has been completed, the flag returns to false.
       *
       * @private
       * @type {boolean}
       */
      this._detaching = false;

      /**
       * A boolean flag indicating that the handle has been detached.
       *
       * @private
       * @type {boolean}
       */
      this._detached = false;

      /**
       * The parent Janode session.
       *
       * @type {Session}
       */
      this.session = session;

      /**
       * The handle unique id, usually taken from Janus response.
       *
       * @type {number}
       */
      this.id = id;

      /**
       * A more descriptive, not unique string (used for logging).
       *
       * @type {string}
       */
      this.name = `[${this.id}]`;

      /**
       * The callback function used for a session destroyed event.
       *
       * @private
       */
      this._sessionDestroyedListener = this._signalDetach.bind(this);

      /* Set a listener to run a callback when session gets destroyed */
      this.session.once(JANODE.EVENT.SESSION_DESTROYED, this._sessionDestroyedListener);
      /* Set a dummy error listener to avoid to avoid unmanaged errors */
      this.on('error', e => `${LOG_NS$4} ${this.name} catched unmanaged error ${e.message}`);
    }

    /**
     * Cleanup the handle closing all owned transactions, emitting the detached event
     * and removing all registered listeners.
     *
     * @private
     */
    _signalDetach() {
      if (this._detached) return;
      this._detaching = false;
      this._detached = true;

      /* Remove the listener for session destroyed event */
      this.session.removeListener(JANODE.EVENT.SESSION_DESTROYED, this._sessionDestroyedListener);
      /* Close all pending transactions for this handle with an error */
      this._tm.closeAllTransactionsWithError(this, new Error('handle detached'));
      /* Emit the detached event */
      /**
       * The handle has been detached.
       *
       * @event module:handle~Handle#event:HANDLE_DETACHED
       * @type {object}
       * @property {number} id - The handle identifier
       */
      this.emit(JANODE.EVENT.HANDLE_DETACHED, { id: this.id });
      /* Remove all listeners to avoid leaks */
      this.removeAllListeners();
    }

    /**
     * Helper to check if a pending transaction is a trickle.
     *
     * @private
     * @param {string} id - The transaction identifier
     * @returns {boolean}
     */
    _isTrickleTx(id) {
      const tx = this._tm.get(id);
      if (tx) return tx.request === JANUS.REQUEST.TRICKLE;
      return false;
    }

    /**
     * Helper to check if a pending transaction is a hangup.
     *
     * @private
     * @param {string} id - The transaction identifier
     * @returns {boolean}
     */
    _isHangupTx(id) {
      const tx = this._tm.get(id);
      if (tx) return tx.request === JANUS.REQUEST.HANGUP;
      return false;
    }

    /**
     * Helper to check if a pending transaction is a detach.
     *
     * @private
     * @param {string} id - The transaction identifier
     * @returns {boolean}
     */
    _isDetachTx(id) {
      const tx = this._tm.get(id);
      if (tx) return tx.request === JANUS.REQUEST.DETACH_PLUGIN;
      return false;
    }

    /**
     * Manage a message sent to this handle. If this involves a owned transaction
     * and the response is a definitive one, the transaction will be closed.
     * In case the instance implements a `handleMessage` method, this function will
     * pass the message to it on order to let a plugin implements its custom logic.
     * Generic Janus API events like `detached`, `hangup` etc. are handled here.
     *
     * @private
     * @param {object} janus_message
     */
    _handleMessage(janus_message) {
      const { transaction, janus } = janus_message;

      /* First check if a transaction is involved */
      if (transaction) {
        Logger.verbose(`${LOG_NS$4} ${this.name} received ${janus} for transaction ${transaction}`);

        /* First check if this handle owns the transaction */
        if (this.ownsTransaction(transaction)) {

          /*
           * Pending transaction management. Close transaction in case of:
           * 1) Ack response to a trickle request
           * 2) Definitive (success/error) response
           */

          /* Case #1: close tx related to trickles */
          if (isAckData(janus_message)) {
            if (this._isTrickleTx(transaction)) {
              this.closeTransactionWithSuccess(transaction, janus_message);
            }
            return;
          }

          /* Case #2: close tx with a definitive response */
          if (isResponseData(janus_message)) {
            if (isErrorData(janus_message)) {
              /* Case #2 (error): close tx with a definitive error */
              const error = new Error(`${janus_message.error.code} ${janus_message.error.reason}`);
              this.closeTransactionWithError(transaction, error);
              return;
            }

            /* Case #2 (success) */

            /* Close hangup Tx */
            if (this._isHangupTx(transaction)) {
              this.closeTransactionWithSuccess(transaction, janus_message);
              return;
            }

            /* Close detach tx */
            if (this._isDetachTx(transaction)) {
              this.closeTransactionWithSuccess(transaction, janus_message);
              return;
            }

            /*
             * If an instance implements a handleMessage method, try to use it.
             * The custom handler may decide to close tx with success or error.
             * A falsy return from handleMessage is considered as a "not-handled" message.
             */
            if (!this.handleMessage(janus_message)) {
              Logger.verbose(`${LOG_NS$4} ${this.name} received response could not be handled by the plugin`);
            }

            /*
             * As a fallback always close with success a transaction with a definitive success response.
             * Closing a transaction is an indempotent action.
             */
            this.closeTransactionWithSuccess(transaction, janus_message);
            return;
          }
        }
      }

      /* Handling of a message that did not close a transaction (e.g. async events) */
      const janode_event_data = {};
      switch (janus) {

        /* Generic Janus event */
        case JANUS.EVENT.EVENT: {
          /* If an instance implements a handleMessage method, use it */
          if (!this.handleMessage(janus_message)) {
            /* If handleMessage has a falsy return close tx with error */
            Logger.warn(`${LOG_NS$4} ${this.name} received event could not be handled by the plugin`);
            const error = new Error('unmanaged event');
            this.closeTransactionWithError(transaction, error);
          }
          else {
            /* If handleMessage has a truthy return close tx with success */
            this.closeTransactionWithSuccess(transaction, janus_message);
          }
          break;
        }

        /* Detached event: the handle has been detached */
        case JANUS.EVENT.DETACHED: {
          this._signalDetach();
          break;
        }

        /* ice-failed event: Janus ICE agent has detected a failure */
        case JANUS.EVENT.ICE_FAILED: {
          /**
           * The handle has detected an ICE failure.
           *
           * @event module:handle~Handle#event:HANDLE_ICE_FAILED
           * @type {object}
           */
          this.emit(JANODE.EVENT.HANDLE_ICE_FAILED, janode_event_data);
          break;
        }

        /* Hangup event: peer connection is down */
        /* In this case the janus message has a reason field */
        case JANUS.EVENT.HANGUP: {
          if (typeof janus_message.reason !== 'undefined') janode_event_data.reason = janus_message.reason;
          /**
           * The handle WebRTC connection has been closed.
           *
           * @event module:handle~Handle#event:HANDLE_HANGUP
           * @type {object}
           * @property {string} [reason] - The reason of the hangup (e.g. ICE failed)
           */
          this.emit(JANODE.EVENT.HANDLE_HANGUP, janode_event_data);
          break;
        }

        /* Media event: a/v media reception from Janus */
        /* In this case the janus message has "type" and "receiving" fields */
        case JANUS.EVENT.MEDIA: {
          if (typeof janus_message.type !== 'undefined') janode_event_data.type = janus_message.type;
          if (typeof janus_message.receiving !== 'undefined') janode_event_data.receiving = janus_message.receiving;
          if (typeof janus_message.mid !== 'undefined') janode_event_data.mid = janus_message.mid;
          if (typeof janus_message.substream !== 'undefined') janode_event_data.substream = janus_message.substream;
          if (typeof janus_message.seconds !== 'undefined') janode_event_data.seconds = janus_message.seconds;
          /**
           * The handle received a media notification.
           *
           * @event module:handle~Handle#event:HANDLE_MEDIA
           * @type {object}
           * @property {string} type - The kind of media (audio/video)
           * @property {boolean} receiving - True if Janus is receiving media
           * @property {string} [mid] - The involved mid
           * @property {number} [substream] - The involved simulcast substream
           * @property {number} [seconds] - Time, in seconds, with no media
           */
          this.emit(JANODE.EVENT.HANDLE_MEDIA, janode_event_data);
          break;
        }

        /* Webrtcup event: peer connection is up */
        case JANUS.EVENT.WEBRTCUP: {
          /**
           * The handle WebRTC connection is up.
           *
           * @event module:handle~Handle#event:HANDLE_WEBRTCUP
           */
          this.emit(JANODE.EVENT.HANDLE_WEBRTCUP, janode_event_data);
          break;
        }

        /* Slowlink event: NACKs number increasing */
        /* In this case the janus message has "uplink" and "nacks" fields */
        case JANUS.EVENT.SLOWLINK: {
          if (typeof janus_message.uplink !== 'undefined') janode_event_data.uplink = janus_message.uplink;
          if (typeof janus_message.mid !== 'undefined') janode_event_data.mid = janus_message.mid;
          if (typeof janus_message.media !== 'undefined') janode_event_data.media = janus_message.media;
          if (typeof janus_message.lost !== 'undefined') janode_event_data.lost = janus_message.lost;
          /**
           * The handle has received a slowlink notification.
           *
           * @event module:handle~Handle#event:HANDLE_SLOWLINK
           * @type {object}
           * @property {boolean} uplink - The direction of the slow link
           * @property {string} media - The media kind (audio/video)
           * @property {string} [mid] - The involved stream mid
           * @property {number} lost - Number of missing packets in the last time slot
           */
          this.emit(JANODE.EVENT.HANDLE_SLOWLINK, janode_event_data);
          break;
        }

        /* Trickle from Janus */
        case JANUS.EVENT.TRICKLE: {
          /**
           * The handle has received a trickle notification.
           *
           * @event module:handle~Handle#event:HANDLE_TRICKLE
           * @type {object}
           * @property {boolean} [completed] - If true, this notifies the end of triclking (the other fields of the event are missing in this case)
           * @property {string} [sdpMid] - The mid the candidate refers to
           * @property {number} [sdpMLineIndex] - The m-line the candidate refers to
           * @property {string} [candidate] - The candidate string
           */

          const { completed, sdpMid, sdpMLineIndex, candidate } = janus_message.candidate;
          if (!completed) {
            janode_event_data.sdpMid = sdpMid;
            janode_event_data.sdpMLineIndex = sdpMLineIndex;
            janode_event_data.candidate = candidate;
          }
          else {
            janode_event_data.completed = true;
          }

          this.emit(JANODE.EVENT.HANDLE_TRICKLE, janode_event_data);
          break;
        }

        default:
          Logger.error(`${LOG_NS$4} ${this.name} unknown janus event directed to the handle ${JSON.stringify(janus_message)}`);
      }
    }

    /**
     * Decorate request with handle id and transaction (if missing).
     *
     * @private
     * @param {object} request
     */
    _decorateRequest(request) {
      request.transaction = request.transaction || getNumericID();
      request.handle_id = request.handle_id || this.id;
    }

    /**
     * Stub handleMessage (it is overriden by specific plugin handlers).
     * Implementations must return falsy values for unhandled events and truthy value
     * for handled events.
     *
     */
    handleMessage() {
      return null;
    }

    /**
     * Helper to check if the handle is managing a specific transaction.
     *
     * @property {string} id - The transaction id
     * @returns {boolean} True if this handle is the owner
     */
    ownsTransaction(id) {
      return this._tm.getTransactionOwner(id) === this;
    }

    /**
     * Helper to close a transaction with error.
     *
     * @property {string} id - The transaction id
     * @property {object} error - The error object
     * @returns {void}
     */
    closeTransactionWithError(id, error) {
      this._tm.closeTransactionWithError(id, this, error);
      return;
    }

    /**
     * Helper to close a transaction with success.
     *
     * @property {string} id - The transaction id
     * @property {object} [data] - The callback success data
     * @returns {void}
     */
    closeTransactionWithSuccess(id, data) {
      this._tm.closeTransactionWithSuccess(id, this, data);
      return;
    }


    /**
     * Send a request from this handle.
     *
     * @param {object} request
     * @returns {Promise<object>} A promsie resolving with the response to the request
     */
    async sendRequest(request) {
      /* Input check */
      if (typeof request !== 'object' || !request) {
        const error = new Error('request must be an object');
        Logger.error(`${LOG_NS$4} ${this.name} ${error.message}`);
        throw error;
      }

      /* Check handle status */
      if (this._detached) {
        const error = new Error('unable to send request because handle has been detached');
        Logger.error(`${LOG_NS$4} ${this.name} ${error.message}`);
        throw error;
      }

      /* Add handle properties */
      this._decorateRequest(request);

      return new Promise((resolve, reject) => {
        /* Create a new transaction if the transaction does not exist */
        /* Use promise resolve and reject fn as callbacks for the transaction */
        this._tm.createTransaction(request.transaction, this, request.janus, resolve, reject);

        /* Send this message through the parent janode session */
        this.session.sendRequest(request).catch(error => {
          /* In case of error quickly close the transaction */
          this.closeTransactionWithError(request.transaction, error);
        });
      });
    }

    /**
     * Gracefully detach the Handle.
     *
     * @returns {Promise<void>}
     */
    async detach() {
      if (this._detaching) {
        const error = new Error('detaching already in progress');
        Logger.verbose(`${LOG_NS$4} ${this.name} ${error.message}`);
        throw error;
      }
      if (this._detached) {
        const error = new Error('already detached');
        Logger.verbose(`${LOG_NS$4} ${this.name} ${error.message}`);
        throw error;
      }
      Logger.info(`${LOG_NS$4} ${this.name} detaching handle`);
      this._detaching = true;

      const request = {
        janus: JANUS.REQUEST.DETACH_PLUGIN,
      };

      try {
        await this.sendRequest(request);
        this._signalDetach();
        return;
      }
      catch ({ message }) {
        this._detaching = false;
        Logger.error(`${LOG_NS$4} ${this.name} error while detaching (${message})`);
      }
    }

    /**
     * Close the peer connection associated to this handle.
     *
     * @returns {Promise<object>}
     */
    async hangup() {
      const request = {
        janus: JANUS.REQUEST.HANGUP,
      };

      try {
        return this.sendRequest(request);
      }
      catch (error) {
        Logger.error(`${LOG_NS$4} ${this.name} error while hanging up (${error.message})`);
        throw error;
      }
    }

    /**
     * Send an ICE candidate / array of candidates.
     *
     * @param {RTCIceCandidate|RTCIceCandidate[]} candidate
     * @returns {Promise<void>}
     */
    async trickle(candidate) {
      /* If candidate is null or undefined, send an ICE trickle complete message */
      if (!candidate) return this.trickleComplete();

      /* Input checking */
      if (typeof candidate !== 'object') {
        const error = new Error('invalid candidate object');
        Logger.error(`${LOG_NS$4} ${this.name} ${error.message}`);
        throw error;
      }

      const request = {
        janus: JANUS.REQUEST.TRICKLE
      };

      /* WATCH OUT ! In case of an array, the field is name "candidates" */
      if (Array.isArray(candidate)) {
        request.candidates = candidate;
      }
      else {
        request.candidate = candidate;
      }

      try {
        return this.sendRequest(request);
      } catch (error) {
        Logger.error(`${LOG_NS$4} ${this.name} error on trickle (${error.message})`);
        throw error;
      }
    }

    /**
     * Send ICE trickle complete message.
     *
     * @returns {Promise<void>}
     */
    async trickleComplete() {
      return this.trickle({
        completed: true
      });
    }

    /**
     * Send a `message` to Janus from this handle, with given body and optional jsep.
     *
     * @param {object} body - The body of the message
     * @param {RTCSessionDescription} [jsep]
     * @returns {Promise<object>} A promise resolving with the response to the message
     *
     * @example
     * // This is a plugin that sends a message with a custom body
     * const body = {
     *   audio: true,
     *   video: true,
     *   record: false,
     * };
     *
     * await handle.message(body, jsep);
     *
     */
    async message(body, jsep) {
      const request = {
        janus: JANUS.REQUEST.MESSAGE,
        body,
      };
      if (jsep) request.jsep = jsep;

      try {
        return this.sendRequest(request);
      }
      catch (error) {
        Logger.error(`${LOG_NS$4} ${this.name} error on message (${error.message})`);
        throw error;
      }
    }

  }

  const LOG_NS$3 = '[session.js]';

  /**
   * Class representing a Janode session.<br>
   *
   * Session extends EventEmitter, so an instance can emit events and users can subscribe to them.<br>
   *
   * Users are not expected to create Session instances, but insted use the Connection.create() API.
   *
   * @hideconstructor
   */
  class Session extends EventEmitter {
    /**
     * Create a Janode session.
     *
     * @param {module:connection~Connection} connection - A reference to the parent connection
     * @param {number} id - The session identifier
     * @param {number} [ka_interval=30] - The keepalive interval in seconds
     */
    constructor(connection, id, ka_interval = 30) {
      super();

      /**
       * The transaction manager used by this session.
       *
       * @private
       * @type {TransactionManager}
       */
      this._tm = connection._tm;

      /**
       * A boolean flag indicating that the session is being destroyed.
       * Once the destroy has been completed, the flag returns to false.
       *
       * @private
       * @type {boolean}
       */
      this._destroying = false;

      /**
       * A boolean flag indicating that the session has been destroyed.
       *
       * @private
       * @type {boolean}
       */
      this._destroyed = false;

      /**
       * Keep track of the handles.
       *
       * @private
       * @type {Map}
       */
      this._handles = new Map(); // keep track of the handles

      /**
       * The task of the peridic keep-alive.
       *
       * @private
       */
      this._ka_task = null;

      /**
       * The parent Janode connection.
       *
       * @type {module:connection~Connection}
       */
      this.connection = connection;

      /**
       * The session unique id, usually taken from Janus response.
       *
       * @type {number}
       */
      this.id = id;

      /**
       * A more descriptive, not unique string (used for logging).
       *
       * @type {string}
       */
      this.name = `[${this.id}]`;

      /* Enable keep-alive when creating the session */
      this._setKeepAlive(ka_interval * 1000);

      /**
       * The callback function used for a connection closed event.
       *
       * @private
       */
      this._closedListener = this._signalDestroy.bind(this);

      /**
       * The callback function used for a connection error event.
       *
       * @private
       */
      this._errorListener = this._signalDestroy.bind(this);

      /* Set a listener to run a callback when the connection gets closed */
      this.connection.once(JANODE.EVENT.CONNECTION_CLOSED, this._closedListener);
      /* Set a listener to run a callback when the connection fails */
      this.connection.once(JANODE.EVENT.CONNECTION_ERROR, this._errorListener);
      /* Set a dummy error listener to avoid unmanaged errors */
      this.on('error', e => `${LOG_NS$3} ${this.name} catched unmanaged error ${e.message}`);
    }

    /**
     * Cleanup the session canceling the KA task, closing all owned transactions, emitting the destroyed event
     * and removing all registered listeners.
     *
     * @private
     */
    _signalDestroy() {
      if (this._destroyed) return;
      this._destroying = false;
      this._destroyed = true;

      /* Cancel the KA task */
      this._unsetKeepAlive();
      /* Remove the listeners on the connection */
      this.connection.removeListener(JANODE.EVENT.CONNECTION_CLOSED, this._closedListener);
      this.connection.removeListener(JANODE.EVENT.CONNECTION_ERROR, this._errorListener);
      /* Close all pending transactions for this session with an error */
      this._tm.closeAllTransactionsWithError(this, new Error('session destroyed'));
      /* Clear handle table */
      this._handles.clear();
      /* Emit the destroyed event */
      /**
       * The session has been destroyed.
       *
       * @event module:session~Session#event:SESSION_DESTROYED
       * @type {object}
       * @property {number} id - The session identifier
       */
      this.emit(JANODE.EVENT.SESSION_DESTROYED, { id: this.id });
      /* Remove all listeners to avoid leaks */
      this.removeAllListeners();
    }

    /**
     * Send a keep-alive request.
     * The returned promise will return upon keep-alive response or a wait timeout.
     *
     * @private
     * @param {number} timeout - The timeout in milliseconds before detecting a ka timeout
     * @returns {Promise<void>}
     */
    async _sendKeepAlive(timeout) {
      const request = {
        janus: JANUS.REQUEST.KEEPALIVE,
      };

      let timeout_task;
      const timeout_ka = new Promise((_, reject) => {
        timeout_task = setTimeout(_ => reject(new Error('timeout')), timeout);
      });

      Logger.verbose(`${LOG_NS$3} ${this.name} sending keep-alive (timeout=${timeout}ms)`);
      const ka_op = this.sendRequest(request).then(_ => {
        Logger.verbose(`${LOG_NS$3} ${this.name} keep-alive OK`);
        clearTimeout(timeout_task);
      }).catch(e => {
        Logger.error(`${LOG_NS$3} ${this.name} keep-alive error (${e.message})`);
        clearTimeout(timeout_task);
        throw e;
      });

      return Promise.race([ka_op, timeout_ka]);
    }

    /**
     * Helper method to enable the keep-alive task with a given period.
     *
     * @private
     * @param {number} delay - The period of the task in milliseconds
     */
    _setKeepAlive(delay) {
      if (this._ka_task) return;
      const timeout = delay / 2;

      this._ka_task = setInterval(_ => {
        this._sendKeepAlive(timeout).catch(({ message }) => {
          /* If a keep-alive fails destroy the session */
          if (!this._destroyed) {
            const error = new Error(`keep-alive failed (${message})`);
            Logger.error(`${LOG_NS$3} ${this.name} ${error.message}`);
          }
          this._signalDestroy();
        });
      }, delay);

      Logger.info(`${LOG_NS$3} ${this.name} session keep-alive task scheduled every ${delay} milliseconds`);
    }

    /**
     * Helper method to disable the keep-alive task.
     *
     * @private
     */
    _unsetKeepAlive() {
      if (!this._ka_task) return;
      clearInterval(this._ka_task);
      this._ka_task = null;
      Logger.info(`${LOG_NS$3} ${this.name} session keep-alive task disabled`);
    }

    /**
     * Helper to check if a pending transaction is a keep-alive.
     *
     * @private
     * @param {string} id - The transaction identifier
     * @returns {boolean}
     */
    _isKeepaliveTx(id) {
      const tx = this._tm.get(id);
      if (tx) return (tx.request === JANUS.REQUEST.KEEPALIVE);
      return false;
    }

    /**
     * Manage a message sent to this session.  If a handle is involved let it manage the message.
     * Trickles transactions are closed here because the Janus API lacks the handle id in the ack.
     * If the message involves a owned transaction and the response is a definitive one,
     * the transaction will be closed.
     *
     * @private
     * @param {object} janus_message
     */
    _handleMessage(janus_message) {
      const { sender, janus, transaction } = janus_message;

      /* First check if a handle is involved */
      if (sender) {
        /* Look for the handle in the map */
        const handle = this._handles.get(sender);
        /* If the handle is missing notifies the user */
        if (!handle) {
          if (janus === JANUS.EVENT.HANDLE_DETACHED_PLUGIN) {
            /* In case of duplicate "detached" try to not pollute the logs */
            Logger.verbose(`${LOG_NS$3} ${this.name} handle ${sender} not found for incoming message "${janus}"`);
            return;
          }
          Logger.verbose(`${LOG_NS$3} ${this.name} handle ${sender} not found for incoming message "${janus}"`);
          return;
        }

        /* Let the handle manage the message */
        handle._handleMessage(janus_message);
        return;
      }

      /* Ack responses for trickle requests (sent by handles) are exceptionally handled here */
      /* Since the sender id is missing from the ack response, we need to understand the handle that originated the request */
      if (transaction) {
        /* Fetch the owner of the transaction */
        const owner = this._tm.getTransactionOwner(transaction);
        /* Is the owner one of the handle of this session? */
        const handle = owner ? this._handles.get(owner.id) : null;

        if (handle && handle === owner) {
          /* The handle has been found and it is the owner of the tx, let it close the tx */
          handle._handleMessage(janus_message);
          return;
        }
      }

      /* Check if this message is a transaction of this session */
      if (transaction) {
        Logger.verbose(`${LOG_NS$3} ${this.name} received ${janus} for transaction ${transaction}`);

        /* Not owned by the session? */
        if (this._tm.getTransactionOwner(transaction) !== this) {
          Logger.warn(`${LOG_NS$3} ${this.name} transaction ${transaction} not found for incoming message ${janus}`);
          return;
        }

        /*
         * Pending session transaction management.
         * Close transaction in case of:
         * 1) Definitive response
         * 2) Any response to a Keep-Alive request
         */
        if (isResponseData(janus_message) || this._isKeepaliveTx(transaction)) {
          if (isErrorData(janus_message)) {
            const error = new Error(`${janus_message.error.code} ${janus_message.error.reason}`);
            this._tm.closeTransactionWithError(transaction, this, error);
            return;
          }

          this._tm.closeTransactionWithSuccess(transaction, this, janus_message);
        }

        return;
      }

      /* Session timeout event from Janus */
      if (isTimeoutData(janus_message)) {
        Logger.warn(`${LOG_NS$3} ${this.name} session timed out by Janus server!`);
        /* Let's cleanup the session */
        this._signalDestroy();
        return;
      }

      /* No handle, no transaction, no timeout? */
      Logger.error(`${LOG_NS$3} ${this.name} unexpected janus message directed to the session ${JSON.stringify(janus_message)}`);
    }

    /**
     * Decorate request with session id and transaction (if missing).
     *
     * @private
     * @param {object} request
     */
    _decorateRequest(request) {
      request.transaction = request.transaction || getNumericID();
      request.session_id = request.session_id || this.id;
    }

    /**
     * Send a request from this session.
     *
     * @param {object} request
     * @returns {Promise<object>} A promise resolving with the response
     */
    async sendRequest(request) {
      /* Input check */
      if (typeof request !== 'object' || !request) {
        const error = new Error('request must be an object');
        Logger.error(`${LOG_NS$3} ${this.name} ${error.message}`);
        throw error;
      }

      /* Check session status */
      if (this._destroyed) {
        const error = new Error('unable to send request because session has been destroyed');
        Logger.error(`${LOG_NS$3} ${this.name} ${error.message}`);
        throw error;
      }

      /* Add session properties */
      this._decorateRequest(request);

      return new Promise((resolve, reject) => {
        /* Create a new transaction if the transaction does not exist */
        /* Use promise resolve and reject fn as callbacks for the transaction */
        this._tm.createTransaction(request.transaction, this, request.janus, resolve, reject);

        /* Send this message through the parent janode connection */
        this.connection.sendRequest(request).catch(error => {
          /* In case of error quickly close the transaction */
          this._tm.closeTransactionWithError(request.transaction, this, error);
        });
      });
    }

    /**
     * Gracefully destroy the session.
     *
     * @returns {Promise<void>}
     */
    async destroy() {
      Logger.info(`${LOG_NS$3} ${this.name} destroying session`);
      if (this._destroying) {
        const error = new Error('destroying already in progress');
        Logger.warn(`${LOG_NS$3} ${this.name} ${error.message}`);
        throw error;
      }
      if (this._destroyed) {
        const error = new Error('session already destroyed');
        Logger.warn(`${LOG_NS$3} ${this.name} ${error.message}`);
        throw error;
      }
      this._destroying = true;

      const request = {
        janus: JANUS.REQUEST.DESTROY_SESSION,
      };

      try {
        await this.sendRequest(request);
        this._signalDestroy();
        return;
      }
      catch (error) {
        Logger.error(`${LOG_NS$3} ${this.name} error while destroying session (${error.message})`);
        this._destroying = false;
        throw error;
      }
    }

    /**
     * Attach a plugin in this session using a plugin descriptor.
     * If the Handle param is missing, a new generic Handle will be attached.
     * Returns a promise with the pending attach operation.
     *
     * @param {module:janode~PluginDescriptor} descriptor - The plugin descriptor
     * @returns {Promise<module:handle~Handle>}
     *
     * @example
     *
     * // attach an echotest plugin with its specifc class
     * import EchoTestPlugin from 'janode/src/plugins/echotest-plugin.js';
     * const echoHandle = await janodeSession.attach(EchoTestPlugin);
     *
     * // attach a plugin without using its custom implementation
     * const handle = await session.attach({ id: 'janus.plugin.echotest' });
     *
     */
    async attach({ id, Handle: Handle$1 = Handle }) {
      Logger.info(`${LOG_NS$3} ${this.name} attaching new handle`);

      if (!id) {
        const error = new Error('plugin identifier null or not valid');
        throw error;
      }

      const request = {
        janus: JANUS.REQUEST.ATTACH_PLUGIN,
        plugin: id,
      };

      try {
        const { data } = await this.sendRequest(request);
        /* Increase the maximum number of listeners for this session */
        /* The handle will register one listener */
        this.setMaxListeners(this.getMaxListeners() + 1);

        /* If the plugin Handle class is defined, use it to create a custom handle */
        /* Or simply create a generic handle with standard methods and events */
        const handle_instance = new Handle$1(this, data.id);

        /* Add the new handle to the table */
        this._handles.set(handle_instance.id, handle_instance);

        /* On handle detach delete the entry from handle map and decrease the number of listeners */
        handle_instance.once(JANODE.EVENT.HANDLE_DETACHED, ({ id }) => {
          this._handles.delete(id);
          this.setMaxListeners(this.getMaxListeners() - 1);
        });

        Logger.info(`${LOG_NS$3} ${this.name} handle attached (id=${handle_instance.id})`);
        return handle_instance;
      }
      catch (error) {
        Logger.error(`${LOG_NS$3} ${this.name} handle attach error (${error.message})`);
        throw error;
      }
    }

  }

  const LOG_NS$2 = '[tmanager.js]';

  const debug = getCliArgument('debug-tx', 'boolean', false);

  /**
   * Class representing a Janode Transaction Manager (TM).
   * A transaction manager stores the pending transactions and has methods to create and close transactions.
   * Every transaction objects has an identifier, a reference to the owner and a kind of janus request.
   *
   * @private
   */
  class TransactionManager {
    /**
     * Create a Transacton Manager (TM)
     *
     * @param {string} [id] - The identifier given to the manager (got from a counter if missing)
     */
    constructor(id = getNumericID()) {
      this.transactions = new Map();
      this.id = id;
      Logger.info(`${LOG_NS$2} [${this.id}] creating new transaction manager (debug=${debug})`);
      this._dbgtask = null;
      /* If tx debugging is enabled, periodically print the size of the tx table */
    }

    /**
     * Clear the internal transaction table and the debugging printing task.
     */
    clear() {
      Logger.info(`${LOG_NS$2} [${this.id}] clearing transaction manager`);
      clearInterval(this._dbgtask);
      this.transactions.clear();
    }

    /**
     * Check if the TM has a specific transaction.
     *
     * @param {string} id - The transaction id
     * @returns {boolean} True if the manager contains the transaction
     */
    has(id) {
      if (!id) return false;
      return this.transactions.has(id);
    }

    /**
     * Get a specific transaction from the TM.
     *
     * @param {string} id - The transaction id
     * @returns {PendingTransaction|void} The wanted transaction, or nothing if missing
     */
    get(id) {
      if (!id) return null;
      if (!this.has(id)) return null;
      return this.transactions.get(id);
    }

    /**
     * Get the current size of the transaction table.
     *
     * @returns {number} The size of the table
     */
    size() {
      return this.transactions.size;
    }

    /**
     * Add a pending transaction to the TM.
     *
     * @param {string} id - The transaction id
     * @param {PendingTransaction} transaction
     */
    set(id, transaction) {
      if (!id) return;
      if (!transaction) return;
      this.transactions.set(id, transaction);
      if (debug && !this._dbgtask) {
        this._dbgtask = setInterval(_ => {
          Logger.info(`${LOG_NS$2} [${this.id}] TM DEBUG size=${this.size()}`);
        }, 5000);
      }
    }

    /**
     * Delete a specific transaction from the TM.
     *
     * @param {string} id - The transaction id to delete
     */
    delete(id) {
      if (!id) return;
      if (!this.has(id)) return;
      this.transactions.delete(id);
    }

    /**
     * Get the owner of a specific transaction id.
     *
     * @param {string} id - The transaction id
     * @returns {object|void} A reference to the owner object, or nothing if transaction is missing
     */
    getTransactionOwner(id) {
      if (!id) return;
      if (!this.has(id)) return;
      return this.get(id).owner;
    }

    /**
     * Create a new transaction if id does not exist in the table and add it to the TM.
     *
     * @param {string} id - The transaction identifier
     * @param {object} owner - A reference to the object that created the transaction
     * @param {string} request - The janus request for the pending transaction
     * @param {function} done - The success callback
     * @param {function} error - The error callback
     * @returns {PendingTransaction|void} The newly created transaction, or nothing if the id already exists
     */
    createTransaction(id, owner, request, done, error) {
      if (this.has(id)) return;
      const tx = {
        id,
        owner,
        request,
        done,
        error,
      };
      this.set(id, tx);
      Logger.verbose(`${LOG_NS$2} [${tx.owner.id}] created new transaction ${id}, request "${tx.request}"`);
      return tx;
    }

    /**
     * Close a transaction with an error if the id is found and the owner matches.
     * The closed transaction will be removed from the internal table and the error cb will be invoked with the error string.
     *
     * @param {string} id - The transaction identifier
     * @param {object} owner - A reference to the transaction owner
     * @param {object} error - The error object
     * @returns {PendingTransaction|void} The closed transaction, or nothing if the id does not exist or the owner does not match
     */
    closeTransactionWithError(id, owner, error) {
      const tx = this.get(id);
      if (!tx) return;
      if (tx.owner !== owner) return;
      this.delete(id);
      tx.error(error);
      Logger.verbose(`${LOG_NS$2} [${tx.owner.id}] closed with error transaction ${id}, request "${tx.request}"`);
      return tx;
    }

    /**
     * Close all the stored transactions with an error.
     * If an owner is specified only the owner's transaction will be closed.
     * The closed transactions will be removed from the internal table.
     *
     * @param {object} [owner] - A reference to the transaction owner
     * @param {object} error - The error object
     */
    closeAllTransactionsWithError(owner, error) {
      for (const [_, pendingTx] of this.transactions) {
        if (!owner || pendingTx.owner === owner)
          this.closeTransactionWithError(pendingTx.id, pendingTx.owner, error);
      }
    }

    /**
     * Close a transaction with success if the id is found and the owner matches.
     * The closed transaction will be removed from the internal table and the success cb will be invoked with the specified data.
     *
     * @param {string} id - The transaction identifier
     * @param {object} owner - A reference to the transaction owner
     * @param {*} data - The success callback data
     * @returns {PendingTransaction|void} The closed transaction, or nothing if the id does not exist or the owner does not match
     */
    closeTransactionWithSuccess(id, owner, data) {
      const tx = this.get(id);
      if (!tx) return;
      if (tx.owner !== owner) return;
      this.delete(id);
      tx.done(data);
      Logger.verbose(`${LOG_NS$2} [${tx.owner.id}] closed with success transaction ${id}, request "${tx.request}"`);
      return tx;
    }
  }

  const LOG_NS$1 = '[connection.js]';
  function UnixTransport() { this.open = _ => Logger.error('unix-dgram unsupported on browsers');}

  /**
   * Class representing a Janode connection.<br>
   *
   * Specific transports are picked by checking the connection URI.<br>
   *
   * This class implements both the Janus API and Admin API.<br>
   *
   * Connection extends EventEmitter, so an instance can emit events and users can subscribe to them.<br>
   *
   * Users are not expected to create Connection instances, but insted use the Janode.connect() API.<br>
   *
   * @hideconstructor
   */
  class Connection extends EventEmitter {
    /**
     * Create a Janode Connection.
     *
     * @param {module:configuration~Configuration} server_config - The Janode configuration as created by the Configuration constructor.
     */
    constructor(server_config) {
      super();

      /**
       * The configuration in use for this connection.
       *
       * @private
       * @type {module:configuration~Configuration}
       */
      this._config = server_config;

      /**
       * The transaction manager used by this connection.
       *
       * @private
       * @type {module:tmanager~TransactionManager}
       */
      this._tm = new TransactionManager();

      /**
       * Keep track of the sessions.
       *
       * @private
       * @type {Map}
       */
      this._sessions = new Map();

      /**
       * The iterator to select available Janus addresses.
       *
       * @private
       * @type {module:utils~CircularIterator}
       */
      this._address_iterator = newIterator(this._config.getAddress());

      /**
       * A numerical identifier assigned for logging purposes.
       *
       * @type {number}
       */
      this.id = parseInt(getNumericID());

      /**
       * A more descriptive, not unique string (used for logging).
       *
       * @type {string}
       */
      this.name = `[${this.id}]`;

      /**
       * The internal transport that will be used for the connection.
       *
       * @typedef {object} Transport
       * @property {function} open
       * @property {function} close
       * @property {function} send
       * @property {function} getRemoteHostname
       */
      this._transport = {
        open: async _ => { throw new Error('transport does not implement the "open" function'); },
        close: async _ => { throw new Error('transport does not implement the "close" function'); },
        send: async _ => { throw new Error('transport does not implement the "send" function'); },
        getRemoteHostname: _ => { throw new Error('transport does not implement the "getRemoteHostname" function'); },
      };

      try {
        let transport;
        /* Check the protocol to define the kind of transport */
        if (checkUrl(server_config.getAddress()[0].url, ['ws', 'wss', 'ws+unix', 'wss+unix'])) {
          transport = new TransportWs(this);
        }
        if (checkUrl(server_config.getAddress()[0].url, ['file'])) {
          transport = new UnixTransport(this);
        }
        if (transport) this._transport = transport;
      } catch (error) {
        Logger.error(`${LOG_NS$1} ${this.name} error while initializing transport (${error.message})`);
      }

      /* Set a dummy error listener to avoid unmanaged errors */
      this.on('error', e => `${LOG_NS$1} ${this.name} catched unmanaged error ${e.message}`);
    }

    /**
     * Cleanup the connection closing all owned transactions and emitting the destroyed event
     * and removing all registered listeners.
     *
     * @private
     * @param {boolean} graceful - True if this is an expected disconnection
     */
    _signalClose(graceful) {
      /* Close all pending transactions inside this connection with an error */
      this._tm.closeAllTransactionsWithError(null, new Error('connection closed'));
      /* Clear tx table */
      this._tm.clear();
      /* Clear session table */
      this._sessions.clear();

      /* Did we really mean to close it? */
      if (graceful) {
        /* This is a greceful teardown */
        /**
         * The connection has been closed.
         *
         * @event module:connection~Connection#event:CONNECTION_CLOSED
         * @type {object}
         * @property {number} id - The connection identifier
         */
        this.emit(JANODE.EVENT.CONNECTION_CLOSED, { id: this.id });
      }
      else {
        /* If this event is unexpected emit an error */
        const error = new Error('unexpected disconnection');
        /**
         * An error occurred on the connection.
         *
         * @event module:connection~Connection#event:CONNECTION_ERROR
         * @type {Error}
         */
        this.emit(JANODE.EVENT.CONNECTION_ERROR, error);
      }

      /* Remove all listeners to avoid leaks */
      this.removeAllListeners();
    }

    /**
     * Open a connection using the transport defined open method.
     * Users do not need to call this method, since the connection is opened by Janode.connect().
     *
     * @returns {Promise<module:connection~Connection>} A promise resolving with the Janode connection
     */
    async open() {
      await this._transport.open();
      return this;
    }

    /**
     * Manage a message sent to this session.  If a session is involved let it manage the message.
     * If the message involves a owned transaction and the response is a definitive one,
     * the transaction will be closed.
     *
     * @private
     * @param {object} janus_message
     */
    _handleMessage(janus_message) {
      const { session_id, transaction, janus } = janus_message;

      /* Check if a session is involved */
      if (session_id && !this._config.isAdmin()) {
        /* Look for the session in the map */
        const session = this._sessions.get(session_id);
        /* If the handle is missing notifies the user */
        if (!session) {
          Logger.warn(`${LOG_NS$1} ${this.name} session ${session_id} not found for incoming message ${janus}`);
          return;
        }

        try {
          /* Let the session manage the message */
          session._handleMessage(janus_message);
        } catch (error) {
          Logger.error(`${LOG_NS$1} ${this.name} error while handling message (${error.message})`);
        }
        return;
      }

      /* Check if a transaction is involved */
      if (transaction) {
        Logger.verbose(`${LOG_NS$1} ${this.name} received ${janus} for transaction ${transaction}`);

        /* Not owned by this connection? */
        if (this._tm.getTransactionOwner(transaction) !== this) {
          Logger.warn(`${LOG_NS$1} ${this.name} transaction ${transaction} not found for incoming messsage ${janus}`);
          return;
        }

        /*
         * Pending connection transaction management.
         * Close transaction in case of:
         * 1) Definitive response
         */
        if (isResponseData(janus_message)) {
          if (isErrorData(janus_message)) {
            const error = new Error(`${janus_message.error.code} ${janus_message.error.reason}`);
            return this._tm.closeTransactionWithError(transaction, this, error);
          }

          this._tm.closeTransactionWithSuccess(transaction, this, janus_message);
        }

        return;
      }

      /* No session, no transaction? */
      Logger.error(`${LOG_NS$1} ${this.name} unexpected janus message directed to the connection ${JSON.stringify(janus_message)}`);
    }

    /**
     * Decorate request with apisecret, token and transaction (if missing).
     *
     * @private
     * @param {object} request
     */
    _decorateRequest(request) {
      request.transaction = request.transaction || getNumericID();
      if (this._address_iterator.currElem().apisecret) {
        if (!this._config.isAdmin())
          request.apisecret = request.apisecret || this._address_iterator.currElem().apisecret;
        else
          request.admin_secret = request.admin_secret || this._address_iterator.currElem().apisecret;
      }
      if (this._address_iterator.currElem().token)
        request.token = this._address_iterator.currElem().token;
    }

    /**
     * Gracefully close the connection using the transport defined close method.
     *
     * @returns {Promise<void>}
     */
    async close() {
      await this._transport.close();
      return;
    }

    /**
     * Send a request from this connection using the transport defined send method.
     *
     * @param {object} request - The request to be sent
     * @returns {Promise<object>} A promise resolving with a response from Janus
     */
    async sendRequest(request) {
      /* Add connection properties */
      this._decorateRequest(request);

      return new Promise((resolve, reject) => {
        /* Create a new transaction if the transaction does not exist */
        /* Use promise resolve and reject fn as callbacks for the transaction */
        this._tm.createTransaction(request.transaction, this, request.janus, resolve, reject);

        this._transport.send(request).catch(error => {
          /* In case of error quickly close the transaction */
          this._tm.closeTransactionWithError(request.transaction, this, error);
          reject(error);
          return;
        });
      });
    }

    /**
     * Get the remote Janus hostname using the transport defined method.
     *
     * @returns {string} The hostname of the Janus server
     */
    getRemoteHostname() {
      return this._transport.getRemoteHostname();
    }

    /**
     * Create a new session in this connection.
     *
     * @param {number} [ka_interval] - The time interval (seconds) for session keep-alive requests
     * @returns {Promise<module:session~Session>} The newly created session
     *
     * @example
     *
     * const session = await connection.create();
     * Logger.info(`***** SESSION CREATED *****`);
     */
    async create(ka_interval) {
      Logger.info(`${LOG_NS$1} ${this.name} creating new session`);

      const request = {
        janus: JANUS.REQUEST.CREATE_SESSION,
      };

      try {
        const { data: { id } } = await this.sendRequest(request);
        /* Increase the maximum number of listeners for this connection */
        /* The session will register two listeners */
        this.setMaxListeners(this.getMaxListeners() + 2);

        /* Create a new Janode Session and add it to the table */
        const session_instance = new Session(this, id, ka_interval);
        this._sessions.set(session_instance.id, session_instance);

        /* On session destroy delete the entry from session map and decrease the number of listeners */
        session_instance.once(JANODE.EVENT.SESSION_DESTROYED, ({ id }) => {
          this._sessions.delete(id);
          this.setMaxListeners(this.getMaxListeners() - 2);
        });

        Logger.info(`${LOG_NS$1} ${this.name} session created (id=${id})`);
        return session_instance;
      }
      catch (error) {
        Logger.error(`${LOG_NS$1} ${this.name} session creation error (${error.message})`);
        throw error;
      }
    }

    /**
     * Janus GET INFO API.
     *
     * @returns {Promise<object>} The Get Info response
     *
     * @example
     *
     * const info = await connection.getInfo();
     * Logger.info(`${info.name} ${info.version_string}`);
     */
    async getInfo() {
      Logger.info(`${LOG_NS$1} ${this.name} requesting server info`);

      const request = {
        janus: JANUS.REQUEST.SERVER_INFO,
      };

      return this.sendRequest(request);
    }

    /*************/
    /* ADMIN API */
    /*************/

    /* The following APIs are available only if a connection has been created with is_admin = true in the config */

    /**
     * (Admin API) List the sessions in a janus instance.
     *
     * @returns {Promise<object>}
     *
     * @example
     *
     * const data = await connection.listSessions();
     * Logger.info(`${JSON.stringify(data)}`);
     */
    async listSessions() {
      Logger.verbose(`${LOG_NS$1} ${this.name} requesting session list`);

      const request = {
        janus: JANUS.ADMIN.LIST_SESSIONS,
      };

      return this.sendRequest(request);
    }

    /**
     * (Admin API) List the handles in a session.
     *
     * @param {number} session_id - The identifier of the session
     * @returns {Promise<object>}
     *
     * @example
     *
     * const data = await connection.listSessions();
     * Logger.info(`${JSON.stringify(data)}`);
     */
    async listHandles(session_id) {
      Logger.info(`${LOG_NS$1} ${this.name} requesting handle list`);
      if (!session_id) {
        const error = new Error('session_id parameter not specified');
        Logger.error(`${LOG_NS$1} ${this.name} ${error.message}`);
        throw error;
      }
      const request = {
        janus: JANUS.ADMIN.LIST_HANDLES,
        session_id,
      };

      return this.sendRequest(request);
    }

    /**
     * (Admin API) Get an handle info.
     *
     * @param {number} session_id - The session identifier
     * @param {number} handle_id - The handle identifier
     * @returns {Promise<object>} The Get Handle Info response
     *
     * @example
     *
     * const data = await connection.handleInfo(session.id, handle.id);
     * Logger.info(`${JSON.stringify(data)}`);
     */
    async handleInfo(session_id, handle_id) {
      Logger.info(`${LOG_NS$1} ${this.name} requesting handle info`);
      if (!session_id) {
        const error = new Error('session_id parameter not specified');
        Logger.error(`${LOG_NS$1} ${this.name} ${error.message}`);
        throw error;
      }
      if (!handle_id) {
        const error = new Error('handle_id parameter not specified');
        Logger.error(`${LOG_NS$1} ${this.name} ${error.message}`);
        throw error;
      }
      const request = {
        janus: JANUS.ADMIN.HANDLE_INFO,
        session_id,
        handle_id,
      };

      return this.sendRequest(request);
    }

    /**
     * (Admin API) Start a packet capture on an handle.
     *
     * @param {number} session_id - The session identifier
     * @param {number} handle_id - The handle identifier
     * @param {string} folder - The folder in which save the pcap
     * @param {string} filename - The pcap file name
     * @param {number} [truncate] - Number of bytes to truncate the pcap to
     * @returns {Promise<object>} The start pcap response
     */
    async startPcap(session_id, handle_id, folder, filename, truncate) {
      Logger.info(`${LOG_NS$1} ${this.name} requesting pcap start for handle ${handle_id}`);
      if (!session_id) {
        const error = new Error('session_id parameter not specified');
        Logger.error(`${LOG_NS$1} ${this.name} ${error.message}`);
        throw error;
      }
      if (!handle_id) {
        const error = new Error('handle_id parameter not specified');
        Logger.error(`${LOG_NS$1} ${this.name} ${error.message}`);
        throw error;
      }
      if (typeof folder !== 'string' || typeof filename !== 'string') {
        const error = new Error('invalid folder or filename specified');
        Logger.error(`${LOG_NS$1} ${this.name} ${error.message}`);
        throw error;
      }
      const request = {
        janus: JANUS.ADMIN.START_PCAP,
        session_id,
        handle_id,
        folder,
        filename,
      };
      if ((typeof truncate === 'number') && truncate > 0) {
        request.truncate = truncate;
      }

      return this.sendRequest(request);
    }

    /**
     * Stop an ogoing packet capture.
     *
     * @param {number} session_id - The session identifier
     * @param {number} handle_id - The handle identifier
     * @returns {Promsie<object>} The stop pcap response
     */
    async stopPcap(session_id, handle_id) {
      Logger.info(`${LOG_NS$1} ${this.name} requesting pcap stop for handle ${handle_id}`);
      if (!session_id) {
        const error = new Error('session_id parameter not specified');
        Logger.error(`${LOG_NS$1} ${this.name} ${error.message}`);
        throw error;
      }
      if (!handle_id) {
        const error = new Error('handle_id parameter not specified');
        Logger.error(`${LOG_NS$1} ${this.name} ${error.message}`);
        throw error;
      }
      const request = {
        janus: JANUS.ADMIN.STOP_PCAP,
        session_id,
        handle_id,
      };

      return this.sendRequest(request);
    }

  }

  const LOG_NS = '[janode.js]';
  const { EVENT } = JANODE;

  /**
   * An object describing a janus server (e.g. url, secret).
   *
   * @typedef {object} ServerObjectConf
   * @property {string} url - The URL to reach this server API
   * @property {string} apisecret - The API secret for this server
   * @property {string} [token] - The optional Janus API token
   */

  /**
   * The configuration passed by the user.
   *
   * @typedef {object} RawConfiguration
   * @property {string} [server_key] - The key used to refer to this server in Janode.connect
   * @property {module:janode~ServerObjectConf[]|module:janode~ServerObjectConf} address - The server to connect to
   * @property {number} [retry_time_secs=10] - The seconds between any connection attempts
   * @property {number} [max_retries=5] - The maximum number of retries before issuing a connection error
   * @property {boolean} [is_admin=false] - True if the connection is dedicated to the Janus Admin API
   * @property {object} [ws_options] - Specific WebSocket transport options
   */

  /**
   * The plugin descriptor used when attaching a plugin from a session.
   *
   * @typedef {object} PluginDescriptor
   * @property {string} id - The plugin id used when sending the attach request to Janus
   * @property {module:handle~Handle} [Handle] - The class implementing the handle
   * @property {object} [EVENT] - The object with the list of events emitted by the plugin
   */

  /**
   * Connect using a defined configuration.<br>
   *
   * The input configuration can be an object or an array. In case it is an array and the param "key" is provided,
   * Janode will pick a server configuration according to "key" type. If it is a number it will pick the index "key" of the array.
   * If it is a string it will pick the server configuration that matches the "server_key" property.
   * In case "key" is missing, Janode will fallback to index 0.
   *
   * @param {module:janode~RawConfiguration|module:janode~RawConfiguration[]} config - The configuration to be used
   * @param {number|string} [key=0] - The index of the config in the array to use, or the server of the arrray matching this server key
   * @returns {Promise<module:connection~Connection>} The promise resolving with the Janode connection
   *
   * @example
   *
   * // simple example with single object and no key
   * const connection = await Janode.connect({
   *   address: {
   *	   url: 'ws://127.0.0.1:8188/',
   *	   apisecret: 'secret'
   *	 },
   * });
   *
   * // example with an array and a key 'server_2'
   * // connection is established with ws://127.0.0.1:8002
   * const connection = await Janode.connect([{
   *   server_key: 'server_1',
   *   address: {
   *	   url: 'ws://127.0.0.1:8001/',
   *	   apisecret: 'secret'
   *	 },
   * },
   * {
   *   server_key: 'server_2',
   *   address: {
   *	   url: 'ws://127.0.0.1:8002/',
   *	   apisecret: 'secondsecret'
   *	 },
   * }], 'server_2');
   *
   * // example with an array and a key 'server_B' with multiple addresses
   * // connection is attempted starting with ws://127.0.0.1:8003
   * const connection = await Janode.connect([{
   *   server_key: 'server_A',
   *   address: {
   *	   url: 'ws://127.0.0.1:8001/',
   *	   apisecret: 'secret'
   *	 },
   * },
   * {
   *   server_key: 'server_B',
   *   address: [{
   *	   url: 'ws://127.0.0.1:8003/',
   *	   apisecret: 'secondsecret'
   *	 },
   *   {
   *     url: 'ws://127.0.0.2:9003/',
   *	   apisecret: 'thirdsecret'
   *   }],
   * }], 'server_B');
   */
  const connect = (config = {}, key = null) => {
    Logger.info(`${LOG_NS} creating new connection`);

    const janus_server_list = Array.isArray(config) ? config : [config];
    let index = 0;
    if (typeof key === 'number')
      index = key;
    if (typeof key === 'string')
      index = janus_server_list.findIndex(({ server_key }) => server_key === key);
    if (!key)
      Logger.verbose(`${LOG_NS} omitted server key, falling back to the first server in configuration`);

    const server_raw_conf = janus_server_list[index];
    if (!server_raw_conf) {
      const error = new Error(`server configuration not defined for server #${key || index}`);
      Logger.error(`${LOG_NS} ${error.message}`);
      throw error;
    }

    const server_conf = new Configuration(server_raw_conf);
    Logger.verbose(`${LOG_NS} creating connection with server configuration ${JSON.stringify(server_conf)}`);
    const janus_connection = new Connection(server_conf);
    return janus_connection.open();
  };

  var Janode = {
    connect,
    /**
     * The Logger used in Janode.
     *
     * @property {function} debug - Print out a debug message
     * @property {function} verbose - Print out a verbose message
     * @property {function} info - Print out an info message
     * @property {function} warn - Print out a warning message
     * @property {function} error - Print out an error message
     * @property {function} setLevel - Set logger level
     */
    Logger,

    /**
     * Events emitted by Janode
     *
     * @property {string} CONNECTION_CLOSED - {@link module:connection~Connection#event:CONNECTION_CLOSED}
     * @property {string} SESSION_DESTROYED - {@link module:session~Session#event:SESSION_DESTROYED}
     * @property {string} HANDLE_DETACHED - {@link module:handle~Handle#event:HANDLE_DETACHED}
     * @property {string} HANDLE_HANGUP - {@link module:handle~Handle#event:HANDLE_HANGUP}
     * @property {string} HANDLE_MEDIA - {@link module:handle~Handle#event:HANDLE_MEDIA}
     * @property {string} HANDLE_WEBRTCUP - {@link module:handle~Handle#event:HANDLE_WEBRTCUP}
     * @property {string} HANDLE_SLOWLINK - {@link module:handle~Handle#event:HANDLE_SLOWLINK}
     * @property {string} CONNECTION_ERROR - {@link module:connection~Connection#event:CONNECTION_ERROR}
     */
    EVENT,
  };

  /* The plugin ID exported in the plugin descriptor */
  const PLUGIN_ID = 'janus.plugin.videoroom';

  /* These are the requests defined for the Janus VideoRoom API */
  const REQUEST_JOIN = 'join';
  const REQUEST_CONFIGURE = 'configure';
  const REQUEST_JOIN_CONFIGURE = 'joinandconfigure';
  const REQUEST_LIST_PARTICIPANTS = 'listparticipants';
  const REQUEST_ENABLE_RECORDING = 'enable_recording';
  const REQUEST_KICK = 'kick';
  const REQUEST_START = 'start';
  const REQUEST_PAUSE = 'pause';
  const REQUEST_SWITCH = 'switch';
  const REQUEST_PUBLISH = 'publish';
  const REQUEST_UNPUBLISH = 'unpublish';
  const REQUEST_LEAVE = 'leave';
  const REQUEST_UPDATE = 'update';

  const REQUEST_EXISTS = 'exists';
  const REQUEST_LIST_ROOMS = 'list';
  const REQUEST_CREATE = 'create';
  const REQUEST_DESTROY = 'destroy';
  const REQUEST_ALLOW = 'allowed';

  const REQUEST_RTP_FWD_START = 'rtp_forward';
  const REQUEST_RTP_FWD_STOP = 'stop_rtp_forward';
  const REQUEST_RTP_FWD_LIST = 'listforwarders';

  const PTYPE_PUBLISHER = 'publisher';
  const PTYPE_LISTENER = 'subscriber';

  /* These are the events/responses that the Janode plugin will manage */
  /* Some of them will be exported in the plugin descriptor */
  const PLUGIN_EVENT = {
    PUB_JOINED: 'videoroom_joined',
    SUB_JOINED: 'videoroom_subscribed',
    PUB_LIST: 'videoroom_publisher_list',
    PARTICIPANTS_LIST: 'videoroom_participants_list',
    PUB_PEER_JOINED: 'videoroom_publisher_joined',
    STARTED: 'videoroom_started',
    PAUSED: 'videoroom_paused',
    SWITCHED: 'videoroom_switched',
    CONFIGURED: 'videoroom_configured',
    SLOW_LINK: 'videoroom_slowlink',
    DISPLAY: 'videoroom_display',
    UNPUBLISHED: 'videoroom_unpublished',
    LEAVING: 'videoroom_leaving',
    UPDATED: 'videoroom_updated',
    KICKED: 'videoroom_kicked',
    RECORDING_ENABLED_STATE: 'videoroom_recording_enabled_state',
    TALKING: 'videoroom_talking',
    SC_SUBSTREAM_LAYER: 'videoroom_sc_substream_layer',
    SC_TEMPORAL_LAYERS: 'videoroom_sc_temporal_layers',
    ALLOWED: 'videoroom_allowed',
    EXISTS: 'videoroom_exists',
    ROOMS_LIST: 'videoroom_list',
    CREATED: 'videoroom_created',
    DESTROYED: 'videoroom_destroyed',
    RTP_FWD_STARTED: 'videoroom_rtp_fwd_started',
    RTP_FWD_STOPPED: 'videoroom_rtp_fwd_stopped',
    RTP_FWD_LIST: 'videoroom_rtp_fwd_list',
    SUCCESS: 'videoroom_success',
    ERROR: 'videoroom_error',
  };

  /**
   * The class implementing the VideoRoom plugin (ref. {@link https://janus.conf.meetecho.com/docs/videoroom.html}).<br>
   *
   * It extends the base Janode Handle class and overrides the "handleMessage" method.<br>
   *
   * Moreover it defines many methods to support VideoRoom operations.<br>
   *
   * @hideconstructor
   */
  class VideoRoomHandle extends Handle {
    /**
     * Create a Janode VideoRoom handle.
     *
     * @param {module:session~Session} session - A reference to the parent session
     * @param {number} id - The handle identifier
     */
    constructor(session, id) {
      super(session, id);

      /**
       * Either the feed identifier assigned to this publisher handle or the publisher's feed in case this handle is a subscriber.
       *
       * @type {number|string}
       */
      this.feed = null;

      /**
       * [multistream]
       * Either the streams assigned to this publisher handle or the streams subscribed to in case this handle is a subscriber.
       *
       * @type {object[]}
       */
      this.streams = null;

      /**
       * The identifier of the videoroom the handle has joined.
       *
       * @type {number|string}
       */
      this.room = null;
    }

    /**
     * The custom "handleMessage" needed for handling VideoRoom messages.
     *
     * @private
     * @param {object} janus_message
     * @returns {object} A falsy value for unhandled events, a truthy value for handled events
     */
    handleMessage(janus_message) {
      const { plugindata, jsep, transaction } = janus_message;
      if (plugindata && plugindata.data && plugindata.data.videoroom) {
        /**
         * @type {VideoRoomData}
         */
        const message_data = plugindata.data;
        const { videoroom, error, error_code, room } = message_data;

        /* Prepare an object for the output Janode event */
        const janode_event = {
          /* The name of the resolved event */
          event: null,
          /* The event payload */
          data: {},
        };

        /* Add JSEP data if available */
        if (jsep) janode_event.data.jsep = jsep;
        if (jsep && typeof jsep.e2ee === 'boolean') janode_event.data.e2ee = jsep.e2ee;
        /* Add room information if available */
        if (room) janode_event.data.room = room;

        /* The plugin will emit an event only if the handle does not own the transaction */
        /* That means that a transaction has already been closed or this is an async event */
        const emit = (this.ownsTransaction(transaction) === false);

        /* Use the "janode" property to store the output event */
        janus_message._janode = janode_event;

        switch (videoroom) {

          /* Success response */
          case 'success':
            /* Room exists API */
            if (typeof message_data.exists !== 'undefined') {
              janode_event.data.exists = message_data.exists;
              janode_event.event = PLUGIN_EVENT.EXISTS;
              break;
            }
            /* Room list API */
            if (typeof message_data.list !== 'undefined') {
              janode_event.data.list = message_data.list;
              janode_event.event = PLUGIN_EVENT.ROOMS_LIST;
              break;
            }
            /* Tokens management (add/remove/enable) */
            if (typeof message_data.allowed !== 'undefined') {
              janode_event.data.list = message_data.allowed;
              janode_event.event = PLUGIN_EVENT.ALLOWED;
              break;
            }
            /* Global recording enabled or disabled */
            if (typeof message_data.record !== 'undefined') {
              janode_event.data.record = message_data.record;
              janode_event.event = PLUGIN_EVENT.RECORDING_ENABLED_STATE;
              break;
            }

            /* Generic success event */
            janode_event.event = PLUGIN_EVENT.SUCCESS;
            break;

          /* Publisher joined */
          case 'joined':
            /* Store room and feed id */
            this.room = room;
            this.feed = message_data.id;

            janode_event.data.feed = message_data.id;
            janode_event.data.description = message_data.description;
            janode_event.data.private_id = message_data.private_id;
            janode_event.data.publishers = message_data.publishers.map(({ id, display, talking, audio_codec, video_codec, simulcast, streams }) => {
              const pub = {
                feed: id,
                display,
              };
              if (typeof talking !== 'undefined') pub.talking = talking;
              if (typeof audio_codec !== 'undefined') pub.audiocodec = audio_codec;
              if (typeof video_codec !== 'undefined') pub.videocodec = video_codec;
              if (typeof simulcast !== 'undefined') pub.simulcast = simulcast;
              /* [multistream] add streams info for this participant */
              if (typeof streams !== 'undefined') pub.streams = streams;
              return pub;
            });
            janode_event.event = PLUGIN_EVENT.PUB_JOINED;
            break;

          /* Subscriber joined */
          case 'attached':
            /* Store room and feed id */
            this.room = room;
            if (typeof message_data.id !== 'undefined') {
              this.feed = message_data.id;
              janode_event.data.feed = message_data.id;
              janode_event.data.display = message_data.display;
            }

            /* [multistream] add streams info to the subscriber joined event */
            if (typeof message_data.streams !== 'undefined') {
              this.streams = message_data.streams;
              janode_event.data.streams = message_data.streams;
            }

            janode_event.event = PLUGIN_EVENT.SUB_JOINED;
            break;

          /* Slow-link event */
          case 'slow_link':
            if (this.feed) janode_event.data.feed = this.feed;
            janode_event.data.bitrate = message_data['current-bitrate'];
            janode_event.event = PLUGIN_EVENT.SLOW_LINK;
            break;

          /* Participants list */
          case 'participants':
            janode_event.data.participants = message_data.participants.map(({ id, display, publisher, talking }) => {
              const peer = {
                feed: id,
                display,
                publisher,
              };
              if (typeof talking !== 'undefined') peer.talking = talking;
              return peer;
            });
            janode_event.event = PLUGIN_EVENT.PARTICIPANTS_LIST;
            break;

          /* Room created */
          case 'created':
            janode_event.event = PLUGIN_EVENT.CREATED;
            janode_event.data.permanent = message_data.permanent;
            break;

          /* Room destroyed */
          case 'destroyed':
            janode_event.event = PLUGIN_EVENT.DESTROYED;
            break;

          /* RTP forwarding started */
          case 'rtp_forward':
            janode_event.data.feed = message_data.publisher_id;
            if (message_data.rtp_stream) {
              const f = message_data.rtp_stream;
              const fwd = {
                host: f.host,
              };
              if (f.audio_stream_id) {
                fwd.audio_stream = f.audio_stream_id;
                fwd.audio_port = f.audio;
                if (typeof f.audio_rtcp === 'number') {
                  fwd.audio_rtcp_port = f.audio_rtcp;
                }
              }
              if (f.video_stream_id) {
                fwd.video_stream = f.video_stream_id;
                fwd.video_port = f.video;
                if (typeof f.video_rtcp === 'number') {
                  fwd.video_rtcp_port = f.video_rtcp;
                }
                if (f.video_stream_id_2) {
                  fwd.video_stream_2 = f.video_stream_id_2;
                  fwd.video_port_2 = f.video_2;
                }
                if (f.video_stream_id_3) {
                  fwd.video_stream_3 = f.video_stream_id_3;
                  fwd.video_port_3 = f.video_3;
                }
              }
              if (f.data_stream_id) {
                fwd.data_stream = f.data_stream_id;
                fwd.data_port = f.data;
              }

              janode_event.data.forwarder = fwd;
            }
            /* [multistream] */
            else if (message_data.forwarders) {
              janode_event.data.forwarders = message_data.forwarders.map(f => {
                const fwd = {
                  host: f.host,
                };
                if (f.type === 'audio') {
                  fwd.audio_stream = f.stream_id;
                  fwd.audio_port = f.port;
                  if (typeof f.remote_rtcp_port === 'number') {
                    fwd.audio_rtcp_port = f.remote_rtcp_port;
                  }
                }
                if (f.type === 'video') {
                  fwd.video_stream = f.stream_id;
                  fwd.video_port = f.port;
                  if (typeof f.remote_rtcp_port === 'number') {
                    fwd.video_rtcp_port = f.remote_rtcp_port;
                  }
                  if (typeof f.substream === 'number') {
                    fwd.sc_substream_layer = f.substream;
                  }
                }
                if (f.type === 'data') {
                  fwd.data_stream = f.stream_id;
                  fwd.data_port = f.port;
                }
                if (typeof f.ssrc === 'number') {
                  fwd.ssrc = f.ssrc;
                }
                if (typeof f.pt === 'number') {
                  fwd.pt = f.pt;
                }
                if (typeof f.srtp === 'boolean') {
                  fwd.srtp = f.srtp;
                }

                return fwd;
              });
            }

            janode_event.event = PLUGIN_EVENT.RTP_FWD_STARTED;
            break;

          /* RTP forwarding stopped */
          case 'stop_rtp_forward':
            janode_event.data.feed = message_data.publisher_id;
            janode_event.data.stream = message_data.stream_id;
            janode_event.event = PLUGIN_EVENT.RTP_FWD_STOPPED;
            break;

          /* RTP forwarders list */
          case 'forwarders':
            if (message_data.rtp_forwarders) {
              janode_event.data.forwarders = message_data.rtp_forwarders.map(({ publisher_id, rtp_forwarder }) => {
                const pub = {
                  feed: publisher_id,
                };

                pub.forwarders = rtp_forwarder.map(f => {
                  const fwd = {
                    host: f.ip,
                  };
                  if (f.audio_stream_id) {
                    fwd.audio_stream = f.audio_stream_id;
                    fwd.audio_port = f.port;
                    if (typeof f.remote_rtcp_port === 'number') {
                      fwd.audio_rtcp_port = f.remote_rtcp_port;
                    }
                  }
                  if (f.video_stream_id) {
                    fwd.video_stream = f.video_stream_id;
                    fwd.video_port = f.port;
                    if (typeof f.remote_rtcp_port === 'number') {
                      fwd.video_rtcp_port = f.remote_rtcp_port;
                    }
                    if (typeof f.substream === 'number') {
                      fwd.sc_substream_layer = f.substream;
                    }
                  }
                  if (f.data_stream_id) {
                    fwd.data_stream = f.data_stream_id;
                    fwd.data_port = f.port;
                  }
                  if (typeof f.ssrc === 'number') {
                    fwd.ssrc = f.ssrc;
                  }
                  if (typeof f.pt === 'number') {
                    fwd.pt = f.pt;
                  }
                  if (typeof f.srtp === 'boolean') {
                    fwd.srtp = f.srtp;
                  }

                  return fwd;
                });

                return pub;
              });
            }
            /* [multistream] */
            else if (message_data.publishers) {
              janode_event.data.forwarders = message_data.publishers.map(({ publisher_id, forwarders }) => {
                const pub = {
                  feed: publisher_id,
                };

                pub.forwarders = forwarders.map(f => {
                  const fwd = {
                    host: f.host,
                  };
                  if (f.type === 'audio') {
                    fwd.audio_stream = f.stream_id;
                    fwd.audio_port = f.port;
                    if (typeof f.remote_rtcp_port === 'number') {
                      fwd.audio_rtcp_port = f.remote_rtcp_port;
                    }
                  }
                  if (f.type === 'video') {
                    fwd.video_stream = f.stream_id;
                    fwd.video_port = f.port;
                    if (typeof f.remote_rtcp_port === 'number') {
                      fwd.video_rtcp_port = f.remote_rtcp_port;
                    }
                    if (typeof f.substream === 'number') {
                      fwd.sc_substream_layer = f.substream;
                    }
                  }
                  if (f.type === 'data') {
                    fwd.data_stream = f.stream_id;
                    fwd.data_port = f.port;
                  }
                  if (typeof f.ssrc === 'number') {
                    fwd.ssrc = f.ssrc;
                  }
                  if (typeof f.pt === 'number') {
                    fwd.pt = f.pt;
                  }
                  if (typeof f.srtp === 'boolean') {
                    fwd.srtp = f.srtp;
                  }
                  return fwd;
                });

                return pub;
              });
            }

            janode_event.event = PLUGIN_EVENT.RTP_FWD_LIST;
            break;

          /* Talking events */
          case 'talking':
          case 'stopped-talking':
            janode_event.data.feed = message_data.id;
            janode_event.data.talking = (videoroom === 'talking');
            /* [multistream] */
            if (typeof message_data.mid !== 'undefined') janode_event.data.mid = message_data.mid;
            janode_event.data.audio_level = message_data['audio-level-dBov-avg'];
            janode_event.event = PLUGIN_EVENT.TALKING;
            break;

          /* [multistream] updated event */
          case 'updated':
            janode_event.data.streams = message_data.streams;
            janode_event.event = PLUGIN_EVENT.UPDATED;
            break;

          /* [multistream] updating event, sent when janus receives another "update" before getting a JSEP answer for the previous one */
          case 'updating':
            janode_event.data.streams = message_data.streams;
            janode_event.event = PLUGIN_EVENT.UPDATED;
            break;

          /* Generic events (error, notifications ...) */
          case 'event':
            /* VideoRoom Error */
            if (error) {
              janode_event.event = PLUGIN_EVENT.ERROR;
              janode_event.data = new Error(`${error_code} ${error}`);
              janode_event.data._code = error_code;
              /* In case of error, close a transaction */
              this.closeTransactionWithError(transaction, janode_event.data);
              break;
            }
            /* Participant joined notification (notify_joining) */
            if (message_data.joining) {
              janode_event.event = PLUGIN_EVENT.PUB_PEER_JOINED;
              janode_event.data.feed = message_data.joining.id;
              if (message_data.joining.display) janode_event.data.display = message_data.joining.display;
              break;
            }
            /* Publisher list notification */
            if (message_data.publishers) {
              janode_event.event = PLUGIN_EVENT.PUB_LIST;
              janode_event.data.publishers = message_data.publishers.map(({ id, display, talking, audio_codec, video_codec, simulcast, streams }) => {
                const pub = {
                  feed: id,
                  display,
                };
                if (typeof talking !== 'undefined') pub.talking = talking;
                if (typeof audio_codec !== 'undefined') pub.audiocodec = audio_codec;
                if (typeof video_codec !== 'undefined') pub.videocodec = video_codec;
                if (typeof simulcast !== 'undefined') pub.simulcast = simulcast;
                /* [multistream] add streams info for this participant */
                if (typeof streams !== 'undefined') pub.streams = streams;
                return pub;
              });
              break;
            }
            /* Configuration events (publishing, general configuration) */
            if (typeof message_data.configured !== 'undefined') {
              janode_event.event = PLUGIN_EVENT.CONFIGURED;
              if (this.feed) janode_event.data.feed = this.feed;
              /* [multistream] add streams info */
              if (typeof message_data.streams !== 'undefined') janode_event.data.streams = message_data.streams;
              janode_event.data.configured = message_data.configured;
              break;
            }
            /* Subscribed feed started */
            if (typeof message_data.started !== 'undefined') {
              janode_event.event = PLUGIN_EVENT.STARTED;
              if (this.feed) janode_event.data.feed = this.feed;
              janode_event.data.started = message_data.started;
              break;
            }
            /* Subscribed feed paused */
            if (typeof message_data.paused !== 'undefined') {
              janode_event.event = PLUGIN_EVENT.PAUSED;
              if (this.feed) janode_event.data.feed = this.feed;
              janode_event.data.paused = message_data.paused;
              break;
            }
            /* Subscribed feed switched */
            if (typeof message_data.switched !== 'undefined') {
              janode_event.event = PLUGIN_EVENT.SWITCHED;
              janode_event.data.switched = message_data.switched;
              if (message_data.switched === 'ok') {
                if (typeof message_data.id !== 'undefined') {
                  janode_event.data.from_feed = this.feed;
                  this.feed = message_data.id;
                  janode_event.data.to_feed = this.feed;
                  janode_event.data.display = message_data.display;
                }
                if (typeof message_data.streams != 'undefined') {
                  this.streams = message_data.streams;
                  janode_event.data.streams = message_data.streams;
                }
              }
              break;
            }
            /* Unpublished own or other feed */
            if (typeof message_data.unpublished !== 'undefined') {
              janode_event.event = PLUGIN_EVENT.UNPUBLISHED;
              janode_event.data.feed = (message_data.unpublished === 'ok') ? this.feed : message_data.unpublished;
              if (message_data.display) janode_event.data.display = message_data.display;
              break;
            }
            /* Leaving confirmation */
            if (typeof message_data.leaving !== 'undefined') {
              janode_event.event = PLUGIN_EVENT.LEAVING;
              janode_event.data.feed = (message_data.leaving === 'ok') ? this.feed : message_data.leaving;
              if (message_data.reason) janode_event.data.reason = message_data.reason;
              if (message_data.display) janode_event.data.display = message_data.display;
              break;
            }
            /* Display name changed event */
            if (typeof message_data.display !== 'undefined' && typeof message_data.switched === 'undefined') {
              janode_event.event = PLUGIN_EVENT.DISPLAY;
              janode_event.data.feed = message_data.id;
              janode_event.data.display = message_data.display;
              break;
            }
            /* Participant kicked out */
            if (typeof message_data.kicked !== 'undefined') {
              janode_event.event = PLUGIN_EVENT.KICKED;
              janode_event.data.feed = message_data.kicked;
              break;
            }
            /* Participant left (for subscribers "leave") */
            if (typeof message_data.left !== 'undefined') {
              janode_event.event = PLUGIN_EVENT.LEAVING;
              if (this.feed) janode_event.data.feed = this.feed;
              break;
            }
            /* Simulcast substream layer switch */
            if (typeof message_data.substream !== 'undefined') {
              janode_event.event = PLUGIN_EVENT.SC_SUBSTREAM_LAYER;
              if (this.feed) janode_event.data.feed = this.feed;
              /* [multistream] */
              if (typeof message_data.mid !== 'undefined') janode_event.data.mid = message_data.mid;
              janode_event.data.sc_substream_layer = message_data.substream;
              break;
            }
            /* Simulcast temporal layers switch */
            if (typeof message_data.temporal !== 'undefined') {
              janode_event.event = PLUGIN_EVENT.SC_TEMPORAL_LAYERS;
              if (this.feed) janode_event.data.feed = this.feed;
              /* [multistream] */
              if (typeof message_data.mid !== 'undefined') janode_event.data.mid = message_data.mid;
              janode_event.data.sc_temporal_layers = message_data.temporal;
              break;
            }
        }

        /* The event has been handled */
        if (janode_event.event) {
          /* Try to close the transaction */
          this.closeTransactionWithSuccess(transaction, janus_message);
          /* If the transaction was not owned, emit the event */
          if (emit) this.emit(janode_event.event, janode_event.data);
          return janode_event;
        }
      }

      /* The event has not been handled, return a falsy value */
      return null;
    }

    /*----------*/
    /* USER API */
    /*----------*/

    /* These are the APIs that users need to work with the videoroom plugin */

    /**
     * Join a videoroom as publisher.
     *
     * @param {object} params
     * @param {number|string} params.room - The room to join to
     * @param {number|string} [params.feed] - The feed identifier to use, if missing it is picked by Janus
     * @param {boolean} [params.audio] - True to request audio relaying
     * @param {boolean} [params.video] - True to request video relaying
     * @param {boolean} [params.data] - True to request datachannel relaying
     * @param {string} [params.display] - The display name to use
     * @param {number} [params.bitrate] - Bitrate cap
     * @param {string} [params.token] - The optional token needed to join the room
     * @param {string} [params.pin] - The optional pin needed to join the room
     * @param {boolean} [params.record] - Enable the recording
     * @param {string} [params.filename] - If recording, the base path/file to use for the recording
     * @param {object[]} [params.descriptions] - [multistream] The descriptions object, can define a description for the tracks separately e.g. track mid:0 'Video Camera', track mid:1 'Screen'
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_PUB_JOINED>}
     */
    async joinPublisher({ room, feed, audio, video, data, bitrate, record, filename, display, token, pin, descriptions }) {
      const body = {
        request: REQUEST_JOIN,
        ptype: PTYPE_PUBLISHER,
        room,
      };
      if (typeof feed === 'string' || typeof feed === 'number') body.id = feed;
      if (typeof display === 'string') body.display = display;
      if (typeof audio === 'boolean') body.audio = audio;
      if (typeof video === 'boolean') body.video = video;
      if (typeof data === 'boolean') body.data = data;
      if (typeof bitrate === 'number') body.bitrate = bitrate;
      if (typeof record === 'boolean') body.record = record;
      if (typeof filename === 'string') body.filename = filename;
      if (typeof token === 'string') body.token = token;
      if (typeof pin === 'string') body.pin = pin;

      /* [multistream] */
      if (descriptions && Array.isArray(descriptions)) body.descriptions = descriptions;

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.PUB_JOINED) {
        if (body.display) evtdata.display = body.display;
        return evtdata;
      }
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Join and configure videoroom handle as publisher.
     *
     * @param {object} params
     * @param {number|string} params.room - The room to join to
     * @param {number|string} [params.feed] - The feed identifier to use, if missing it is picked by Janus
     * @param {boolean} [params.audio] - True to request audio relaying
     * @param {boolean} [params.video] - True to request video relaying
     * @param {boolean} [params.data] - True to request datachannel relaying
     * @param {string} [params.display] - The display name to use
     * @param {number} [params.bitrate] - Bitrate cap
     * @param {string} [params.token] - The optional token needed to join the room
     * @param {string} [params.pin] - The optional pin needed to join the room
     * @param {boolean} [params.record] - Enable the recording
     * @param {string} [params.filename] - If recording, the base path/file to use for the recording
     * @param {boolean} [params.e2ee] - True to notify end-to-end encryption for this connection
     * @param {object[]} [params.descriptions] - [multistream] The descriptions object, can define a description for the tracks separately e.g. track mid:0 'Video Camera', track mid:1 'Screen'
     * @param {RTCSessionDescription} [params.jsep] - The JSEP offer
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_PUB_JOINED>}
     */
    async joinConfigurePublisher({ room, feed, audio, video, data, bitrate, record, filename, display, token, pin, e2ee, descriptions, jsep }) {
      const body = {
        request: REQUEST_JOIN_CONFIGURE,
        ptype: PTYPE_PUBLISHER,
        room,
      };
      if (typeof feed === 'string' || typeof feed === 'number') body.id = feed;
      if (typeof display === 'string') body.display = display;
      if (typeof audio === 'boolean') body.audio = audio;
      if (typeof video === 'boolean') body.video = video;
      if (typeof data === 'boolean') body.data = data;
      if (typeof bitrate === 'number') body.bitrate = bitrate;
      if (typeof record === 'boolean') body.record = record;
      if (typeof filename === 'string') body.filename = filename;
      if (typeof token === 'string') body.token = token;
      if (typeof pin === 'string') body.pin = pin;
      if (typeof e2ee === 'boolean' && jsep) jsep.e2ee = e2ee;

      /* [multistream] */
      if (descriptions && Array.isArray(descriptions)) body.descriptions = descriptions;

      const response = await this.message(body, jsep).catch(e => {
        /* Cleanup the WebRTC status in Janus in case of errors when publishing */
        /*
         *
         * JANUS_VIDEOROOM_ERROR_NO_SUCH_FEED       428
         * JANUS_VIDEOROOM_ERROR_MISSING_ELEMENT    429
         * JANUS_VIDEOROOM_ERROR_INVALID_ELEMENT    430
         * JANUS_VIDEOROOM_ERROR_INVALID_SDP_TYPE   431
         * JANUS_VIDEOROOM_ERROR_PUBLISHERS_FULL    432
         * JANUS_VIDEOROOM_ERROR_UNAUTHORIZED       433
         * JANUS_VIDEOROOM_ERROR_ALREADY_PUBLISHED  434
         * JANUS_VIDEOROOM_ERROR_NOT_PUBLISHED      435
         * JANUS_VIDEOROOM_ERROR_ID_EXISTS          436
         * JANUS_VIDEOROOM_ERROR_INVALID_SDP        437
         *
         */
        if (jsep && e._code && e._code >= 429 && e._code <= 437 && e._code != 434)
          this.hangup().catch(() => { });
        throw e;
      });

      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.PUB_JOINED) {
        if (body.display) evtdata.display = body.display;
        return evtdata;
      }
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Configure a publisher or subscriber handle.<br>
     * Room is detected from the context since a handle must have joined before.<br>
     * Can also be used by publishers to publish a feed.<br>
     *
     * Use this API also to trigger ICE restarts. Publishers can omit the
     * restart/update flags, while subscribers need to use them to force
     * the operation.
     *
     * @param {object} params
     * @param {boolean} [params.audio] - True to request audio relaying
     * @param {boolean} [params.video] - True to request video relaying
     * @param {boolean} [params.data] - True to request datachannel relaying
     * @param {string} [params.display] - The display name to use (publishers only)
     * @param {number} [params.bitrate] - Bitrate cap (publishers only)
     * @param {boolean} [params.record] - True to record the feed (publishers only)
     * @param {string} [params.filename] - If recording, the base path/file to use for the recording (publishers only)
     * @param {boolean} [params.restart] - Set to force a ICE restart
     * @param {boolean} [params.update] - Set to force a renegotiation
     * @param {object[]} [params.streams] - [multistream] The streams object, each stream includes mid, keyframe, send, min_delay, max_delay
     * @param {object[]} [params.descriptions] - [multistream] The descriptions object, can define a description for the tracks separately e.g. track mid:0 'Video Camera', track mid:1 'Screen'
     * @param {number} [params.sc_substream_layer] - Substream layer to receive (0-2), in case simulcasting is enabled (subscribers only)
     * @param {number} [params.sc_substream_fallback_ms] - How much time in ms without receiving packets will make janus drop to the substream below (subscribers only)
     * @param {number} [params.sc_temporal_layers] - Temporal layers to receive (0-2), in case VP8 simulcasting is enabled (subscribers only)
     * @param {boolean} [params.e2ee] - True to notify end-to-end encryption for this connection
     * @param {RTCSessionDescription} [params.jsep] - The JSEP offer (publishers only)
     * @param {boolean} [params.keyframe] - True to request a keyframe (publishers only)
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_CONFIGURED>}
     */
    async configure({ audio, video, data, bitrate, record, filename, display, restart, update, streams, descriptions, sc_substream_layer, sc_substream_fallback_ms, sc_temporal_layers, e2ee, jsep, keyframe }) {
      const body = {
        request: REQUEST_CONFIGURE,
      };

      /* [multistream] */
      if (streams && Array.isArray(streams)) {
        body.streams = streams;
      }
      else {
        if (typeof audio === 'boolean') body.audio = audio;
        if (typeof video === 'boolean') body.video = video;
        if (typeof data === 'boolean') body.data = data;
        if (typeof sc_substream_layer === 'number') body.substream = sc_substream_layer;
        if (typeof sc_substream_fallback_ms === 'number') body.fallback = 1000 * sc_substream_fallback_ms;
        if (typeof sc_temporal_layers === 'number') body.temporal = sc_temporal_layers;
      }

      if (typeof bitrate === 'number') body.bitrate = bitrate;
      if (typeof record === 'boolean') body.record = record;
      if (typeof filename === 'string') body.filename = filename;
      if (typeof display === 'string') body.display = display;
      if (typeof restart === 'boolean') body.restart = restart;
      if (typeof update === 'boolean') body.update = update;
      if (typeof e2ee === 'boolean' && jsep) jsep.e2ee = e2ee;
      if (typeof keyframe === 'boolean') body.keyframe = keyframe;

      /* [multistream] */
      if (descriptions && Array.isArray(descriptions)) body.descriptions = descriptions;

      const response = await this.message(body, jsep).catch(e => {
        /* Cleanup the WebRTC status in Janus in case of errors when publishing */
        /*
         *
         * JANUS_VIDEOROOM_ERROR_NO_SUCH_FEED       428
         * JANUS_VIDEOROOM_ERROR_MISSING_ELEMENT    429
         * JANUS_VIDEOROOM_ERROR_INVALID_ELEMENT    430
         * JANUS_VIDEOROOM_ERROR_INVALID_SDP_TYPE   431
         * JANUS_VIDEOROOM_ERROR_PUBLISHERS_FULL    432
         * JANUS_VIDEOROOM_ERROR_UNAUTHORIZED       433
         * JANUS_VIDEOROOM_ERROR_ALREADY_PUBLISHED  434
         * JANUS_VIDEOROOM_ERROR_NOT_PUBLISHED      435
         * JANUS_VIDEOROOM_ERROR_ID_EXISTS          436
         * JANUS_VIDEOROOM_ERROR_INVALID_SDP        437
         *
         */
        if (jsep && e._code && e._code >= 429 && e._code <= 437 && e._code != 434)
          this.hangup().catch(() => { });
        throw e;
      });

      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.CONFIGURED && evtdata.configured === 'ok') {
        if (body.display) evtdata.display = body.display;
        if (typeof body.request === 'boolean') evtdata.restart = body.restart;
        if (typeof body.update === 'boolean') evtdata.update = body.update;
        return evtdata;
      }
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Publish a feed in the room.
     * Room is detected from the context since a handle must have joined before.
     *
     * @param {object} params
     * @param {boolean} [params.audio] - True to request audio relaying
     * @param {boolean} [params.video] - True to request video relaying
     * @param {boolean} [params.data] - True to request datachannel relaying
     * @param {string} [params.display] - The display name to use
     * @param {number} [params.bitrate] - Bitrate cap
     * @param {boolean} [params.record] - True to record the feed
     * @param {string} [params.filename] - If recording, the base path/file to use for the recording
     * @param {object[]} [params.descriptions] - [multistream] The descriptions object, for each stream you can define description
     * @param {boolean} [params.e2ee] - True to notify end-to-end encryption for this connection
     * @param {RTCSessionDescription} params.jsep - The JSEP offer
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_CONFIGURED>}
     */
    async publish({ audio, video, data, bitrate, record, filename, display, descriptions, e2ee, jsep }) {
      if (typeof jsep === 'object' && jsep && jsep.type !== 'offer') {
        const error = new Error('jsep must be an offer');
        return Promise.reject(error);
      }
      const body = {
        request: REQUEST_PUBLISH,
      };

      if (typeof audio === 'boolean') body.audio = audio;
      if (typeof video === 'boolean') body.video = video;
      if (typeof data === 'boolean') body.data = data;

      if (typeof bitrate === 'number') body.bitrate = bitrate;
      if (typeof record === 'boolean') body.record = record;
      if (typeof filename === 'string') body.filename = filename;
      if (typeof display === 'string') body.display = display;
      if (typeof e2ee === 'boolean' && jsep) jsep.e2ee = e2ee;

      /* [multistream] */
      if (descriptions && Array.isArray(descriptions)) {
        body.descriptions = descriptions;
      }

      const response = await this.message(body, jsep).catch(e => {
        /* Cleanup the WebRTC status in Janus in case of errors when publishing */
        /*
         *
         * JANUS_VIDEOROOM_ERROR_NO_SUCH_FEED       428
         * JANUS_VIDEOROOM_ERROR_MISSING_ELEMENT    429
         * JANUS_VIDEOROOM_ERROR_INVALID_ELEMENT    430
         * JANUS_VIDEOROOM_ERROR_INVALID_SDP_TYPE   431
         * JANUS_VIDEOROOM_ERROR_PUBLISHERS_FULL    432
         * JANUS_VIDEOROOM_ERROR_UNAUTHORIZED       433
         * JANUS_VIDEOROOM_ERROR_ALREADY_PUBLISHED  434
         * JANUS_VIDEOROOM_ERROR_NOT_PUBLISHED      435
         * JANUS_VIDEOROOM_ERROR_ID_EXISTS          436
         * JANUS_VIDEOROOM_ERROR_INVALID_SDP        437
         *
         */
        if (jsep && e._code && e._code >= 429 && e._code <= 437 && e._code != 434)
          this.hangup().catch(() => { });
        throw e;
      });

      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.CONFIGURED && evtdata.configured === 'ok') {
        if (body.display) evtdata.display = body.display;
        return evtdata;
      }
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Unpublish a feed in the room.
     *
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_UNPUBLISHED>}
     */
    async unpublish() {
      const body = {
        request: REQUEST_UNPUBLISH,
      };

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.UNPUBLISHED)
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Join a room as subscriber.
     *
     * @param {object} params
     * @param {number|string} params.room - The room to join
     * @param {number|string} [params.feed=0] - The feed the user wants to subscribe to
     * @param {boolean} [params.audio] - Whether or not audio should be relayed
     * @param {boolean} [params.video] - Whether or not video should be relayed
     * @param {boolean} [params.data] - Whether or not data should be relayed
     * @param {boolean} [params.offer_audio] - Whether or not audio should be negotiated
     * @param {boolean} [params.offer_video] - Whether or not video should be negotiated
     * @param {boolean} [params.offer_data] - Whether or not data should be negotiated
     * @param {number} [params.private_id] - The private id to correlate with publisher
     * @param {number} [params.sc_substream_layer] - Substream layer to receive (0-2), in case simulcasting is enabled
     * @param {number} [params.sc_substream_fallback_ms] - How much time in ms without receiving packets will make janus drop to the substream below
     * @param {number} [params.sc_temporal_layers] - Temporal layers to receive (0-2), in case VP8 simulcasting is enabled
     * @param {object[]} [params.streams] - [multistream] The streams object, each stream includes feed, mid, send, ...
     * @param {boolean} [params.autoupdate] - [multistream] Whether a new SDP offer is sent automatically when a subscribed publisher leaves
     * @param {boolean} [params.use_msid] - [multistream] Whether subscriptions should include an msid that references the publisher
     * @param {string} [params.token] - The optional token needed
     * @param {string} [params.pin] - The optional password required to join the room
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_SUB_JOINED>}
     */
    async joinSubscriber({ room, feed, audio, video, data, offer_audio, offer_video, offer_data, private_id, sc_substream_layer, sc_substream_fallback_ms, sc_temporal_layers, streams, autoupdate, use_msid, token, pin }) {
      const body = {
        request: REQUEST_JOIN,
        ptype: PTYPE_LISTENER,
        room,
      };

      /* [multistream] */
      if (streams && Array.isArray(streams)) {
        body.streams = streams;
      }
      else {
        body.feed = feed;
        if (typeof audio === 'boolean') body.audio = audio;
        if (typeof video === 'boolean') body.video = video;
        if (typeof data === 'boolean') body.data = data;
        if (typeof offer_audio === 'boolean') body.offer_audio = offer_audio;
        if (typeof offer_video === 'boolean') body.offer_video = offer_video;
        if (typeof offer_data === 'boolean') body.offer_data = offer_data;
        if (typeof sc_substream_layer === 'number') body.substream = sc_substream_layer;
        if (typeof sc_substream_fallback_ms === 'number') body.fallback = 1000 * sc_substream_fallback_ms;
        if (typeof sc_temporal_layers === 'number') body.temporal = sc_temporal_layers;
      }
      if (typeof private_id === 'number') body.private_id = private_id;
      if (typeof token === 'string') body.token = token;
      if (typeof pin === 'string') body.pin = pin;

      /* [multistream] */
      if (typeof autoupdate === 'boolean') body.autoupdate = autoupdate;
      if (typeof use_msid === 'boolean') body.use_msid = use_msid;

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.SUB_JOINED)
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Alias for "joinSubscriber".
     *
     * @see module:videoroom-plugin~VideoRoomHandle#joinSubscriber
     */
    async joinListener(params) {
      return this.joinSubscriber(params);
    }

    /**
     * Start a subscriber stream.
     *
     * @param {object} params
     * @param {RTCSessionDescription} params.jsep - The JSEP answer
     * @param {boolean} [e2ee] - True to hint an end-to-end encrypted negotiation
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_STARTED>}
     */
    async start({ jsep, e2ee }) {
      const body = {
        request: REQUEST_START,
      };
      if (jsep)
        jsep.e2ee = (typeof e2ee === 'boolean') ? e2ee : jsep.e2ee;

      const response = await this.message(body, jsep);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.STARTED && evtdata.started === 'ok')
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Pause a subscriber feed.
     *
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_PAUSED>}
     */
    async pause() {
      const body = {
        request: REQUEST_PAUSE,
      };

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.PAUSED && evtdata.paused === 'ok')
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Switch to another feed.
     *
     * @param {object} params
     * @param {number|string} [params.to_feed] - The feed id of the new publisher to switch to
     * @param {boolean} [params.audio] - True to subscribe to the audio feed
     * @param {boolean} [params.video] - True to subscribe to the video feed
     * @param {boolean} [params.data] - True to subscribe to the datachannels of the feed
     * @param {object[]} [params.streams] - [multistream] streams array containing feed, mid, sub_mid ...
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_SWITCHED>}
     */
    async switch({ to_feed, audio, video, data, streams }) {
      const body = {
        request: REQUEST_SWITCH,
      };

      /* [multistream] */
      if (streams && Array.isArray(streams)) {
        body.streams = streams;
      }
      else {
        body.feed = to_feed;
        if (typeof audio === 'boolean') body.audio = audio;
        if (typeof video === 'boolean') body.video = video;
        if (typeof data === 'boolean') body.data = data;
      }

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.SWITCHED && evtdata.switched === 'ok') {
        return evtdata;
      }
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Leave a room.
     * Can be used by both publishers and subscribers.
     *
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_LEAVING>}
     */
    async leave() {
      const body = {
        request: REQUEST_LEAVE,
      };

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.LEAVING)
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * [multistream] Update a subscription.
     *
     * @param {object[]} subscribe - The array of streams to subscribe
     * @param {object[]} unsubscribe - The array of streams to unsubscribe
     *
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_UPDATED>}
     */
    async update({ subscribe, unsubscribe }) {
      const body = {
        request: REQUEST_UPDATE,
      };
      if (subscribe && Array.isArray(subscribe)) body.subscribe = subscribe;
      if (unsubscribe && Array.isArray(unsubscribe)) body.unsubscribe = unsubscribe;

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.UPDATED) {
        return evtdata;
      }
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /*----------------*/
    /* Management API */
    /*----------------*/

    /* These are the APIs needed to manage videoroom resources (rooms, forwarders ...) */

    /**
     * List the participants inside a room.
     *
     * @param {object} params
     * @param {number|string} params.room - The room where the list is being requested
     * @param {string} params.secret - The optional secret for the operation
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_PARTICIPANTS_LIST>}
     */
    async listParticipants({ room, secret }) {
      const body = {
        request: REQUEST_LIST_PARTICIPANTS,
        room,
      };
      if (typeof secret === 'string') body.secret = secret;

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.PARTICIPANTS_LIST)
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Enable or disable recording for all participants in a room while the conference is in progress.
     *
     * @param {object} params
     * @param {number|string} params.room - The room where the change of recording state is being requested
     * @param {string} params.secret - The optional secret for the operation
     * @param {boolean} params.record - True starts recording for all participants in an already running conference, false stops the recording
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_RECORDING_ENABLED_STATE>}
     */
    async enable_recording({ room, secret, record }) {
      const body = {
        request: REQUEST_ENABLE_RECORDING,
        room,
        record
      };
      if (typeof secret === 'string') body.secret = secret;

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.RECORDING_ENABLED_STATE) {
        evtdata.room = body.room;
        return evtdata;
      }
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Kick a publisher out from a room.
     *
     * @param {object} params
     * @param {number|string} params.room - The room where the kick is being requested
     * @param {number|string} params.feed - The identifier of the feed to kick out
     * @param {string} params.secret - The optional secret for the operation
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_KICKED>}
     */
    async kick({ room, feed, secret }) {
      const body = {
        request: REQUEST_KICK,
        room,
        id: feed,
      };
      if (typeof secret === 'string') body.secret = secret;

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.SUCCESS) {
        evtdata.room = body.room;
        evtdata.feed = body.id;
        return evtdata;
      }
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Check if a room exists.
     *
     * @param {object} params
     * @param {number|string} params.room - The room to check
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_EXISTS>}
     */
    async exists({ room }) {
      const body = {
        request: REQUEST_EXISTS,
        room,
      };

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.EXISTS)
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * List all the available rooms.
     *
     * @param {object} params
     * @param {string} [params.admin_key] - The admin key needed for invoking the API
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_LIST>}
     */
    async list({ admin_key } = {}) {
      const body = {
        request: REQUEST_LIST_ROOMS,
      };
      if (typeof admin_key === 'string') body.admin_key = admin_key;

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.ROOMS_LIST)
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Create a new room.
     *
     * @param {object} params
     * @param {number|string} [params.room] - The room identifier, if missing picked by janus
     * @param {string} [params.description] - A textual description of the room
     * @param {number} [params.max_publishers] - The max number of publishers allowed
     * @param {boolean} [params.permanent] - True to make Janus persist the room on th config file
     * @param {boolean} [params.is_private] - Make the room private (hidden from listing)
     * @param {string} [params.secret] - The secret that will be used to modify the room
     * @param {string} [params.pin] - The pin needed to access the room
     * @param {string} [params.admin_key] - The admin key needed for invoking the API
     * @param {number} [params.bitrate] - The bitrate cap that will be used for publishers
     * @param {boolean} [params.bitrate_cap] - Make the bitrate cap an insormountable limit
     * @param {number} [params.fir_freq] - The PLI interval in seconds
     * @param {string} [params.audiocodec] - Comma separated list of allowed audio codecs
     * @param {string} [params.videocodec] - Comma separated list of allowed video codecs
     * @param {boolean} [params.talking_events] - True to enable talking events
     * @param {number} [params.talking_level_threshold] - Audio level threshold for talking events in the range [0, 127]
     * @param {number} [params.talking_packets_threshold] - Audio packets threshold for talking events
     * @param {boolean} [params.require_pvtid] - Whether subscriptions are required to provide a valid private_id
     * @param {boolean} [params.require_e2ee] - Whether all participants are required to publish and subscribe using e2e encryption
     * @param {boolean} [params.record] - Wheter to enable recording of any publisher
     * @param {string} [params.rec_dir] - Folder where recordings should be stored
     * @param {boolean} [params.videoorient] - Whether the video-orientation RTP extension must be negotiated
     * @param {string} [params.h264_profile] - H264 specific profile to prefer
     * @param {string} [params.vp9_profile] - VP9 specific profile to prefer
     * @param {number} [params.threads] - Number of threads to assist with the relaying of publishers in the room
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_CREATED>}
     */
    async create({ room, description, max_publishers, permanent, is_private, secret, pin, admin_key, bitrate,
      bitrate_cap, fir_freq, audiocodec, videocodec, talking_events, talking_level_threshold, talking_packets_threshold,
      require_pvtid, require_e2ee, record, rec_dir, videoorient, h264_profile, vp9_profile, threads }) {
      const body = {
        request: REQUEST_CREATE,
      };
      if (typeof room === 'string' || typeof room === 'number') body.room = room;
      if (typeof description === 'string') body.description = description;
      if (typeof max_publishers === 'number') body.publishers = max_publishers;
      if (typeof permanent === 'boolean') body.permanent = permanent;
      if (typeof is_private === 'boolean') body.is_private = is_private;
      if (typeof secret === 'string') body.secret = secret;
      if (typeof pin === 'string') body.pin = pin;
      if (typeof admin_key === 'string') body.admin_key = admin_key;
      if (typeof bitrate === 'number') body.bitrate = bitrate;
      if (typeof bitrate_cap === 'boolean') body.bitrate_cap = bitrate_cap;
      if (typeof fir_freq === 'number') body.fir_freq = fir_freq;
      if (typeof audiocodec === 'string') body.audiocodec = audiocodec;
      if (typeof videocodec === 'string') body.videocodec = videocodec;
      if (typeof talking_events === 'boolean') body.audiolevel_event = talking_events;
      if (typeof talking_level_threshold === 'number' && talking_level_threshold >= 0 && talking_level_threshold <= 127) body.audio_level_average = talking_level_threshold;
      if (typeof talking_packets_threshold === 'number' && talking_packets_threshold > 0) body.audio_active_packets = talking_packets_threshold;
      if (typeof require_pvtid === 'boolean') body.require_pvtid = require_pvtid;
      if (typeof require_e2ee === 'boolean') body.require_e2ee = require_e2ee;
      if (typeof record === 'boolean') body.record = record;
      if (typeof rec_dir === 'string') body.rec_dir = rec_dir;
      if (typeof videoorient === 'boolean') body.videoorient_ext = videoorient;
      if (typeof h264_profile === 'string') body.h264_profile = h264_profile;
      if (typeof vp9_profile === 'string') body.vp9_profile = vp9_profile;
      if (typeof threads === 'number') body.threads = threads;

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.CREATED)
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Destroy a room.
     *
     * @param {object} params
     * @param {number|string} params.room - The room to destroy
     * @param {boolean} [params.permanent] - True to remove the room from the Janus config file
     * @param {string} [params.secret] - The secret needed to manage the room
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_DESTROYED>}
     */
    async destroy({ room, permanent, secret }) {
      const body = {
        request: REQUEST_DESTROY,
        room,
      };
      if (typeof permanent === 'boolean') body.permanent = permanent;
      if (typeof secret === 'string') body.secret = secret;

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.DESTROYED)
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Edit the ACL tokens for a room.
     *
     * @param {object} params
     * @param {number|string} params.room - The room where to change the acl
     * @param {"enable"|"disable"|"add"|"remove"} params.action - The action to execute on the acl
     * @param {string[]} params.list - The list of tokens to execute the action onto
     * @param {string} [params.secret] - The secret needed to manage the room
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_ALLOWED>}
     */
    async allow({ room, action, list, secret }) {
      const body = {
        request: REQUEST_ALLOW,
        room,
        action,
      };
      if (list && list.length > 0) body.allowed = list;
      if (typeof secret === 'string') body.secret = secret;

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.ALLOWED)
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Start a RTP forwarding in a room.
     *
     * @param {object} params
     * @param {number|string} params.room - The room where to start a forwarder
     * @param {number|string} params.feed - The feed identifier to forward (must be published)
     * @param {string} params.host - The target host for the forwarder
     * @param {object[]} [params.streams] - [multistream] The streams array containing mid, port, rtcp_port, port_2 ...
     * @param {number} [params.audio_port] - The target audio RTP port, if audio is to be forwarded
     * @param {number} [params.audio_rtcp_port] - The target audio RTCP port, if audio is to be forwarded
     * @param {number} [params.audio_ssrc] - The SSRC that will be used for audio RTP
     * @param {number} [params.video_port] - The target video RTP port, if video is to be forwarded
     * @param {number} [params.video_rtcp_port] - The target video RTCP port, if video is to be forwarded
     * @param {number} [params.video_ssrc] - The SSRC that will be used for video RTP
     * @param {number} [params.video_port_2] - The target video RTP port for simulcast substream
     * @param {number} [params.video_ssrc_2] - The SSRC that will be used for video RTP substream
     * @param {number} [params.video_port_3] - The target video RTP port for simulcast substream
     * @param {number} [params.video_ssrc_3] - The SSRC that will be used for video RTP substream
     * @param {number} [params.data_port] - The target datachannels port, if datachannels are to be forwarded
     * @param {string} [params.secret] - The secret needed for managing the room
     * @param {string} [params.admin_key] - The admin key needed for invoking the API
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_RTP_FWD_STARTED>}
     */
    async startForward({ room, feed, host, streams, audio_port, audio_rtcp_port, audio_ssrc, video_port, video_rtcp_port, video_ssrc, video_port_2, video_ssrc_2, video_port_3, video_ssrc_3, data_port, secret, admin_key }) {
      const body = {
        request: REQUEST_RTP_FWD_START,
        room,
        publisher_id: feed,
      };
      if (typeof host === 'string') body.host = host;
      /* [multistream] */
      if (streams && Array.isArray(streams)) {
        body.streams = streams;
      }
      else {
        if (typeof audio_port === 'number') body.audio_port = audio_port;
        if (typeof audio_rtcp_port === 'number') body.audio_rtcp_port = audio_rtcp_port;
        if (typeof audio_ssrc === 'number') body.audio_ssrc = audio_ssrc;
        if (typeof video_port === 'number') body.video_port = video_port;
        if (typeof video_rtcp_port === 'number') body.video_rtcp_port = video_rtcp_port;
        if (typeof video_ssrc === 'number') body.video_ssrc = video_ssrc;
        if (typeof video_port_2 === 'number') body.video_port_2 = video_port_2;
        if (typeof video_ssrc_2 === 'number') body.video_ssrc_2 = video_ssrc_2;
        if (typeof video_port_3 === 'number') body.video_port_3 = video_port_3;
        if (typeof video_ssrc_3 === 'number') body.video_ssrc_3 = video_ssrc_3;
        if (typeof data_port === 'number') body.data_port = data_port;
      }

      if (typeof secret === 'string') body.secret = secret;
      if (typeof admin_key === 'string') body.admin_key = admin_key;

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.RTP_FWD_STARTED)
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * Stop a RTP forwarder in a room.
     *
     * @param {object} params
     * @param {number|string} params.room - The room where to stop a forwarder
     * @param {number|string} params.feed - The feed identifier for the forwarder to stop (must be published)
     * @param {number|string} params.stream - The forwarder identifier as returned by the start forward API
     * @param {string} [params.secret] - The secret needed for managing the room
     * @param {string} [params.admin_key] - The admin key needed for invoking the API
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_RTP_FWD_STOPPED>}
     */
    async stopForward({ room, feed, stream, secret, admin_key }) {
      const body = {
        request: REQUEST_RTP_FWD_STOP,
        room,
        publisher_id: feed,
        stream_id: stream,
      };
      if (typeof secret === 'string') body.secret = secret;
      if (typeof admin_key === 'string') body.admin_key = admin_key;

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.RTP_FWD_STOPPED)
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

    /**
     * List the active forwarders in a room.
     *
     * @param {object} params
     * @param {number|string} params.room - The room where to list the forwarders
     * @param {string} [params.secret] - The secret needed for managing the room
     * @returns {Promise<module:videoroom-plugin~VIDEOROOM_EVENT_RTP_FWD_LIST>}
     */
    async listForward({ room, secret }) {
      const body = {
        request: REQUEST_RTP_FWD_LIST,
        room,
      };
      if (typeof secret === 'string') body.secret = secret;

      const response = await this.message(body);
      const { event, data: evtdata } = response._janode || {};
      if (event === PLUGIN_EVENT.RTP_FWD_LIST)
        return evtdata;
      const error = new Error(`unexpected response to ${body.request} request`);
      throw (error);
    }

  }

  /**
   * The payload of the plugin message (cfr. Janus docs).
   * {@link https://janus.conf.meetecho.com/docs/videoroom.html}
   *
   * @private
   * @typedef {object} VideoRoomData
   */

  /**
   * The response event when a publisher has joined.
   *
   * @typedef {object} VIDEOROOM_EVENT_PUB_JOINED
   * @property {number|string} room - The involved room
   * @property {number|string} feed - The feed identifier
   * @property {string} [display] - The dsplay name, if available
   * @property {string} description - A description of the room, if available
   * @property {number} private_id - The private id that can be used when subscribing
   * @property {object[]} publishers - The list of active publishers
   * @property {number|string} publishers[].feed - The feed of an active publisher
   * @property {string} [publishers[].display] - The display name of an active publisher
   * @property {boolean} [publishers[].talking] - Whether the publisher is talking or not
   * @property {string} [publishers[].audiocodec] - The audio codec used by active publisher
   * @property {string} [publishers[].videocodec] - The video codec used by active publisher
   * @property {boolean} publishers[].simulcast - True if the publisher uses simulcast (VP8 and H.264 only)
   * @property {object[]} [publishers[].streams] - [multistream] Streams description as returned by Janus
   * @property {boolean} [e2ee] - True if the stream is end-to-end encrypted
   * @property {RTCSessionDescription} [jsep] - The JSEP answer
   */

  /**
   * The response event when a subscriber has joined.
   *
   * @typedef {object} VIDEOROOM_EVENT_SUB_JOINED
   * @property {number|string} room - The involved room
   * @property {number|string} [feed] - The published feed identifier
   * @property {string} [display] - The published feed display name
   * @property {object[]} [streams] - [multistream] Streams description as returned by Janus
   */

  /**
   * The response event to a participant list request.
   *
   * @typedef {object} VIDEOROOM_EVENT_PARTICIPANTS_LIST
   * @property {number|string} room - The involved room
   * @property {number|string} feed - The current published feed
   * @property {object[]} participants - The list of current participants
   * @property {number|string} participants[].feed - Feed identifier of the participant
   * @property {string} [participants[].display] - The participant's display name, if available
   * @property {boolean} participants[].publisher - Whether the user is an active publisher in the room
   * @property {boolean} [participants[].talking] - True if participant is talking
   */

  /**
   * The response event for room create request.
   *
   * @typedef {object} VIDEOROOM_EVENT_CREATED
   * @property {number|string} room - The created room
   * @property {boolean} permanent - True if the room has been persisted on the Janus configuratin file
   */

  /**
   * The response event for room destroy request.
   *
   * @typedef {object} VIDEOROOM_EVENT_DESTROYED
   * @property {number|string} room - The destroyed room
   * @property {boolean} permanent - True if the room has been removed from the Janus configuratin file
   */

  /**
   * The response event for room exists request.
   *
   * @typedef {object} VIDEOROOM_EVENT_EXISTS
   * @property {number|string} room - The queried room
   */

  /**
   * Descriptrion of an active RTP forwarder.
   *
   * @typedef {object} RtpForwarder
   * @property {string} host - The target host
   * @property {number} [audio_port] - The RTP audio target port
   * @property {number} [audio_rtcp_port] - The RTCP audio target port
   * @property {number} [audio_stream] - The audio forwarder identifier
   * @property {number} [video_port] - The RTP video target port
   * @property {number} [video_rtcp_port] - The RTCP video target port
   * @property {number} [video_stream] - The video forwarder identifier
   * @property {number} [video_port_2] - The RTP video target port (simulcast)
   * @property {number} [video_stream_2] - The video forwarder identifier (simulcast)
   * @property {number} [video_port_3] - The RTP video target port (simulcast)
   * @property {number} [video_stream_3] - The video forwarder identifier (simulcast)
   * @property {number} [data_port] - The datachannels target port
   * @property {number} [data_stream] - The datachannels forwarder identifier
   * @property {number} [ssrc] - SSRC this forwarder is using
   * @property {number} [pt] - payload type this forwarder is using
   * @property {number} [sc_substream_layer] - video simulcast substream this video forwarder is relaying
   * @property {boolean} [srtp] - whether the RTP stream is encrypted
   */

  /**
   * The response event for RTP forward start request.
   *
   * @typedef {object} VIDEOROOM_EVENT_RTP_FWD_STARTED
   * @property {number|string} room - The involved room
   * @property {RtpForwarder} [forwarder] - The forwarder object
   * @property {RtpForwarder[]} [forwarders] - [multistream] The array of forwarders
   */

  /**
   * The response event for RTP forward stop request.
   *
   * @typedef {object} VIDEOROOM_EVENT_RTP_FWD_STOPPED
   * @property {number|string} room - The involved room
   * @property {number|string} feed - The feed identifier being forwarded
   * @property {number} stream - The forwarder identifier
   */

  /**
   * The response event for RTP forwarders list request.
   *
   * @typedef {object} VIDEOROOM_EVENT_RTP_FWD_LIST
   * @property {number|string} room - The involved room
   * @property {object[]} forwarders - The list of forwarders
   * @property {number|string} forwarders[].feed - The feed that is being forwarded
   * @property {RtpForwarder[]} forwarders[].forwarders -The list of the forwarders for this feed
   */

  /**
   * The response event for videoroom list request.
   *
   * @typedef {object} VIDEOROOM_EVENT_LIST
   * @property {object[]} list - The list of the room as returned by Janus
   */

  /**
   * The response event for ACL tokens edit (allowed) request.
   *
   * @typedef {object} VIDEOROOM_EVENT_ALLOWED
   * @property {string[]} list - The updated, complete, list of allowed tokens
   */

  /**
   * The response event for publisher/subscriber configure request.
   *
   * @typedef {object} VIDEOROOM_EVENT_CONFIGURED
   * @property {number|string} room - The involved room
   * @property {number|string} feed - The feed identifier
   * @property {string} [display] - The display name, if available
   * @property {boolean} [restart] - True if the request had it true
   * @property {boolean} [update] - True if the request had it true
   * @property {string} configured - A string with the value returned by Janus
   * @property {object[]} [streams] - [multistream] Streams description as returned by Janus
   * @property {boolean} [e2ee] - True if the stream is end-to-end encrypted
   * @property {RTCSessionDescription} [jsep] - The JSEP answer
   */

  /**
   * The response event for subscriber start request.
   *
   * @typedef {object} VIDEOROOM_EVENT_STARTED
   * @property {number|string} room - The involved room
   * @property {number|string} [feed] - The feed that started
   * @property {boolean} [e2ee] - True if started stream is e2ee
   * @property {string} started - A string with the value returned by Janus
   */

  /**
   * The response event for subscriber pause request.
   *
   * @typedef {object} VIDEOROOM_EVENT_PAUSED
   * @property {number|string} room - The involved room
   * @property {number|string} feed - The feed that has been paused
   * @property {string} paused - A string with the value returned by Janus
   */

  /**
   * The response event for subscriber switch request.
   *
   * @typedef {object} VIDEOROOM_EVENT_SWITCHED
   * @property {number|string} room - The involved room
   * @property {number|string} [from_feed] - The feed that has been switched from
   * @property {number|string} [to_feed] - The feed that has been switched to
   * @property {string} switched - A string with the value returned by Janus
   * @property {string} [display] - The display name of the new feed
   * @property {object[]} [streams] - [multistream] The updated streams array
   */

  /**
   * The response event for publisher unpublish request.
   *
   * @typedef {object} VIDEOROOM_EVENT_UNPUBLISHED
   * @property {number|string} room - The involved room
   * @property {number|string} feed - The feed that unpublished
   */

  /**
   * The response event for publiher/subscriber leave request.
   *
   * @typedef {object} VIDEOROOM_EVENT_LEAVING
   * @property {number|string} room - The involved room
   * @property {number|string} feed - The feed that left
   * @property {string} [reason] - An optional string with the reason of the leaving
   */

  /**
   * The response event for the kick request.
   *
   * @typedef {object} VIDEOROOM_EVENT_KICKED
   * @property {number|string} room - The involved room
   * @property {number|string} feed - The feed that has been kicked
   */

  /**
   * The response event for the recording enabled request.
   *
   * @typedef {object} VIDEOROOM_EVENT_RECORDING_ENABLED_STATE
   * @property {number|string} room - The involved room
   * @property {boolean} recording - Whether or not the room recording is now enabled
   */

  /**
   * [multistream] The response event for update subscriber request.
   *
   * @typedef {object} VIDEOROOM_EVENT_UPDATED
   * @property {number|string} room - The involved room
   * @property {RTCSessionDescription} [jsep] - The updated JSEP offer
   * @property {object[]} streams - List of the updated streams in this subscription
   */

  /**
   * The exported plugin descriptor.
   *
   * @property {string} id - The plugin identifier used when attaching to Janus
   * @property {module:videoroom-plugin~VideoRoomHandle} Handle - The custom class implementing the plugin
   * @property {string} EVENT.VIDEOROOM_PUB_PEER_JOINED {@link module:videoroom-plugin~VIDEOROOM_PUB_PEER_JOINED}
   * @property {string} EVENT.VIDEOROOM_PUB_LIST {@link module:videoroom-plugin~VIDEOROOM_PUB_LIST}
   * @property {string} EVENT.VIDEOROOM_DESTROYED {@link module:videoroom-plugin~VIDEOROOM_DESTROYED}
   * @property {string} EVENT.VIDEOROOM_UNPUBLISHED {@link module:videoroom-plugin~VIDEOROOM_UNPUBLISHED}
   * @property {string} EVENT.VIDEOROOM_LEAVING {@link module:videoroom-plugin~VIDEOROOM_LEAVING}
   * @property {string} EVENT.VIDEOROOM_DISPLAY {@link module:videoroom-plugin~VIDEOROOM_DISPLAY}
   * @property {string} EVENT.VIDEOROOM_KICKED {@link module:videoroom-plugin~VIDEOROOM_KICKED}
   * @property {string} EVENT.VIDEOROOM_RECORDING_ENABLED_STATE {@link module:videoroom-plugin~VIDEOROOM_RECORDING_ENABLED_STATE}
   * @property {string} EVENT.VIDEOROOM_TALKING {@link module:videoroom-plugin~VIDEOROOM_TALKING}
   * @property {string} EVENT.VIDEOROOM_ERROR {@link module:videoroom-plugin~VIDEOROOM_ERROR}
   */
  var VideoroomPlugin = {
    id: PLUGIN_ID,
    Handle: VideoRoomHandle,
    EVENT: {
      /**
       * A peer has joined theh room (notify-joining).
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_PUB_PEER_JOINED
       * @type {object}
       * @property {number|string} room - The involved room
       * @property {number|string} feed - The feed identifier that joined
       * @property {string} display - The display name of the peer
       */
      VIDEOROOM_PUB_PEER_JOINED: PLUGIN_EVENT.PUB_PEER_JOINED,

      /**
       * Active publishers list updated.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_PUB_LIST
       * @type {object}
       * @property {number|string} room - The involved room
       * @property {number|string} feed - The current feed identifier
       * @property {object[]} publishers - List of the new publishers
       * @property {number|string} publishers[].feed - Feed identifier of the new publisher
       * @property {string} publishers[].display - Display name of the new publisher
       * @property {boolean} [publishers[].talking] - Whether the publisher is talking or not
       * @property {string} [publishers[].audiocodec] - The audio codec used by active publisher
       * @property {string} [publishers[].videocodec] - The video codec used by active publisher
       * @property {boolean} publishers[].simulcast - True if the publisher uses simulcast (VP8 and H.264 only)
       * @property {object[]} [publishers[].streams] - [multistream] Streams description as returned by Janus
       */
      VIDEOROOM_PUB_LIST: PLUGIN_EVENT.PUB_LIST,

      /**
       * The videoroom has been destroyed.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_DESTROYED
       * @type {module:videoroom-plugin~VIDEOROOM_EVENT_DESTROYED}
       */
      VIDEOROOM_DESTROYED: PLUGIN_EVENT.DESTROYED,

      /**
       * A feed has been unpublished.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_UNPUBLISHED
       * @type {module:videoroom-plugin~VIDEOROOM_EVENT_UNPUBLISHED}
       */
      VIDEOROOM_UNPUBLISHED: PLUGIN_EVENT.UNPUBLISHED,

      /**
       * A peer has left the room.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_LEAVING
       * @type {module:videoroom-plugin~VIDEOROOM_EVENT_LEAVING}
       */
      VIDEOROOM_LEAVING: PLUGIN_EVENT.LEAVING,

      /**
       * A participant has changed the display name.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_DISPLAY
       * @type {object}
       * @property {number|string} room - The involved room
       * @property {number|string} feed - The feed of the peer that change display name
       * @property {string} display - The new display name of the peer
       */
      VIDEOROOM_DISPLAY: PLUGIN_EVENT.DISPLAY,

      /**
       * A handle received a configured event.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_CONFIGURED
       * @type {module:videoroom-plugin~VIDEOROOM_EVENT_CONFIGURED}
       */
      VIDEOROOM_CONFIGURED: PLUGIN_EVENT.CONFIGURED,

      /**
       * A handle received a slow link notification.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_DISPLAY
       * @type {object}
       * @property {number|string} room - The involved room
       * @property {number|string} feed - The feed of the peer that change display name
       * @property {number} bitrate - The current bitrate cap for the participant
       */
      VIDEOROOM_SLOWLINK: PLUGIN_EVENT.SLOW_LINK,

      /**
       * Notify if the current user is talking.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_TALKING
       * @type {object}
       * @property {number|string} room - The involved room
       * @property {number|string} feed - The feed of the peer this talking notification refers to
       * @property {boolean} talking - True if the participant is talking
       * @property {number} audio_level - The audio level of the participant in the range [0,127]
       */
      VIDEOROOM_TALKING: PLUGIN_EVENT.TALKING,

      /**
       * A feed has been kicked out.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_KICKED
       * @type {module:videoroom-plugin~VIDEOROOM_EVENT_KICKED}
       */
      VIDEOROOM_KICKED: PLUGIN_EVENT.KICKED,

      /**
       * Conference recording has been enabled or disabled.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_RECORDING_ENABLED_STATE
       * @type {module:videoroom-plugin~VIDEOROOM_EVENT_RECORDING_ENABLED_STATE}
       */
      VIDEOROOM_RECORDING_ENABLED_STATE: PLUGIN_EVENT.RECORDING_ENABLED_STATE,

      /**
       * A switch to a different simulcast substream has been completed.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_SC_SUBSTREAM_LAYER
       * @type {object}
       * @property {number|string} room - The involved room
       * @property {number|string} feed - The feed of the peer this notification refers to
       * @property {number} sc_substream_layer - The new simuclast substream layer relayed
       */
      VIDEOROOM_SC_SUBSTREAM_LAYER: PLUGIN_EVENT.SC_SUBSTREAM_LAYER,

      /**
       * A switch to a different number of simulcast temporal layers has been completed.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_SC_TEMPORAL_LAYERS
       * @type {object}
       * @property {number|string} room - The involved room
       * @property {number|string} feed - The feed of the peer this switch notification refers to
       * @property {number} sc_temporal_layers - The new number of simuclast teporal layers relayed
       */
      VIDEOROOM_SC_TEMPORAL_LAYERS: PLUGIN_EVENT.SC_TEMPORAL_LAYERS,

      /**
       * A multistream subscription has been updated.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_UPDATED
       * @type {module:videoroom-plugin~VIDEOROOM_EVENT_UPDATED}
       */
      VIDEOROOM_UPDATED: PLUGIN_EVENT.UPDATED,

      /**
       * A generic videoroom error.
       *
       * @event module:videoroom-plugin~VideoRoomHandle#event:VIDEOROOM_ERROR
       * @type {Error}
       */
      VIDEOROOM_ERROR: PLUGIN_EVENT.ERROR,
    },
  };

  var app = {
      Janode,
      VideoroomPlugin,
  };

  return app;

})();

export const Janode = Janus.Janode;
export const VideoroomPlugin = Janus.VideoroomPlugin;
