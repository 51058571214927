// Создаем подписчики на события от кнопок/меню и из janus
import { EventBus } from 'quasar';

const buttonEventBus = new EventBus();
const callEventBus = new EventBus();
const janusEventBus = new EventBus();

export {
    buttonEventBus,
    callEventBus,
    janusEventBus,
};

// События на странице звонка
export enum CallBusEvents {
    OnChangeBackgroundOption = 'onChangeBackgroundOption',
}

// События в звонке, который вызываются по нажатию кнопок
export enum ButtonBusEvents {
    Relocate = 'relocate',
    TurnOfScreenSharing = 'turnOfScreenSharing',
    MuteInterlocutorAudio = 'muteInterlocutorAudio',
    ExitRoom = 'exitRoom',
    FinishCall = 'finishCall',
    CallUsers = 'callUsers',
    MuteAllMicrophones = 'muteAllMicrophones',
}
