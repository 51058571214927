import { DropDownItem } from 'src/api/ApiClient';
import Bowser from 'bowser';
import { localize } from 'src/services/LocalizationService';
import { Common } from 'src/helpers/Common';

// Енам с вариантами бэкграунда
export enum BackgroundItem {
    Default,
    BlurEffect,
    NewYear1,
    NewYear2,
    LibraryImage,
    BrightOfficeImage,
    DarkOfficeImage,
    HomeOfficeImage,
    FireplaceImage,
    CityImage,
    SunsetImage,
    NorthernLightsImage,
    PinkFlowersImage,
    YellowFlowersImage,
    CatsImage,
    BlackCatImage,
    TrueFriendImage,
    AbstractionImage,
}

// Получить путь к картинке по енаму
export function getBackground(item: BackgroundItem): string | null {
    switch (item) {
        case BackgroundItem.NewYear1:
            return '/images/call-backgrounds/new-year-1.png';
        case BackgroundItem.NewYear2:
            return '/images/call-backgrounds/new-year-2.png';
        case BackgroundItem.AbstractionImage:
            return '/images/call-backgrounds/abstraction.png';
        case BackgroundItem.TrueFriendImage:
            return '/images/call-backgrounds/true-friend.png';
        case BackgroundItem.CityImage:
            return '/images/call-backgrounds/city.png';
        case BackgroundItem.HomeOfficeImage:
            return '/images/call-backgrounds/home-office.png';
        case BackgroundItem.LibraryImage:
            return '/images/call-backgrounds/library.png';
        case BackgroundItem.BrightOfficeImage:
            return '/images/call-backgrounds/bright-office.png';
        case BackgroundItem.DarkOfficeImage:
            return '/images/call-backgrounds/dark-office.png';
        case BackgroundItem.FireplaceImage:
            return '/images/call-backgrounds/fireplace.png';
        case BackgroundItem.SunsetImage:
            return '/images/call-backgrounds/sunset.png';
        case BackgroundItem.NorthernLightsImage:
            return '/images/call-backgrounds/northern-lights.png';
        case BackgroundItem.PinkFlowersImage:
            return '/images/call-backgrounds/pink-flowers.png';
        case BackgroundItem.YellowFlowersImage:
            return '/images/call-backgrounds/yellow-flowers.png';
        case BackgroundItem.CatsImage:
            return '/images/call-backgrounds/cats.png';
        case BackgroundItem.BlackCatImage:
            return '/images/call-backgrounds/black-cat.png';
        default:
            return null;
    }
}

// Список вариантов бэкграунда для выпадающих списков
// Поле текст пишем как геттер
// Это связано с особенностями локализации
// Чтобы локализация была только при обращении к полю
// А не при выполнении скрипта когда его загрузит браузер
let options: DropDownItem[] = [
    {
        get text() {
            return localize('Стандартный');
        },
        value: BackgroundItem.Default,
    }, {
        get text() {
            return localize('Размытие');
        },
        value: BackgroundItem.BlurEffect,
    }, {
        get text() {
            return localize('Библиотека');
        },
        value: BackgroundItem.LibraryImage,
    }, {
        get text() {
            return localize('Яркий офис');
        },
        value: BackgroundItem.BrightOfficeImage,
    }, {
        get text() {
            return localize('Тёмный офис');
        },
        value: BackgroundItem.DarkOfficeImage,
    }, {
        get text() {
            return localize('Домашний офис');
        },
        value: BackgroundItem.HomeOfficeImage,
    }, {
        get text() {
            return localize('Камин');
        },
        value: BackgroundItem.FireplaceImage,
    }, {
        get text() {
            return localize('Город');
        },
        value: BackgroundItem.CityImage,
    }, {
        get text() {
            return localize('Закат');
        },
        value: BackgroundItem.SunsetImage,
    }, {
        get text() {
            return localize('Северное сияние');
        },
        value: BackgroundItem.NorthernLightsImage,
    }, {
        get text() {
            return localize('Розовые цветы');
        },
        value: BackgroundItem.PinkFlowersImage,
    }, {
        get text() {
            return localize('Жёлтые цветы');
        },
        value: BackgroundItem.YellowFlowersImage,
    }, {
        get text() {
            return localize('Банда котов');
        },
        value: BackgroundItem.CatsImage,
    }, {
        get text() {
            return localize('Чёрный кот');
        },
        value: BackgroundItem.BlackCatImage,
    }, {
        get text() {
            return localize('Верный друг');
        },
        value: BackgroundItem.TrueFriendImage,
    }, {
        get text() {
            return localize('Абстракция');
        },
        value: BackgroundItem.AbstractionImage,
    },
];

// Добавляем новогодний фон
if (Common.isNewYearTime()) {
    const newYearOptions: DropDownItem[] = [{
        get text() {
            return localize('Новый Год 1');
        },
        value: BackgroundItem.NewYear1,
    }, {
        get text() {
            return localize('Новый Год 2');
        },
        value: BackgroundItem.NewYear2,
    }];

    options = options.concat(newYearOptions);
}

// В сафари не работает размытие фона
export default {
    get items() {
        return Bowser.getParser(window.navigator.userAgent).getBrowserName() === 'Safari'
            ? options.filter((x: DropDownItem) => x.value !== BackgroundItem.BlurEffect)
            : options;
    }
};
