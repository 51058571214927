/* eslint comment-density/comment-density: 0 */
// Импорты всех страниц и лейаутов
import { RouteRecordRaw } from 'vue-router';
import * as Layouts from '../layouts';
import * as PagesLogin from '../pages/Login';
import * as PagesExchangeable from '../pages/Exchangeable';
import { localize } from 'src/services/LocalizationService';
import { Common } from 'src/helpers/Common';
import { RoutePageNameEnum } from 'src/api/ApiClient';

//
// Настройка страниц приложение
// в meta указываются кастомные параметры
// title - будет подставляться в title вкладки браузера
// isIndexed - добавляются метатеги для индексации страницы
// isNeedLogin - для доступа к странице пользователь должен быть авторизован
//

const routes: RouteRecordRaw[] = [
    //region Страницы авторизации
    {
        path: '/Account/Login',
        component: Layouts.Login,
        children: [
            {
                path: '',
                name: Common.getRouteName(RoutePageNameEnum.Login),
                component: PagesLogin.Login,
                props: true,
                meta: {
                    getTitle() {
                        return localize('Авторизация');
                    },
                    isIndexed: true,
                },
            },
            {
                path: '/Account/Register/:cohortId?/:email?',
                name: Common.getRouteName(RoutePageNameEnum.Register),
                component: () => {
                    return import('../pages/Login/Register');
                },
                meta: {
                    getTitle() {
                        return localize('Создать аккаунт');
                    },
                    isIndexed: true,
                },
            },
            {
                path: '/Account/ForgotPassword',
                name: Common.getRouteName(RoutePageNameEnum.ForgotPassword),
                component: () => {
                    return import('../pages/Login/ForgotPassword');
                },
                meta: {
                    getTitle() {
                        return localize('Восстановить пароль');
                    },
                },
            },
            {
                path: '/Account/ResetPassword',
                name: Common.getRouteName(RoutePageNameEnum.ResetPassword),
                component: () => {
                    return import('../pages/Login/ResetPassword');
                },
                meta: {
                    getTitle() {
                        return localize('Восстановление пароля');
                    },
                },
            },
            {
                path: '/Account/LoginAs/:id',
                name: Common.getRouteName(RoutePageNameEnum.LoginAs),
                component: () => {
                    return import('../pages/Login/LoginAs.vue');
                },
                meta: {
                    isNeedLogin: true
                }
            },
            {
                path: '/Account/YandexLogin',
                name: Common.getRouteName(RoutePageNameEnum.YandexLogin),
                component: () => {
                    return import('../pages/Login/YandexLogin.vue');
                },
                meta: {
                    getTitle() {
                        return localize('Аутентификация Яндекс');
                    },
                },
            },
            {
                path: '/Account/VkLogin',
                name: Common.getRouteName(RoutePageNameEnum.VkLogin),
                component: () => {
                    return import('../pages/Login/VkLogin.vue');
                },
                meta: {
                    getTitle() {
                        return localize('Аутентификация VK');
                    },
                },
            },
            {
                path: '/Account/MoodleLogin/',
                name: Common.getRouteName(RoutePageNameEnum.MoodleLogin),
                component: () => {
                    return import('../pages/Login/MoodleLogin.vue');
                },
                meta: {
                    getTitle() {
                        return localize('Аутентификация Moodle');
                    },
                },
            },
            {
                path: '/Account/GoogleLogin',
                name: Common.getRouteName(RoutePageNameEnum.GoogleLogin),
                component: () => {
                    return import('../pages/Login/GoogleLogin.vue');
                },
                meta: {
                    getTitle() {
                        return localize('Аутентификация Google');
                    },
                },
            },
            {
                path: '/Account/TsuLogin',
                name: Common.getRouteName(RoutePageNameEnum.TsuLogin),
                component: () => {
                    return import('../pages/Login/TsuLogin.vue');
                },
                meta: {
                    getTitle() {
                        return localize('Аутентификация ТГУ');
                    },
                },
            },
            {
                path: '/Account/Login2035',
                name: Common.getRouteName(RoutePageNameEnum.University2035Login),
                component: () => {
                    return import('../pages/Login/University2035/University2035Login.vue');
                },
                meta: {
                    getTitle() {
                        return localize('Аутентификация Университет 2035');
                    },
                },
            },
            {
                path: '/Account/FutureCodeLogin',
                name: Common.getRouteName(RoutePageNameEnum.FutureCodeLogin),
                component: () => {
                    return import('../pages/Login/University2035/CodeOfFutureLogin.vue');
                },
                meta: {
                    getTitle() {
                        return localize('Вход для участников "Код будущего"');
                    },
                    isNeedLogin: false
                }
            },
            {
                path: '/Account/TselFutureCodeLogin',
                name: Common.getRouteName(RoutePageNameEnum.TselFutureCodeLogin),
                component: () => {
                    return import('../pages/Login/University2035/TselCodeOfFutureLogin.vue');
                },
                meta: {
                    getTitle() {
                        return localize('Вход для участников "Код будущего" - МЦК "Цель"');
                    },
                    isNeedLogin: false
                }
            },
            {
                path: '/Account/U2035Verification/:payload/:email/:redirectVariant/:code?',
                name: Common.getRouteName(RoutePageNameEnum.University2035Verification),
                component: () => {
                    return import('../pages/Login/University2035/University2035Verification.vue');
                },
                meta: {
                    getTitle() {
                        return localize('Необходимо подтвердить указанный почтовый адрес');
                    },
                    isNeedLogin: false
                }
            },
            {
                path: '/Account/U2035EmailChange/:payload/:email/:redirectVariant',
                name: Common.getRouteName(RoutePageNameEnum.University2035EmailChange),
                component: () => {
                    return import('../pages/Login/University2035/University2035EmailChange.vue');
                },
                meta: {
                    getTitle() {
                        return localize('Необходимо указать другой почтовый адрес');
                    },
                    isNeedLogin: false
                }
            },
            {
                path: '/Account/CohortRegister/:id',
                name: Common.getRouteName(RoutePageNameEnum.CohortRegister),
                component: () => {
                    return import('../pages/Login/CohortRegister/CohortRegister.vue');
                },
                meta: {
                    isNeedLogin: false
                }
            },
            {
                path: '/Account/Blocked',
                name: Common.getRouteName(RoutePageNameEnum.Blocked),
                component: () => {
                    return import('../pages/Login/Blocked.vue');
                },
                meta: {
                    getTitle() {
                        return localize('Учётная запись заблокирована');
                    }
                }
            },
            {
                path: '/Account/FastLogin',
                name: Common.getRouteName(RoutePageNameEnum.FastLogin),
                component: PagesLogin.FastLogin,
                meta: {
                    getTitle() {
                        return localize('Быстрый вход');
                    },
                },
            },
            {
                path: '/Account/ExamLogin',
                alias: '/Account/WebExamLogin',
                name: Common.getRouteName(RoutePageNameEnum.MigrationExamLogin),
                component: PagesLogin.MigrationExamLogin,
                meta: {
                    getTitle() {
                        return localize('Авторизация');
                    },
                },
            },
            {
                path: '/ExamLogin',
                name: Common.getRouteName(RoutePageNameEnum.MigrationExamLogin)+'Short',
                component: PagesLogin.MigrationExamLogin,
                meta: {
                    getTitle() {
                        return localize('Авторизация');
                    },
                },
            },
            {
                path: '/Account/ExamLoginEnter',
                alias: '/Account/WebExamLoginEnter',
                name: Common.getRouteName(RoutePageNameEnum.MigrationExamLoginEnter),
                component: PagesLogin.MigrationExamLoginEnter,
                meta: {
                    getTitle() {
                        return localize('Авторизация');
                    },
                },
            },
            {
                path: '/Account/Logout',
                name: 'Logout',
                component: () => {
                    return import('../pages/Login/LogOut.vue');
                },
                meta: {
                    isNeedLogin: true
                }
            },
        ],
    },
    //endregion

    //region Страницы соглашения и конфеденциальности
    {
        path: '/Agreement',
        component: Layouts.Agreement,
        children: [
            {
                path: '/Agreement',
                name: Common.getRouteName(RoutePageNameEnum.Agreement),
                component: () => {
                    return import('../pages/Agreement');
                },
            },
        ]
    },
    //endregion

    //region Страницы звонка
    {
        path: '/Call/Enter/:id',
        component: Layouts.Call,
        children: [
            {
                path: '/Call/Enter/:id',
                name: Common.getRouteName(RoutePageNameEnum.CallEnter),
                component: () => {
                    return import('../pages/Call/Enter.vue');
                },
                meta: {
                    isNeedLogin: true,
                    noReload: true,
                },
            },
        ],
    },
    //endregion

    //region Страница личного кабинета
    {
        path: '/',
        component: Layouts.Main,
        name: Common.getRouteName(RoutePageNameEnum.Index),
        meta: {
            isNeedLogin: true,
        },
        children: [
            {
                path: '/',
                alias: '/Dashboard',
                name: Common.getRouteName(RoutePageNameEnum.Dashboard),
                component: () => {
                    return import('../pages/Main/Dashboard');
                },
                meta: {
                    getTitle() {
                        return localize('Дашборд');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Account/ConfirmEmail',
                name: Common.getRouteName(RoutePageNameEnum.ConfirmEmail),
                component: () => {
                    return import('../pages/Main/Dashboard');
                },
                meta: {
                    getTitle() {
                        return localize('Дашборд');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Account/SendConfirmEmail',
                name: Common.getRouteName(RoutePageNameEnum.SendConfirmEmail),
                component: () => {
                    return import('../pages/Main/Dashboard');
                },
                meta: {
                    getTitle() {
                        return localize('Дашборд');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Schedule/',
                name: Common.getRouteName(RoutePageNameEnum.Schedule),
                component: () => {
                    return import('../pages/Main/Schedule/pages/Schedule');
                },
                meta: {
                    getTitle() {
                        return localize('Расписание');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/University',
                name: Common.getRouteName(RoutePageNameEnum.University),
                component: () => {
                    return import('../pages/Main/University');
                },
                meta: {
                    getTitle() {
                        return localize('Организация');
                    },
                    isNeedLogin: true,
                },
                children: [
                    {
                        path: 'Activities',
                        name: Common.getRouteName(RoutePageNameEnum.Activities),
                        component: () => {
                            return import('../pages/Main/University/pages/Activities');
                        },
                        meta: {
                            getTitle() {
                                return localize('Активности');
                            },
                            isNeedLogin: true,
                        },
                    },
                    {
                        path: 'Divisions',
                        name: Common.getRouteName(RoutePageNameEnum.Divisions),
                        component: () => {
                            return import('../pages/Main/University/pages/Divisions');
                        },
                        meta: {
                            getTitle() {
                                return localize('Подразделения');
                            },
                            isNeedLogin: true,
                        },
                    },
                    {
                        path: 'Disciplines',
                        name: Common.getRouteName(RoutePageNameEnum.Disciplines),
                        component: () => {
                            return import('../pages/Main/University/pages/Disciplines');
                        },
                        meta: {
                            getTitle() {
                                return localize('Дисциплины');
                            },
                            isNeedLogin: true,
                        },
                    },
                    {
                        path: 'Cohorts',
                        name: Common.getRouteName(RoutePageNameEnum.Cohorts),
                        component: () => {
                            return import('../pages/Main/University/pages/Cohorts');
                        },
                        meta: {
                            getTitle() {
                                return localize('Потоки');
                            },
                            isNeedLogin: true,
                        },
                    },
                    {
                        path: 'Programs',
                        name: Common.getRouteName(RoutePageNameEnum.Programs),
                        component: () => {
                            return import('../pages/Main/University/pages/Programs');
                        },
                        meta: {
                            getTitle() {
                                return localize('Программы');
                            },
                            isNeedLogin: true,
                        },
                    },
                    {
                        path: 'Universities',
                        name: Common.getRouteName(RoutePageNameEnum.Universities),
                        component: () => {
                            return import('../pages/Main/University/pages/Universities');
                        },
                        meta: {
                            getTitle() {
                                return localize('Организации');
                            },
                            isNeedLogin: true,
                        },
                    },
                    {
                        path: 'UsersTunableList',
                        name: Common.getRouteName(RoutePageNameEnum.UsersTunableList),
                        component: () => {
                            return import('../pages/Main/University/pages/UsersTunableList');
                        },
                        meta: {
                            getTitle() {
                                return localize('Люди');
                            },
                            isNeedLogin: true,
                        },
                    },
                ],
            },
            {
                path: '/University/Info/:id',
                name:  Common.getRouteName(RoutePageNameEnum.UniversityInfo),
                component: () => {
                    return import('../pages/Main/University/pages/Info');
                },
                meta: {
                    isNeedLogin: true,
                    getTitle() {
                        return localize('Организация');
                    },
                },
            },
            {
                path: '/University/Create',
                name: Common.getRouteName(RoutePageNameEnum.UniversityCreate),
                component: () => {
                    return import('../pages/Main/University/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Создание новой организации');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/University/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.UniversityEdit),
                component: () => {
                    return import('../pages/Main/University/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование информации об организации');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/University/License/:id',
                name: Common.getRouteName(RoutePageNameEnum.UniversityRate),
                component: () => {
                    return import('../pages/Main/University/pages/Rate');
                },
                meta: {
                    getTitle() {
                        return localize('Мой тариф');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/University/StaffImport/:id',
                name: Common.getRouteName(RoutePageNameEnum.UniversityStaffImport),
                component: () => {
                    return import('../pages/Main/University/pages/StaffImport');
                },
                meta: {
                    getTitle() {
                        return localize('Импорт сотрудников');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/University/IntegrationWith2023/:id',
                name: Common.getRouteName(RoutePageNameEnum.UniversityIntegrationWith2023),
                component: () => {
                    return import('../pages/Main/University/pages/IntegrationWith2023');
                },
                meta: {
                    getTitle() {
                        return localize('Интеграция с У2035');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/University/IntegrationWith2023/:id/project/:project',
                name: Common.getRouteName(RoutePageNameEnum.UniversityIntegrationWith2023Project),
                component: () => {
                    return import('pages/Main/University/pages/IntegrationWith2023/pages/Project/Project.vue');
                },
                meta: {
                    getTitle() {
                        return localize('Интеграция с У2035');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Specialty/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.SpecialtiesEdit),
                component: () => {
                    return import('../pages/Main/Specialty/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование информации об направлении подготовки');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/Specialty/Create',
                name: Common.getRouteName(RoutePageNameEnum.SpecialtiesCreate),
                component: () => {
                    return import('../pages/Main/Specialty/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Создание нового направления подготовки');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/Division/Info/:id',
                name: Common.getRouteName(RoutePageNameEnum.DivisionInfo),
                component: () => {
                    return import('../pages/Main/Division/pages/Info');
                },
                meta: {
                    isNeedLogin: true,
                },
            },
            {
                path: '/Division/Create',
                name: Common.getRouteName(RoutePageNameEnum.DivisionCreate),
                component: () => {
                    return import('../pages/Main/Division/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Создание нового подразделения');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/Division/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.DivisionEdit),
                component: () => {
                    return import('../pages/Main/Division/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование информации о подразделении');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/EducationalProgram/Create',
                name: Common.getRouteName(RoutePageNameEnum.EducationalProgramCreate),
                component: () => {
                    return import('../pages/Main/EducationalProgram/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Создание образовательной программы');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/EducationalProgram/CreateForDivision',
                name: Common.getRouteName(RoutePageNameEnum.EducationalProgramCreateForDivision),
                component: () => {
                    return import('../pages/Main/EducationalProgram/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Создание образовательной программы');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/EducationalProgram/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.EducationalProgramEdit),
                component: () => {
                    return import('../pages/Main/EducationalProgram/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование образовательной программы');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/EducationalProgram/Info/:id',
                name: Common.getRouteName(RoutePageNameEnum.EducationalProgramInfo),
                component: () => {
                    return import('../pages/Main/EducationalProgram/pages/Info');
                },
                meta: {
                    getTitle() {
                        return localize('Образовательная программа');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/EducationalProgramPlan/Create/:directoryId',
                name: Common.getRouteName(RoutePageNameEnum.EducationalProgramPlanCreate),
                component: () => {
                    return import('../pages/Main/EducationalProgramPlan/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Создание шаблона образовательной программы');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/EducationalProgramPlan/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.EducationalProgramPlanEdit),
                component: () => {
                    return import('../pages/Main/EducationalProgramPlan/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование шаблона образовательной программы');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/EducationalProgramPlan/Info/:id',
                name: Common.getRouteName(RoutePageNameEnum.EducationalProgramPlanInfo),
                component: () => {
                    return import('../pages/Main/EducationalProgramPlan/pages/Info');
                },
                meta: {
                    getTitle() {
                        return localize('Образовательная программа');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: 'Activity/Info/:id',
                name: Common.getRouteName(RoutePageNameEnum.ActivityInfo),
                component: () => {
                    return import('../pages/Main/Activity/Info');
                },
                meta: {
                    isNeedLogin: true,
                },
            },
            {
                path: '/Activity/PassedActivities',
                name: Common.getRouteName(RoutePageNameEnum.PassedActivities),
                component: () => {
                    return import('../pages/Main/Activity/PassedActivities');
                },
                meta: {
                    getTitle() {
                        return localize('Освоенные студентами активности');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Activity/CreateForDiscipline',
                name: Common.getRouteName(RoutePageNameEnum.ActivityForDiscipline),
                component: () => {
                    return import('../pages/Main/Activity/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Создание образовательной активности');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/Activity/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.ActivityEdit),
                component: () => {
                    return import('../pages/Main/Activity/Edit');
                },
                meta: {
                    isNeedLogin: true,
                    noReload: true,
                    getTitle() {
                        return localize('Редактирование активности');
                    },
                },
            },
            {
                path: '/Activity/PassCodingTask/:id/:studentId?',
                name: Common.getRouteName(RoutePageNameEnum.PassCodingTask),
                component: () => {
                    return import('../pages/Main/Activity/PassCodingTask');
                },
                meta: {
                    isNeedLogin: true,
                    noReload: true,
                    getTitle() {
                        return localize('Выполнение задания');
                    },
                },
            },
            {
                path: '/ActivityPlan/',
                name: Common.getRouteName(RoutePageNameEnum.ActivityPlan),
                component: () => {
                    return import('../pages/Main/ActivityPlan/pages/ActivityPlan');
                },
                meta: {
                    getTitle() {
                        return localize('Шаблоны образовательных активностей');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/ActivityPlan/Info/:id',
                name: Common.getRouteName(RoutePageNameEnum.ActivityPlanInfo),
                component: () => {
                    return import('../pages/Main/ActivityPlan/pages/Info');
                },
                meta: {
                    getTitle() {
                        return localize('Шаблоны образовательных активностей');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/ActivityPlan/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.ActivityPlanEdit),
                component: () => {
                    return import('../pages/Main/ActivityPlan/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Шаблоны образовательных активностей');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/ActivityPlan/Create',
                name: Common.getRouteName(RoutePageNameEnum.ActivityPlanCreate),
                component: () => {
                    return import('../pages/Main/ActivityPlan/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Создание шаблона образовательной активности');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/Content/Info/:id',
                name: Common.getRouteName(RoutePageNameEnum.ContentInfo),
                component: () => {
                    return import('../pages/Main/Content/pages/Info');
                },
                meta: {
                    isNeedLogin: true,
                },
            },
            {
                path: '/KnowledgeMap/',
                name: Common.getRouteName(RoutePageNameEnum.KnowledgeMapInfo),
                component: () => {
                    return import('../pages/Main/KnowledgeMap/pages/KnowledgeMap');
                },
                meta: {
                    getTitle() {
                        return localize('Карты знаний');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/KnowledgeMap/Info/:id',
                name: Common.getRouteName(RoutePageNameEnum.MapViewPages),
                component: () => {
                    return import('../pages/Main/KnowledgeMap/pages/Info');
                },
                meta: {
                    getTitle() {
                        return localize('Карты знаний');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Content/Edit/:id?',
                name: Common.getRouteName(RoutePageNameEnum.ContentEdit),
                component: () => {
                    return import('../pages/Main/Content/pages/Edit');
                },
                meta: {
                    isNeedLogin: true,
                    noReload: true,
                    getTitle() {
                        return localize('Редактирование материала');
                    },
                },
            },
            {
                path: '/Content/CreateForDirectory/:directoryId/:type',
                name: Common.getRouteName(RoutePageNameEnum.CreateForDirectory),
                component: () => {
                    return import('../pages/Main/Content/pages/CreateForDirectory');
                },
                meta: {
                    isNeedLogin: true,
                    noReload: true,
                    getTitle() {
                        return localize('Создание материала');
                    },
                },
            },
            {
                path: '/DisciplinePlan/CreateForSemesterPlan',
                name: Common.getRouteName(RoutePageNameEnum.DisciplinePlanCreateForSemesterPlan),
                component: () => {
                    return import('../pages/Main/DisciplinePlan/pages/Edit');
                },
                meta: {
                    isNeedLogin: true,
                    noReload: true,
                    getTitle() {
                        return localize('Создание шаблона дисциплины');
                    },
                },
            },
            {
                path: '/DisciplinePlan/CreateForDpoEducationalProgramPlan',
                name: Common.getRouteName(RoutePageNameEnum.DisciplinePlanCreateForDpoEducationalProgramPlan),
                component: () => {
                    return import('../pages/Main/DisciplinePlan/pages/Edit');
                },
                meta: {
                    isNeedLogin: true,
                    noReload: true,
                    getTitle() {
                        return localize('Создание шаблона дисциплины');
                    },
                },
            },
            {
                path: '/DisciplinePlan/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.DisciplinePlanEdit),
                component: () => {
                    return import('../pages/Main/DisciplinePlan/pages/Edit');
                },
                meta: {
                    isNeedLogin: true,
                    noReload: true,
                    getTitle() {
                        return localize('Редактирование шаблона дисциплины');
                    },
                },
            },
            {
                path: '/Content/Library/:path*',
                name: Common.getRouteName(RoutePageNameEnum.Library),
                component: () => {
                    return import('../pages/Main/Content/pages/Library');
                },
                meta: {
                    getTitle() {
                        return localize('Библиотека');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: 'Discipline/Info/:id/:page?/:path*',
                name: Common.getRouteName(RoutePageNameEnum.DisciplineInfo),
                component: () => {
                    return import('../pages/Main/Discipline/pages/Info');
                },
                meta: {
                    getTitle() {
                        return localize('Дисциплина');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Discipline/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.DisciplineEdit),
                component: () => {
                    return import('../pages/Main/Discipline/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование дисциплины');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/Discipline/PracticeDiary/:id',
                name: Common.getRouteName(RoutePageNameEnum.DisciplinePracticeDiary),
                component: () => {
                    return import('../pages/Main/Discipline/pages/PracticeDiary');
                },
                meta: {
                    getTitle() {
                        return localize('Дневники практик');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Discipline/ConfirmationDocs/:id',
                name: Common.getRouteName(RoutePageNameEnum.DisciplineConfirmationDocs),
                component: () => {
                    return import('../pages/Main/Discipline/pages/ConfirmationDocs');
                },
                meta: {
                    getTitle() {
                        return localize('Подтверждающие документы');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/DisciplinePlan',
                name: Common.getRouteName(RoutePageNameEnum.DisciplinePlan),
                component: () => {
                    return import('../pages/Main/DisciplinePlan/pages/DisciplinePlan');
                },
                meta: {
                    getTitle() {
                        return localize('Шаблоны дисциплины');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/DisciplinePlan/Info/:id(\\d+)/:page?',
                name: Common.getRouteName(RoutePageNameEnum.DisciplinePlanInfo),
                component: () => {
                    return import('../pages/Main/DisciplinePlan/pages/Info');
                },
                meta: {
                    getTitle() {
                        return localize('Шаблон');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/User/Info/:id',
                name: Common.getRouteName(RoutePageNameEnum.UserInfo),
                component: () => {
                    return import('../pages/Main/User/pages/Info');
                },
                meta: {
                    getTitle() {
                        return localize('Пользователь');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Account/ChangePassword',
                name: Common.getRouteName(RoutePageNameEnum.ChangePassword),
                component: () => {
                    return import('../pages/Main/User/pages/ChangePassword');
                },
                meta: {
                    getTitle() {
                        return localize('Смена пароля');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Account/ForceLogoff/:id',
                name: Common.getRouteName(RoutePageNameEnum.ForceLogoff),
                component: () => {
                    return import('../pages/Main/User/pages/ForceLogoff');
                },
                meta: {
                    getTitle() {
                        return localize('Разлогинить пользователя');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/User/Edit/:id/:redirect?',
                name: Common.getRouteName(RoutePageNameEnum.UserEdit),
                component: () => {
                    return import('../pages/Main/User/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование информации');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/User/LastActions/:id',
                name: Common.getRouteName(RoutePageNameEnum.UserLastActions),
                component: () => {
                    return import('../pages/Main/User/pages/LastActions');
                },
                meta: {
                    isNeedLogin: true,
                    getTitle() {
                        return localize('Последние действия пользователя');
                    },
                },
            },
            {
                path: '/User/NotificationSetting',
                name: Common.getRouteName(RoutePageNameEnum.NotificationSetting),
                component: () => {
                    return import('../pages/Main/User/pages/NotificationSetting');
                },
                meta: {
                    isNeedLogin: true,
                    getTitle() {
                        return localize('Настройка уведомлений');
                    },
                },
            },
            {
                path: '/Group/Info/:id',
                name: Common.getRouteName(RoutePageNameEnum.GroupInfo),
                component: () => {
                    return import('../pages/Main/Group/pages/Info');
                },
                meta: {
                    getTitle() {
                        return localize('Группа');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Group/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.GroupEdit),
                component: () => {
                    return import('../pages/Main/Group/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование группы');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/Group/Create',
                name: Common.getRouteName(RoutePageNameEnum.GroupCreate),
                component: () => {
                    return import('../pages/Main/Group/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Создание новой группы');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/Test/Edit/:id?',
                name: Common.getRouteName(RoutePageNameEnum.TestEdit),
                component: () => {
                    return import('../pages/Main/Test/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование теста');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/TestTrain/Edit/:id?',
                name: Common.getRouteName(RoutePageNameEnum.TestTrainEdit),
                component: () => {
                    return import('../pages/Main/Test/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование теста-тренажера');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/Cohort/CreateForEducationalProgram',
                name: Common.getRouteName(RoutePageNameEnum.CreateForEducationalProgramCreate),
                component: () => {
                    return import('../pages/Main/Cohort/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Создание потока');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/Cohort/BuyCourse/:id',
                name: Common.getRouteName(RoutePageNameEnum.BuyCourse),
                component: () => {
                    return import('../pages/Main/Cohort/pages/BuyCourse');
                },
                meta: {
                    isNeedLogin: true,
                },
            },
            {
                path: '/Cohort/Info/:id',
                name: Common.getRouteName(RoutePageNameEnum.CohortInfo),
                component: () => {
                    return import('../pages/Main/Cohort/pages/Info');
                },
                meta: {
                    isNeedLogin: true,
                },
            },
            {
                path: '/Cohort/Analytics/:id',
                name: Common.getRouteName(RoutePageNameEnum.CohortAnalytics),
                component: () => {
                    return import('../pages/Main/Cohort/pages/Analytics');
                },
                meta: {
                    getTitle() {
                        return localize('Аналитика');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Cohort/MarkSheet/:id',
                name: Common.getRouteName(RoutePageNameEnum.CohortMarkSheet),
                component: () => {
                    return import('../pages/Main/Cohort/pages/MarkSheet');
                },
                meta: {
                    getTitle() {
                        return localize('Итоговые оценки потока');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Cohort/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.CohortEdit),
                component: () => {
                    return import('../pages/Main/Cohort/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование потока');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/Cohort/EducationCompletion/:id',
                name: Common.getRouteName(RoutePageNameEnum.CohortEducationCompletion),
                component: () => {
                    return import('../pages/Main/Cohort/pages/EducationCompletion');
                },
                meta: {
                    getTitle() {
                        return localize('Загрузка');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Cohort/Import/:id',
                name: Common.getRouteName(RoutePageNameEnum.CohortImport),
                component: () => {
                    return import('../pages/Main/Cohort/pages/Import');
                },
                meta: {
                    getTitle() {
                        return localize('Импорт студентов');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Cohort/ImportMigration/:id',
                name: Common.getRouteName(RoutePageNameEnum.CohortImportMigration),
                component: () => {
                    return import('../pages/Main/Cohort/pages/ImportMigration');
                },
                meta: {
                    getTitle() {
                        return localize('Импорт студентов (экзамены для иностранных граждан)');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Cohort/AIImport/:id',
                name: Common.getRouteName(RoutePageNameEnum.CohortAIImport),
                component: () => {
                    return import('../pages/Main/Cohort/pages/ImportU2035Lrs');
                },
                meta: {
                    getTitle() {
                        return localize('Импорт студентов проекта ИИ');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Cohort/UAVImport/:id',
                name: Common.getRouteName(RoutePageNameEnum.CohortUAVImport),
                component: () => {
                    return import('../pages/Main/Cohort/pages/ImportU2035Lrs');
                },
                meta: {
                    getTitle() {
                        return localize('Импорт студентов проектов БАС');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Cohort/UavMethodistDigitalTrace/:id',
                name: Common.getRouteName(RoutePageNameEnum.UavMethodistDigitalTrace),
                component: () => {
                    return import('../pages/Main/Cohort/pages/UavProject/MethodistDigitalTrace');
                },
                meta: {
                    getTitle() {
                        return localize('Отправка ЦС методиста БАС');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Discipline/Create',
                name: Common.getRouteName(RoutePageNameEnum.DisciplineCreate),
                component: () => {
                    return import('../pages/Main/Discipline/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Создание дисциплины');

                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/ChatWithUser/:userId',
                name: Common.getRouteName(RoutePageNameEnum.ChatWithUser),
                component: () => {
                    return import('../pages/Main/Chat/ChatWithUser.vue');
                },
                meta: {
                    getTitle() {
                        return localize('Чаты');
                    },
                    isNeedLogin: true,
                    isVisibleChatBlock: false,
                    noReload: true,
                },
            },
            {
                path: '/Chat/:id?',
                name: Common.getRouteName(RoutePageNameEnum.Chat),
                component: () => {
                    return import('../pages/Main/Chat');
                },
                meta: {
                    getTitle() {
                        return localize('Чаты');
                    },
                    isNeedLogin: true,
                    isVisibleChatBlock: false,
                    noReload: true,
                },
            },
            {
                path: '/Forbidden',
                name: Common.getRouteName(RoutePageNameEnum.Error403),
                component: () => {
                    return import('../pages/Main/Errors/403');
                },
                meta: {
                    getTitle() {
                        return localize('Проблемы с доступом');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/ExamineeVariant/PassResult/:id/:finish?',
                name: Common.getRouteName(RoutePageNameEnum.ExamineeVariantInfo),
                component: () => {
                    return import('../pages/Main/ExamineeVariant/pages/PassResult');
                },
                meta: {
                    getTitle() {
                        return localize('Результат тестирования');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/ExamineeVariant/Pass/:id',
                name: Common.getRouteName(RoutePageNameEnum.ExamineeVariantPass),
                component: () => {
                    return import('../pages/Main/ExamineeVariant/pages/Pass');
                },
                meta: {
                    getTitle() {
                        return localize('Тестирование');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/ExamineeVariant/PassTestTrain/:id',
                name: Common.getRouteName(RoutePageNameEnum.ExamineeVariantPassTestTrain),
                component: () => {
                    return import('../pages/Main/ExamineeVariant/pages/PassTestTrain');
                },
                meta: {
                    getTitle() {
                        return localize('Тестирование');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Stock/',
                name: Common.getRouteName(RoutePageNameEnum.Stock),
                component: () => {
                    return import('../pages/Main/Stock/pages/Stock');
                },
                meta: {
                    getTitle() {
                        return localize('Биржа');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Stock/Info/:id',
                name: Common.getRouteName(RoutePageNameEnum.StockInfo),
                component: () => {
                    return import('../pages/Main/Stock/pages/Info');
                },
                meta: {
                    getTitle() {
                        return localize('Биржа материалов');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/Stock/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.StockEdit),
                component: () => {
                    return import('../pages/Main/Stock/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование материала на бирже');
                    },
                    isNeedLogin: true,
                    noReload: true,
                },
            },
            {
                path: '/ActivitySolution/Index/:id',
                name: Common.getRouteName(RoutePageNameEnum.ActivitySolution),
                component: () => {
                    return import('../pages/Main/Activity/Solutions');
                },
                meta: {
                    getTitle() {
                        return localize('Решение задания');
                    },
                    isNeedLogin: true,
                    isVisibleChatBlock: false,
                    noReload: true,
                }
            },
            {
                path: '/Notification/Info/',
                name: Common.getRouteName(RoutePageNameEnum.NotificationsInfo),
                component: () => {
                    return import('../pages/Main/Notification/pages/Info');
                },
                meta: {
                    getTitle() {
                        return localize('Уведомления');
                    },
                    isNeedLogin: true,
                }
            },
            {
                path: '/Notification/NotifySavedUserList/:listId',
                name: Common.getRouteName(RoutePageNameEnum.NotifySavedUserList),
                component: () => {
                    return import('../pages/Main/Notification/pages/NotifySavedUserList');
                },
                meta: {
                    getTitle() {
                        return localize('Разослать уведомления по выбранному списку');
                    },
                    isNeedLogin: true,
                    noReload: true,
                }
            },
            {
                path: '/Notification/ConfirmNotifyInform/:id',
                name: Common.getRouteName(RoutePageNameEnum.ConfirmNotifyInform),
                component: () => {
                    return import('../pages/Main/Notification/pages/ConfirmNotifyInform');
                },
                meta: {
                    getTitle() {
                        return localize('Подтверждение отправки уведомления');
                    },
                    isNeedLogin: true,
                }
            },
            {
                path: '/Notification/MyNotifications',
                name: Common.getRouteName(RoutePageNameEnum.MyNotifications),
                component: () => {
                    return import('../pages/Main/Notification/pages/MyNotifications');
                },
                meta: {
                    getTitle() {
                        return localize('Уведомления');
                    },
                    isNeedLogin: true,
                }
            },
            {
                path: '/Notification/UnsubscribeFromEmails',
                name: Common.getRouteName(RoutePageNameEnum.UnsubscribeFromEmails),
                component: () => {
                    return import('../pages/Main/Dashboard');
                },
                meta: {
                    getTitle() {
                        return localize('Дашборд');
                    },
                    isNeedLogin: true,
                },
            },
            {
                path: '/ImsccCourseUpload/Upload/:disciplineId',
                name: Common.getRouteName(RoutePageNameEnum.ImsccCourseUpload),
                component: () => {
                    return import('../pages/Main/ImsccCourseUpload/Upload');
                },
                meta: {
                    getTitle() {
                        return localize('Загрузка Imscc дисциплины');
                    },
                    isNeedLogin: true,
                }
            },
            {
                path: '/MarkSheet/Info',
                name: Common.getRouteName(RoutePageNameEnum.MarkSheetInfo),
                component: () => {
                    return import('../pages/Main/MarkSheet/pages/Info');
                },
                meta: {
                    getTitle() {
                        return localize('Итоговые оценки (ведомость)');
                    },
                    isNeedLogin: true,
                }
            },
            {
                path: '/Administration/AssignedRoles',
                name: Common.getRouteName(RoutePageNameEnum.AssignedRoles),
                component: () => {
                    return import('../pages/Main/Administration/pages/AssignedRoles');
                },
                meta: {
                    isNeedLogin: true,
                    getTitle() {
                        return localize('Назначенные роли');
                    }
                }
            },
            {
                path: '/Administration/Locations',
                name: Common.getRouteName(RoutePageNameEnum.Locations),
                component: () => {
                    return import('../pages/Main/Administration/pages/Locations');
                },
                meta: {
                    isNeedLogin: true,
                    getTitle() {
                        return localize('Города и страны');
                    }
                }
            },
            {
                path: '/Administration/EditTranslations',
                name: Common.getRouteName(RoutePageNameEnum.EditTranslations),
                component: () => {
                    return import('../pages/Main/Administration/pages/EditTranslation/EditTranslation.vue');
                },
                meta: {
                    isNeedLogin: true,
                    getTitle() {
                        return localize('Редактор переводов');
                    }
                }
            },
            {
                path: '/Administration/EditMultiLanguageTranslations',
                name: Common.getRouteName(RoutePageNameEnum.EditMiltiLanguageTranslations),
                component: () => {
                    return import('../pages/Main/Administration/pages/EditTranslation/EditMultiLanguageTranslations.vue');
                },
                meta: {
                    isNeedLogin: true,
                    getTitle() {
                        return localize('Редактор переводов v2');
                    }
                }
            },
            {
                path: '/Administration/Specialties',
                name: Common.getRouteName(RoutePageNameEnum.Specialties),
                component: () => {
                    return import('../pages/Main/Administration/pages/Specialties');
                },
                meta: {
                    isNeedLogin: true,
                    getTitle() {
                        return localize('Направления подготовки');
                    }
                }
            },
            {
                path: '/Administration/Migrations',
                name: Common.getRouteName(RoutePageNameEnum.MigrationMethods),
                component: () => {
                    return import('../pages/Main/Administration/pages/Migrations');
                },
                meta: {
                    isNeedLogin: true,
                    getTitle() {
                        return localize('Миграции');
                    }
                }
            },
            {
                path: '/Administration/SourceVersion',
                name: Common.getRouteName(RoutePageNameEnum.SourceVersion),
                component: () => {
                    return import('../pages/Main/Administration/pages/SourceVersion');
                },
                meta: {
                    getTitle() {
                        return localize('Версия приложения');
                    },
                    isNeedLogin: true
                }
            },
            {
                path: '/Administration/RequestLogs',
                name: Common.getRouteName(RoutePageNameEnum.RequestLogs),
                component: () => {
                    return import('../pages/Main/Administration/pages/RequestLogs');
                },
                meta: {
                    getTitle() {
                        return localize('Журнал http запросов');
                    },
                    isNeedLogin: true,
                }
            },
            {
                path: '/Administration/CallsTest',
                name: Common.getRouteName(RoutePageNameEnum.CallsTest),
                component: () => {
                    return import('../pages/Main/Administration/pages/CallsTest');
                },
                meta: {
                    getTitle() {
                        return localize('Тестирование связи с turn - сервером');
                    },
                    isNeedLogin: true
                }
            },
            {
                path: '/Building/Create',
                name: Common.getRouteName(RoutePageNameEnum.BuildingCreate),
                component: () => {
                    return import('../pages/Main/Building/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Создание нового корпуса');
                    },
                    isNeedLogin: true,
                    noReload: true,
                }
            },
            {
                path: '/Building/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.BuildingEdit),
                component: () => {
                    return import('../pages/Main/Building/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование корпуса');
                    },
                    isNeedLogin: true,
                    noReload: true,
                }
            },
            {
                path: '/Building/Info/:id',
                name: Common.getRouteName(RoutePageNameEnum.BuildingInfo),
                component: () => {
                    return import('../pages/Main/Building/pages/Info');
                },
                meta: {
                    getTitle() {
                        return localize('Информация о корпусе');
                    },
                    isNeedLogin: true,
                }
            },
            {
                path: '/Room/Create',
                name: Common.getRouteName(RoutePageNameEnum.RoomCreate),
                component: () => {
                    return import('../pages/Main/Room/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Создание нового помещения');
                    },
                    isNeedLogin: true,
                    noReload: true,
                }
            },
            {
                path: '/Room/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.RoomEdit),
                component: () => {
                    return import('../pages/Main/Room/pages/Edit');
                },
                meta: {
                    getTitle() {
                        return localize('Редактирование помещения');
                    },
                    isNeedLogin: true,
                    noReload: true,
                }
            },
            {
                path: '/Room/Info/:id',
                name: Common.getRouteName(RoutePageNameEnum.RoomInfo),
                component: () => {
                    return import('../pages/Main/Room/pages/Info');
                },
                meta: {
                    getTitle() {
                        return localize('Информация о помещении');
                    },
                    isNeedLogin: true,
                }
            },
            {
                path: '/Equipment/',
                name: Common.getRouteName(RoutePageNameEnum.Equipments),
                component: () => {
                    return import('../pages/Main/Administration/pages/Equipment/Equipment');
                },
                meta: {
                    isNeedLogin: true,
                    getTitle() {
                        return localize('Оборудование');
                    }
                }
            },
            {
                path: '/Equipment/Edit/:id',
                name: Common.getRouteName(RoutePageNameEnum.EquipmentEdit),
                component: () => {
                    return import('../pages/Main/Administration/pages/Equipment/Edit');
                },
                meta: {
                    isNeedLogin: true,
                    noReload: true,
                    getTitle() {
                        return localize('Редактирование оборудования');
                    }
                }
            },
            {
                path: '/Equipment/Create',
                name: Common.getRouteName(RoutePageNameEnum.EquipmentCreate),
                component: () => {
                    return import('../pages/Main/Administration/pages/Equipment/Edit');
                },
                meta: {
                    isNeedLogin: true,
                    noReload: true,
                    getTitle() {
                        return localize('Создание оборудования');
                    }
                }
            },
            {
                path: '/ExternalInfo/:entitytype/:exchangeableguid',
                name: Common.getRouteName(RoutePageNameEnum.ExternalResolve),
                component: PagesExchangeable.ExchangeableGuidResolve,
                meta: {
                    isNeedLogin: true,
                    noReload: true,
                    getTitle() {
                        return localize('Переход на страницу информации');
                    }
                }
            },
            {
                path: '/:pathMatch*',
                alias: '/NotFound/',
                name: Common.getRouteName(RoutePageNameEnum.Error404),
                component: () => {
                    return import('../pages/Main/Errors/404');
                },
                meta: {
                    getTitle() {
                        return localize('Страница не найдена');
                    },
                    isNeedLogin: true,
                },
            },
        ],
    },
    //endregion
];

export default routes;
