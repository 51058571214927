<template>
    <q-dialog v-model="isVisible" persistent>
        <q-card class="server-error-block text-center">
            <q-card-section class="no-border">
                <div class="title">
                    {{ translateFromLocalizableStrings('Ошибка сервера') }}
                </div>
            </q-card-section>
            <q-separator/>
            <q-card-section>
                <div class="q-mb-md">
                    <img
                        v-if="isNewYear"
                        src="~assets/server-error-new-year.png"
                        class="q-mt-sm"
                        :style="{ width: '106px' }"
                        alt=""
                    />
                    <img
                        v-else
                        src="~assets/server-error.png"
                        class="q-mt-sm"
                        :style="{ width: '150px' }"
                        alt=""
                    />
                </div>
                <div class="description text-shade-8">
                    {{ translateFromLocalizableStrings('Упс... Что-то пошло не так!') }}
                </div>
                <q-btn
                    color="primary"
                    class="q-mt-sm q-mb-md"
                    @click="toHomePage"
                >
                    {{ translateFromLocalizableStrings('На главную') }}
                </q-btn>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script lang="ts">
    import {
        defineComponent,
        Ref,
        computed,
        watch,
        ref,
    } from 'vue';
    import { translateFromLocalizableStrings } from 'src/services/LocalizationService';
    import { useRouter } from 'vue-router';
    import { useMainLayoutStore } from 'src/store/module-main-layout';
    import { Common } from 'src/helpers/Common';

    // Компонент показывается, когда сервер вернул любую 500-ю ошибку
    export default defineComponent({
        setup() {
            const $router = useRouter();
            const mainLayoutStore = useMainLayoutStore();
            const isNewYear = Common.isNewYearTime();

            // Модель привязана к модальному окну для открытия и закрытия
            const isVisible: Ref<boolean> = ref(false);

            // Получаем занчение открыть окно или нет
            const isVisibleSmthWentWrongBlock = computed((): boolean => {
                return mainLayoutStore.isVisibleSmthWentWrongBlock;
            });

            // Отслеживаем изменения по октрытию окна
            watch(isVisibleSmthWentWrongBlock, (status: boolean) => {
                isVisible.value = status;
            });

            // Закрытие окна
            function close(): void {
                mainLayoutStore.isVisibleSmthWentWrongBlock = false;
            }

            // Редирект на главну страницу
            function toHomePage(): void {
                close();
                $router.push('/');
            }

            // Возращаем сущности для реактивности и взаимодействия
            return {
                isNewYear,
                isVisible,
                isVisibleSmthWentWrongBlock,
                close,
                toHomePage,
                translateFromLocalizableStrings
            };
        }
    });
</script>

<style lang="scss" scoped>
    .server-error-block {
        min-width: 360px;
    }
</style>
