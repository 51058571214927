<template>
    <div v-if="isLoading || !isReadyRouter">
        <q-spinner
            color="primary"
            size="3em"
            class="fixed-center"
        />
    </div>
    <router-view v-else />
    <SmthWentWrong />
    <InstallApp v-if="!isLoading" />
</template>

<script lang="ts">
    import LocalizationService from 'src/services/LocalizationService';
    import langRu from 'quasar/lang/ru';
    import { LocaleName } from 'src/types/LocaleName';
    import 'src/helpers/Extensions';
    import { routerBas } from 'components/EventBuses';
    import { Common } from 'src/helpers/Common';
    import { RoutePageNameEnum } from 'src/api/ApiClient';
    import SmthWentWrong from 'layouts/Main/components/SmthWentWrong.vue';
    import FirebaseTokenManagedService from 'src/services/FirebaseTokenManagedService';
    import {
        computed,
        defineComponent,
        getCurrentInstance,
        onBeforeMount,
        onMounted,
        ref,
        watch,
    } from 'vue';
    import { Notify, useQuasar } from 'quasar';
    import { CommonOdinHub } from 'src/services/hubs/CommonOdinHub';
    import { WebNotificationPartialHub } from 'src/services/hubs/WebNotificationPartialHub';
    import { ChatPartialHub } from 'src/services/hubs/ChatPartialHub';
    import { CallPartialHub } from 'src/services/hubs/CallPartialHub';
    import { LiveDataPartialHub } from 'src/services/hubs/LiveDataPartialHub';
    import { useRoute, useRouter, RouteLocationRaw } from 'vue-router';
    import { useAccountStore } from 'src/store/module-account';
    import { useAuthorizationStore } from 'src/store/module-authorization';
    import { useMainLayoutStore } from 'src/store/module-main-layout';
    import { useChatStore } from 'src/store/module-chat';
    import InstallApp from 'layouts/Main/components/InstallApp.vue';
    import { RouterBusEvents } from 'components/EventBuses/emuns';
    import { StatisticPartialHub } from 'src/services/hubs/StatisticPartialHub';

    // eslint-disable-next-line max-lines-per-function
    export default defineComponent({
        name: 'App',

        components: {
            InstallApp,
            SmthWentWrong,
        },

        // eslint-disable-next-line max-lines-per-function
        setup() {
            const isLoading = ref<boolean>(true);
            const accountStore = useAccountStore();
            const authorizationStore = useAuthorizationStore();
            const mainLayoutStore = useMainLayoutStore();
            const chatStore = useChatStore();
            const $router = useRouter();
            const $route = useRoute();
            const $q = useQuasar();
            const app = getCurrentInstance();

            const isReadyRouter = computed<boolean>(() => {
                return mainLayoutStore.isReadyRouter;
            });

            /* На всякий случай проверяем актуальность текущего fcm токена при переходах между страницами */
            watch($route, async function () {
                await app?.appContext.config.globalProperties.$fcmService?.sync();
            });

            async function toLogin(): Promise<void> {
                authorizationStore.setToken('');

                const loginRoute: RouteLocationRaw = {
                    name: Common.getRouteName(RoutePageNameEnum.Login),
                };

                if ($route.query.redirect) {
                    loginRoute.query = { redirect: $route.query.redirect };
                } else {
                    loginRoute.query = { redirect: $route.fullPath };
                }

                await $router.replace(loginRoute);
            }

            async function loadData(): Promise<void> {
                await LocalizationService.init();

                // если это страницы личного кабинета то загружаем данные о пользователе
                if ($route.meta.isNeedLogin) {
                    const accountInfo = await accountStore.loadAccountInfo();

                    if (!accountInfo) {
                        await toLogin();
                    } else {
                        chatStore.loadChatsUnreadedCount();

                        // сохраняем полученный токен
                        if (app) {
                            app.appContext.config.globalProperties.$token = authorizationStore.getToken;
                        }

                        // Если пользователь заблокирован, то отправляем его на страницу Blocked
                        if (accountInfo.isBlocked) {
                            $router.push({
                                name: Common.getRouteName(RoutePageNameEnum.Blocked),
                            });
                        }
                    }
                } else {
                    // если нет, то это страницы авторизации или соглашений
                    // но если пользователь авторизован, то загружаем данные о нем
                    // и редиректим на главную страницу если это не страницы соглашений
                    if (authorizationStore.getIsLogged) {
                        // при переходе из Moodle пользователь может быть как авторизован, так и нет
                        // если мы перешли из moodle, то код внутри не должен отрабатывать,
                        // т.к. мы должны прямиком попасть на страницу RoutePageNameEnum.MoodleLogin,
                        // где собственная логика по авторизации

                        if ($route.name !== Common.getRouteName(RoutePageNameEnum.MoodleLogin) &&
                            $route.name !== Common.getRouteName(RoutePageNameEnum.FastLogin) &&
                            $route.name !== Common.getRouteName(RoutePageNameEnum.University2035Login) &&
                            $route.name !== Common.getRouteName(RoutePageNameEnum.MigrationExamLogin) &&
                            $route.name !== Common.getRouteName(RoutePageNameEnum.MigrationExamLoginEnter) &&
                            $route.name !== Common.getRouteName(RoutePageNameEnum.MigrationExamLogin)+'Short') {
                            const accountInfo = await accountStore.loadAccountInfo();

                            if (!accountInfo) {
                                await toLogin();
                            } else {
                                chatStore.loadChatsUnreadedCount();

                                // сохраняем полученный токен
                                if (app) {
                                    app.appContext.config.globalProperties.$token = authorizationStore.getToken;
                                }

                                if (!($route.name === Common.getRouteName(RoutePageNameEnum.Agreement) ||
                                    $route.name === Common.getRouteName(RoutePageNameEnum.CohortRegister))) {
                                    await $router.push({ name: Common.getRouteName(RoutePageNameEnum.Dashboard) });
                                }
                            }
                        }
                    } else {
                        const params = Common.getUrlSearchParam(window.location.hash, 'access_token');

                        if (params) {
                            await $router.push({
                                name: Common.getRouteName(RoutePageNameEnum.Login),
                                query: { yandexData: params },
                            });
                        }
                    }
                }

                isLoading.value = false;
            }

            function setCustomCssProperty(): void {
                // на некоторых мобильных браузерах нативная единица измерения
                // ведет себя не так, как ожидается
                // устанавливаем кастомную переменную --vh
                // для использования вместо vh когда это необходимо
                const vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            }

            function startLoadData(): void {
                loadData()
                    .then(async () => {
                        if (app) {
                            app.appContext.config.globalProperties.$fcmService = await FirebaseTokenManagedService.Create();
                        }
                    });
            }

            onBeforeMount(() => {
                // Если роутер загржен то загружаем данные
                if (isReadyRouter.value) {
                    startLoadData();
                } else {
                    // Если вешаем обработчик на готовноть роутера
                    // чтобы его можно было использовать
                    $router.isReady().then(startLoadData);
                }

                if (LocalizationService.getLocaleName() === LocaleName.RU) {
                    $q.lang.set(langRu);
                }

                // принимает события из обработчика запросов к серверу NotifyErrors
                routerBas.on(RouterBusEvents.ToLogin, toLogin);

                setCustomCssProperty();

                // ODIN-7839 крестик для закрытия всплывающих уведомлений
                Notify.setDefaults({
                    actions: [{
                        icon: 'close',
                        color: 'white',
                        class: 'close-btn',
                    }],
                });

                window.addEventListener('resize', setCustomCssProperty);

                Common.applyMixins(CommonOdinHub, [WebNotificationPartialHub, CallPartialHub, ChatPartialHub, LiveDataPartialHub, StatisticPartialHub]);
            });

            onMounted(() => {
                const loadingSpinnerEl = document.getElementById('loading-spinner');

                if (loadingSpinnerEl) {
                    loadingSpinnerEl.style.display = 'none';
                }
            });

            return {
                isLoading,
                isReadyRouter,
            };
        },

    });
</script>
