<template>
    <i style="display: inline-flex; align-items: center;">
        <component
            ref="iconRef"
            :is="name"
            @mouseenter="setColor(true)"
            @mouseleave="setColor(false)"
            :class="contentClass"
        >
        </component>
        <slot />
    </i>
</template>

<script lang="ts">
    import {
        defineComponent,
        onMounted,
        watch,
        ref, nextTick,
    } from 'vue';
    import { ColorValiablesNameEnum, IIconProps } from 'components/ui/Icon/interface';
    import { ComponentPublicInstance } from '@vue/runtime-core';

    // Компонент обертка для кастомных иконок
    export default defineComponent({
        name: 'Icon',

        props: {
            // Название иконки
            name: {
                type: String,
                required: true,
            },

            // Размер иконки в пикселях
            size: {
                type: [String, Number],
                required: false,
            },

            // Цвет иконки. Задается строго из заданного пула
            color: {
                type: String,
                default: ColorValiablesNameEnum.shade7,
            },

            // На какой цвет должна меняться иконка при наведении
            hoverColor: {
                type: String,
                default: ColorValiablesNameEnum.accent,
            },

            // Использовать цвет из самой икноки, а не из проперти color
            useRawColor: {
                type: Boolean,
                default: false,
            },
            contentClass: {
                type: String,
                default: ''
            }
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props: IIconProps) {
            const iconRef = ref<ComponentPublicInstance | null>(null);

            watch(() => props.name, () => {
                nextTick(function () {
                    setSize();
                    setColor();
                });
            });
            watch(() => props.color, () => setColor());
            watch(() => props.hoverColor, () => setColor());

            function setColor(isHover: boolean = false): void {
                const { color, hoverColor, useRawColor } = props;

                if (useRawColor) {
                    return;
                }

                if (!iconRef.value || !iconRef.value.$el) {
                    return;
                }

                // Меняем цвет иконок на основе атрибутов
                // которые используются для окрашивания
                for (const item of iconRef.value.$el.children) {
                    if (item.getAttribute('stroke')) {
                        item.setAttribute('stroke', isHover ? hoverColor.toString() : color.toString());
                    }

                    if (item.getAttribute('fill')) {
                        item.setAttribute('fill', isHover ? hoverColor.toString() : color.toString());
                    }
                }

                const fill = iconRef.value.$el.getAttribute('fill');

                if (fill && fill !== 'none') {
                    iconRef.value.$el.setAttribute('fill', isHover ? hoverColor.toString() : color.toString());
                }
            }

            function setSize(): void {
                const { size } = props;

                if (size && iconRef.value && iconRef.value.$el) {
                    // Формируем атрибуты для svg на основе размера
                    const width = iconRef.value.$el.getAttribute('width');
                    const height = iconRef.value.$el.getAttribute('height');

                    if (width && height) {
                        iconRef.value.$el.setAttribute('viewBox', `0 0 ${width} ${height}`);
                    }

                    iconRef.value.$el.setAttribute('width', size.toString());
                    iconRef.value.$el.setAttribute('height', size.toString());
                }
            }

            onMounted(() => {
                setSize();
                setColor();
            });

            return {
                iconRef,
                setColor,
                ColorValiablesNameEnum,
            };
        },
    });
</script>
